import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from '../service/checkout/checkout.service';
import { GlobalStudioConstants } from '../service/GlobalStudioConstants';
import { SignupService } from '../service/signup/signup.service';
import { ICustomWindow, RazorpayService } from '../service/razorpay/razorpay.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  @ViewChild('draftPaginator', { read: MatPaginator }) draftPaginator: MatPaginator;
  @ViewChild('draftSort') draftSort: MatSort;
  displayedColumns: string[] = ['serid','orderId', 'orderDate','customername','custmobile', 'frameAmount','designCharge','adjustmentAmount','gstAmount', 'deliveryCharges', 'finalOrderAmount','dlrNetAmount','dlrGstAmount','dlrFinalAmount', 'commission','expectedDeliveryDate', 'action'];
  dataSource: any;
  @ViewChild('customerPaginator', { read: MatPaginator }) customerPaginator: MatPaginator;
  @ViewChild('customerSort') customerSort: MatSort;
  displayedCustColumns: string[] = ['serid','orderId', 'orderDate','customername','custmobile', 'orderAmount','gstAmount', 'deliveryCharges', 'finalOrderAmount', 'expectedDeliveryDate', 'action'];
  customerDataSource: any;
  @ViewChild('detailPaginator', { read: MatPaginator }) detailPaginator: MatPaginator; //history paginator
  @ViewChild('detailsort') detailsort: MatSort; //history sorting
  displayedModalColumns: string[] = ['serid','orderId', 'orderDate','customername','custmobile', 'orderAmount', 'deliveryCharges', 'finalOrderAmount', 'totalAmount', 'expectedDeliveryDate', 'shipmentOrderID', 'orderStatus','action'];
  dataSourceModal;
  @ViewChild('chargesPaginator', { read: MatPaginator }) chargesPaginator: MatPaginator; //history paginator
  @ViewChild('chargessort') chargessort: MatSort; //history sorting
  displayedchargesColumns: string[] = ['DesignCharges','AdjustmentPersontage','action'];
  dataSourcecharges;

  private _window: ICustomWindow
  public rzp: any

  allDraft;
  allOrderHistory;
  dealerId;
  userMobile;
  userName;
  profilename;
  adjustmentPersontage = 0;
  adjustmentAmount = 0;
  designCharge = 0;
  constructor(
    private checkoutService: CheckoutService,
    private signupService: SignupService,
    private zone: NgZone,
    private razorpayService: RazorpayService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this._window = this.razorpayService.nativeWindow;
  }

  ngOnInit(): void {
    localStorage.setItem("component", this.constructor.name)
    this.getLoginUserData();
    this.getallDraft();
  }

  hideloaderOrder() { 
    $(".orderhidespinner").css({"display": "none"});
    $(".orderpleasewait").css({"display": "none"});
  }
  hideloaderOrderHistory() { 
    $(".orderhistoryhidespinner").css({"display": "none"});
    $(".orderhistorypleasewait").css({"display": "none"});
  }
  hideloaderModal() { 
    $(".modalhidespinner").css({"display": "none"});
    $(".modalpleasewait").css({"display": "none"});
  }
  getLoginUserData() {
    this.signupService.getLoginUserData().subscribe(res => {
      // console.log(res);
      var tempArray = [];
      this.dealerId = res.dealerId;
      this.userMobile = res.mobile;
      this.userName = res.companyName;
      this.profilename = res.companyName.substring(0, 1).toUpperCase();
      if(res.adjustmentPersontage != null || res.designCharges != null){
        this.adjustmentPersontage = res.adjustmentPersontage;
        this.designCharge = res.designCharges;
      } else {
        this.adjustmentPersontage = 0;
        this.designCharge = 0;
      }
      tempArray.push({"dealerid":res.dealerId,"designcharge":res.designCharges,"adjustmentpercentage":res.adjustmentPersontage})
      this.dataSourcecharges = new MatTableDataSource(tempArray);
      this.dataSourcecharges.paginator = this.chargesPaginator;
      this.dataSourcecharges.sort = this.chargessort
    })
  }

  txtDesignCharge;
  txtAdjustmentPer;
  resChargesData;
  updateCharges(){
    this.txtDesignCharge = this.designCharge;
    this.txtAdjustmentPer = this.adjustmentPersontage;
  }

  clearCharges(){
    this.txtDesignCharge = null;
    this.txtAdjustmentPer = null;
  }

  saveCharges(){
    if(this.txtDesignCharge == null || this.txtDesignCharge == undefined){
      this.txtDesignCharge = 0;
    }
    if(this.txtAdjustmentPer == null || this.txtAdjustmentPer == undefined){
      this.txtAdjustmentPer = 0;
    }
    // if (this.txtDesignCharge == "" || this.txtDesignCharge == null || this.txtDesignCharge == undefined) {
    //   this.toastr.error("Design Charge is required");
    // } else if(this.txtAdjustmentPer == "" || this.txtAdjustmentPer == null || this.txtAdjustmentPer == undefined){
    //   this.toastr.error("Adjustment Percentage is required");
    // } else {
      this.checkoutService.saveCharges(this.dealerId,this.txtDesignCharge,this.txtAdjustmentPer).subscribe(res => {
        this.resChargesData = res;
        this.clearCharges();
        this.getLoginUserData();
        $('#chargesModel').modal('hide');
      },error => {

      })
    // }
  }

  applyOrdersFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.customerDataSource.filter = filterValue.trim().toLowerCase();

    if (this.customerDataSource.paginator) {
      this.customerDataSource.paginator.firstPage();
    }
  }
  applyDraftFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  applyHistoryFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceModal.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceModal.paginator) {
      this.dataSourceModal.paginator.firstPage();
    }
  }

  finalNetAmount = 0;
  getallDraft() {
    this.finalNetAmount = 0;
    this.checkoutService.orderHistoryByUserStatusDTOs('DRAFT').subscribe((res: any) => {
      this.allDraft = res;
      // console.log(this.allDraft);
      if (this.allDraft) {
        this.hideloaderOrder();
      }
      for(let data of res) {
        // this.finalNetAmount = Math.round(this.finalNetAmount + data.totalAmount);
        this.finalNetAmount = Math.round(this.finalNetAmount + data.dlrFinalAmount);
      }
      
      this.dataSource = new MatTableDataSource(this.allDraft);
      this.dataSource.paginator = this.draftPaginator;
      this.dataSource.sort = this.draftSort
      //==============customer order list ====================
      this.customerDataSource = new MatTableDataSource(this.allDraft);
      this.customerDataSource.paginator = this.customerPaginator;
      this.customerDataSource.sort = this.customerSort
    })
  }
  getallOrderHistory() {
    this.checkoutService.getOrderHistoryByAllStatus().subscribe((res: any) => {
      this.allOrderHistory = res;
      // console.log(this.allOrderHistory);
      if (this.allOrderHistory) {
        this.hideloaderOrderHistory();
      }
      this.dataSourceModal = new MatTableDataSource(this.allOrderHistory);
      this.dataSourceModal.paginator = this.detailPaginator;
      this.dataSourceModal.sort = this.detailsort
    })
  }

  //=========================razorpay payment code=================================//
  resRazorPayOrder;
  txtcurrency = "INR";
  razorpayOrderId;
  transactionId;
  razorpay_signature;
  dealerRegAmount = 2000;
  txtorderid = null;
  createRazorPayOrderId(draftData) {
    this.listOfPaymentId.push(draftData.orderDetailsId);
    this.txtorderid = "ordDft"+draftData.orderDetailsId;
    // var finalamount = Math.round(parseFloat((draftData.totalAmount * 100).toFixed(2)));
    var finalamount = Math.round(parseFloat((draftData.dlrFinalAmount * 100).toFixed(2)));
    this.checkoutService.createOrderRazorPayOrder(this.listOfPaymentId, finalamount, this.txtcurrency, this.txtorderid).subscribe(res => {
      this.resRazorPayOrder = res;
      this.razorpayOrderId = this.resRazorPayOrder.razorPayOrderId;
      // console.log(this.resRazorPayOrder)

      var options: any = {
        key: GlobalStudioConstants.RazorPay_Key_Id, // Enter the Key ID generated from the Dashboard
        amount: finalamount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tarapara",
        description: "Tarapara",
        image: "",
        order_id: this.razorpayOrderId,
        handler: this.paymentHandler.bind(this),
        // prefill: {
        // "name": "Studio",
        // "email": "Studio.SSM@example.com",
        // "contact": "9999999999"
        // },
        notes: '',
        theme: {
          // color: "#e90e0e"
          color: "#ffffff"
        },
        modal: {
          ondismiss: (() => {
            this.zone.run(() => {
              this.router.navigate(['/myaccount']);
            });
          })
        }
      };
      this.rzp = new this.razorpayService.nativeWindow['Razorpay'](options);
      this.rzp.open();
      this.rzp.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        this.toastr.error(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      })
    }, error => {
      if (error.status == 403) {
        this.toastr.error("please login again.");
        localStorage.removeItem("studio-loginuser");
        localStorage.removeItem("studio-token");
        this.router.navigate(['']);
      } else {
        // console.log("create razorpay order api error");
      }
    })
  }

  paymentHandler(res: any) {
    this.zone.run(() => {
      // console.log(res);//get payment id if payment successfully done
      this.razorpayOrderId = res.razorpay_order_id;
      this.transactionId = res.razorpay_payment_id;
      this.razorpay_signature = res.razorpay_signature;
      this.updateDealerPaymentData();
    }, error => {
      console.log("razorpay transaction handler error");
    });
  }

  txtstatus = "Order"
  updateDealerPaymentData() {
    this.checkoutService.updateRazorPaySuccesOrder(this.razorpayOrderId, this.transactionId, this.razorpay_signature,this.txtstatus).subscribe(res => {
      // console.log(res);
      this.toastr.success("Payment success.");
      this.getallDraft();
      const main = document.getElementById('chkAllPayment');
      var mchk = main as HTMLInputElement;
      mchk.checked = false;
      for (let ii = 0; ii<this.allDraft.length;ii++ ) {
        const mpay = document.getElementById('onePayment' + ii);
        var chk = mpay as HTMLInputElement;
        chk.checked = false;
        this.paymentCheckBox[ii] = null;
        this.listOfPaymentId = [];
        this.allpaymentAmount = 0;
      }
    }, error => {
      this.toastr.error("Payment not success.");
    })
  }

  //===========================Get Selected Order Detail===================================//
  orderJson;
  orderNumber;
  orderMainFrameType;
  orderSize;
  orderFrameProfile;
  orderFrameColor;
  orderMat;
  orderWidth;
  orderLamination;
  orderGlass;
  orderMount;
  orderCanvasWrap;
  orderCanvasType;
  orderShape;
  customer_name;
  customer_Mobile;
  orderAmount = 0;
  deliveryCharge = 0;
  totalAmount = 0;
  shipaddres;
  shipMianImage;
  shipPreviewImage;
  modalPDFData;
  dwnldOriginalimage;
  modalEnable = false;
  gstAmount = 0;
  baseGstamount = 0;
  getSelectedOrderDetail(row) {
    this.modalEnable = false;
    $(".modalhidespinner").css({"display": "block"});
    $(".modalpleasewait").css({"display": "block"});
    this.checkoutService.getOneorderHistoryById(row.orderDetailsId).subscribe(res=>{
      if (res) {
        this.hideloaderModal();
        this.modalEnable = true;
      }
      this.modalPDFData = res;
      this.orderNumber = res.orderNumber;
      this.orderJson = JSON.parse(res.orderJson);
      this.customer_name = res.customerName;
      this.customer_Mobile = res.shippingMobile;
      this.orderAmount = res.orderAmount;
      this.deliveryCharge = res.deliveryCharges;
      if (res.deliveryGst == null) {
        this.baseGstamount = 0;
      } else {
        this.baseGstamount = res.deliveryGst;
      }
      this.gstAmount = res.gstAmount;
      this.totalAmount = res.finalOrderAmount;
      this.shipMianImage = this.orderJson.mainImage;
      this.shipPreviewImage = res.thumbImage;
      this.dwnldOriginalimage = res.originalImage;
      if (res.shippingCity != null || res.shippingCity != "" || res.shippingCity != undefined) {
        this.shipaddres  = res.shippingAddress1 + " , " + res.shippingCity + "-" + res.shippingPincode
      } else {
        this.shipaddres  = res.shippingAddress1 + "-" + res.shippingPincode
      }
      // console.log(this.orderJson);    
      this.orderMainFrameType = this.orderJson.productName;
      this.orderSize = this.orderJson.size.cm;
      this.orderFrameProfile = this.orderJson.frame.label;
      this.orderFrameColor = this.orderJson.frameColor.label;
      if (this.orderJson.mat != null) {
        this.orderMat = this.orderJson.mat.label;
      } else {
        this.orderMat = null;
      }
      if (this.orderJson.shape != null) {
        this.orderShape = this.orderJson.shape.label;
      } else {
        this.orderShape = null;
      }
      if (this.orderJson.width != null) {
        this.orderWidth = this.orderJson.width.label;
      } else {
        this.orderWidth = null;
      }
      if (this.orderJson.lamination != null) {
        this.orderLamination = this.orderJson.lamination.option;
      } else {
        this.orderLamination = null;
      }
      if (this.orderJson.glass != null) {
        this.orderGlass = this.orderJson.glass.option;
      } else {
        this.orderGlass = null;
      }
      if (this.orderJson.mount != null) {
        this.orderMount = this.orderJson.mount.option;
      } else {
        this.orderMount = null;
      }
      if (this.orderJson.canvasWrap != null) {
        this.orderCanvasWrap = this.orderJson.canvasWrap.label;
      } else {
        this.orderCanvasWrap = null;
      }
      if (this.orderJson.canvasType != null) {
        this.orderCanvasType = this.orderJson.canvasType.label;
      } else {
        this.orderCanvasType = null;
      }
    },error => {
      this.modalPDFData = null;
    })
  }

  numberandDotOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  resBillPdf;
  openPdfResponse;
  downloadBill(orderdata) {
    this.checkoutService.downloadOrderBill(orderdata.orderDetailsId).subscribe((data) => {
      this.resBillPdf = data;

      var newBlob = new Blob([this.resBillPdf], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }
      const data1 = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data1;
      link.download = "Invoice.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponse.saveAs(link.href);
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data1);
        link.remove();
      }, 100);
    })
  }

  modalDownloadBill() {
    this.downloadBill(this.modalPDFData);
  }
  //============================Check all Payment================================//
  paymentCheckBox = [];
  listOfPaymentId = [];
  allpaymentAmount = 0;
  checkAllOrder() {
    this.allpaymentAmount = 0;
    const cb = document.getElementById('chkAllPayment');
    var element = cb as HTMLInputElement;
    if (element.checked == false) {
      for (let ii = 0; ii<this.allDraft.length;ii++ ) {
        const mpay = document.getElementById('onePayment' + ii);
        var chk = mpay as HTMLInputElement;
        chk.checked = false;
        this.listOfPaymentId = [];
        this.allpaymentAmount = 0;
      }
    } else if (element.checked == true) {
      for (let ii = 0; ii<this.allDraft.length;ii++ ) {
        const mpay = document.getElementById('onePayment' + ii);
        var chk = mpay as HTMLInputElement;
        chk.checked = true;
        this.listOfPaymentId.push(this.allDraft[ii].orderDetailsId);
        // this.allpaymentAmount = Math.round(this.allpaymentAmount + this.allDraft[ii].totalAmount);
        this.allpaymentAmount = Math.round(this.allpaymentAmount + this.allDraft[ii].dlrFinalAmount);
      }
    }
    // console.log(this.listOfPaymentId);
    // console.log(this.allpaymentAmount);
  }
  selectedPaymentOrder(row) {
    if (this.listOfPaymentId.indexOf(row.orderDetailsId) !== -1) {
      var i = this.listOfPaymentId.indexOf(row.orderDetailsId);
      if (i != -1) {
        this.listOfPaymentId.splice(i, 1);
        // this.allpaymentAmount = Math.round(this.allpaymentAmount - row.totalAmount);
        this.allpaymentAmount = Math.round(this.allpaymentAmount - row.dlrFinalAmount);
      }
    } else {
      this.listOfPaymentId.push(row.orderDetailsId)
      // this.allpaymentAmount = Math.round(this.allpaymentAmount + row.totalAmount);
      this.allpaymentAmount = Math.round(this.allpaymentAmount + row.dlrFinalAmount);
    }
    // console.log(this.listOfPaymentId);
    // console.log(this.allpaymentAmount);
  }

  makeAllPayment() {    
    if (this.listOfPaymentId == [] ||this.listOfPaymentId == null ||this.listOfPaymentId.length == 0) {
      this.toastr.error("please select orders");
    } else{
      this.makeAllOrderPayment();
    }
  }
  makeAllOrderPayment() {
    var allorderid = "allpymt"+this.listOfPaymentId[0] + new Date().getTime().toString() ;
    var finalamount = Math.round(parseFloat((this.allpaymentAmount* 100).toFixed(2)));
    this.checkoutService.createOrderRazorPayOrder(this.listOfPaymentId, finalamount, this.txtcurrency, allorderid).subscribe(res => {
      this.resRazorPayOrder = res;
      this.razorpayOrderId = this.resRazorPayOrder.razorPayOrderId;
      // console.log(this.resRazorPayOrder)

      var options: any = {
        key: GlobalStudioConstants.RazorPay_Key_Id, // Enter the Key ID generated from the Dashboard
        amount: finalamount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tarapara",
        description: "Tarapara",
        image: "",
        order_id: this.razorpayOrderId,
        handler: this.paymentHandler.bind(this),
        // prefill: {
        // "name": "Studio",
        // "email": "Studio.SSM@example.com",
        // "contact": "9999999999"
        // },
        notes: '',
        theme: {
          // color: "#e90e0e"
          color: "#ffffff"
        },
        modal: {
          ondismiss: (() => {
            this.zone.run(() => {
              this.router.navigate(['/myaccount']);
            });
          })
        }
      };
      this.rzp = new this.razorpayService.nativeWindow['Razorpay'](options);
      this.rzp.open();
      this.rzp.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        this.toastr.error(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      })
    }, error => {
      if (error.status == 403) {
        this.toastr.error("please login again.");
        localStorage.removeItem("studio-loginuser");
        localStorage.removeItem("studio-token");
        this.router.navigate(['']);
      } else {
        // console.log("create razorpay order api error");
      }
    })
  }

  // allpaymentHandler(res: any) {
  //   this.zone.run(() => {
  //     console.log(res);//get payment id if payment successfully done
  //     this.razorpayOrderId = res.razorpay_order_id;
  //     this.transactionId = res.razorpay_payment_id;
  //     this.razorpay_signature = res.razorpay_signature;
  //     this.updateallSelectedPayment();
  //   }, error => {
  //     console.log("razorpay transaction handler error");
  //   });
  // }

  // updateallSelectedPayment() {
  //   this.checkoutService.updateSelectedOrderPayment(this.listOfPaymentId,this.razorpayOrderId, this.transactionId, this.razorpay_signature,this.txtstatus).subscribe(res => {
  //     console.log(res);
  //     this.toastr.success("Payment success.");
  //     this.getallDraft();
  //     this.razorpayOrderId = null;
  //     this.transactionId = null;
  //     this.razorpay_signature = null;
  //     this.listOfPaymentId = [];
  //   }, error => {
  //     this.toastr.error("Payment not success.");
  //   })
  // }

  deleteId: any;
  deleteDraftOrder(data) {
    this.deleteId = data.orderDetailsId;
  } 
  
  confirmDelete() {
    this.checkoutService.deleteOrderDetail(this.deleteId).subscribe(res=>{
      this.toastr.success("Order deleted successfully");
      this.getallDraft();
      this.deleteId = null;
      $('#deleteModal').modal('hide');
    }, error =>{
      this.toastr.error("Order not deleted.");
    })
  }
}
