<div class="detail_pages main_component">
<app-sub-header></app-sub-header>
<div id="main" class="mb-4">
    <!-- <div class="breadcrumb">
        <div class="wrapper">
          <div class="breadcrumb_inner">
            <ul class="d-flex flex-row flex-wrap justify-content-start align-items-center">
              <li><a href="#">Home</a></li>
              <li><span>My Account</span></li>
            </ul>
          </div>
        </div>
    </div> -->
    <div class="wrapper">
        <div class="account_page">
        <!-- <div class="container account_page header-manage"> -->
            <div class="product-page-section product-page-details">
                <section class="product-list-section">
                    <!-- <div class="row">
                        <div class="col">
                            <h1 class="inner_title">Orders</h1>
                        </div>
                    </div> -->
                    <div class="row g-4 mt-1">
                        <div class="col-lg-12 account_left_block">
                            <div class="card wish-list mb-3">
                                <div class="card-body">
                                    <div class="payment-section mt-3">
                                        <!-- <div class="row">
                                            <div class="col-12 col-sm-auto mb-3">
                                                <div class="mx-auto" style="width: 140px;">
                                                    <div class="d-flex justify-content-center align-items-center rounded"
                                                        style="height: 140px; background-color: rgb(233, 236, 239);">
                                                        <span style="color: rgb(166, 168, 170); font: bold 25pt Arial;">{{profilename}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                                                <div class="text-center text-sm-start mb-2 mb-sm-0">
                                                    <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">{{userName}}</h4>
                                                </div>
                                            </div>
                                        </div> -->
                                        <ul id="pills-tab" role="tablist" class="nav nav-pills nav-fill mb-3 payment-tab">
                                            <li class="nav-item" role="presentation"><a class="active nav-link"
                                                    id="userOrder-tab" data-bs-toggle="pill" href="#userOrder" role="tab"
                                                    aria-controls="userOrder" aria-selected="true" (click)="getallDraft()">Orders</a></li>
                                            <li class="nav-item" role="presentation"><a class="nav-link"
                                                    id="settings-tab" data-bs-toggle="pill" href="#settings" role="tab"
                                                    aria-controls="settings" aria-selected="true" (click)="getallDraft()">Draft Order</a></li>
                                            <li class="nav-item" role="presentation"><a class="nav-link" id="address-tab"
                                                    data-bs-toggle="pill" href="#address" role="tab" aria-controls="address"
                                                    aria-selected="false" (click)="getallOrderHistory()">Order History</a></li>
                                            <li class="nav-item" role="presentation"><a class="nav-link" id="charges-tab"
                                                data-bs-toggle="pill" href="#charges" role="tab" aria-controls="charges"
                                                aria-selected="false">Setting</a></li>
                                        </ul>
                                        <div class="tab-content" id="pills-tabContent">
                                            <div class="tab-pane fade show active pt-3" id="userOrder" role="tabpanel"
                                                aria-labelledby="settings-tab">
                                                <div class="col-lg-12">
                                                    <div class="row me-1">
                                                        <div class="col-auto" style="margin-left: 10px;">
                                                        <mat-form-field>
                                                            <!-- <mat-label>Search</mat-label> -->
                                                            <span class="d-flex justify-content-start">
                                                            <input matInput (keyup)="applyOrdersFilter($event)" placeholder="Search" #input>
                                                            <i class="fa fa-search"></i>
                                                            </span>
                                                        </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="mat-elevation-z8">
                                                    <div class="data-container">
                                                        <table mat-table [dataSource]="customerDataSource" #customerSort="matSort" matSort>
                                                        <!-- Name Column -->
                                                        <ng-container matColumnDef="serid">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;"># </th>
                                                            <td mat-cell *matCellDef="let row;let i = index" role="button" style="vertical-align: middle;"> {{(customerPaginator.pageIndex * customerPaginator.pageSize) + (i+1)}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderId">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Order</th>
                                                            <td mat-cell *matCellDef="let row" role="button" class="orderlink" data-bs-toggle="modal" data-bs-target="#OrderDetail" (click)="getSelectedOrderDetail(row)"> {{row.orderNumber}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderDate">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Order Date </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.createdDate | date:'dd-MMM-yyyy'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="customername">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Customer </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.customerName}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="custmobile">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Mobile No.</th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.shippingMobile}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.orderAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="gstAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Gst Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.gstAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="deliveryCharges">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Delivery Charges </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.deliveryCharges | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="finalOrderAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Total Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.finalOrderAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="expectedDeliveryDate">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Delivery </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.deliveryDate}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="action">
                                                            <th mat-header-cell *matHeaderCellDef class="title-header"  style="vertical-align: middle;"> Action </th>
                                                            <td mat-cell *matCellDef="let row;let i = index" style="vertical-align: middle;">
                                                            <a role="button" title="Print" style="color: black;font-size: 20px; margin-left: 5px;" (click)="downloadBill(row)"><i class="fa fa-print" aria-hidden="true"></i></a>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row class="tr-row" *matHeaderRowDef="displayedCustColumns;sticky: true "></tr>
                                                        <tr mat-row  class="tr-row" *matRowDef="let row; columns: displayedCustColumns;" ></tr>

                                                        <tr class="mat-row" *matNoDataRow>
                                                            <td class="mat-cell text-center" colspan="11">No Data Found</td>
                                                        </tr>
                                                        </table>
                                                        <!-- spinnner element  -->
                                                        <div class="d-flex justify-content-center mt-4">
                                                            <div class="spinner-border text-dark orderhidespinner" style="width: 4rem; height: 4rem;" role="status">
                                                                <span class="sr-only"></span>
                                                            </div>
                                                        </div>
                                                        <div class="orderpleasewait mt-1">
                                                            <p class="orderpleasewait text-center">Loading...</p>
                                                        </div>
                                                        <!-- Spinner Over-->
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <mat-paginator #customerPaginator class="d-flex" style="width: 100%;" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane pt-3" id="settings" role="tabpanel"
                                                aria-labelledby="settings-tab">
                                                <div class="col-lg-12">
                                                    <!-- <button class="btn_fill" style="float: left;height: 30px;display: flex; justify-content: center;align-items: center;" (click)="makeAllPayment()">Pay All</button> -->
                                                    <div class="row me-1">
                                                        <div class="col-auto" style="margin-left: 10px;">
                                                        <mat-form-field>
                                                            <!-- <mat-label>Search</mat-label> -->
                                                            <span class="d-flex justify-content-start">
                                                            <input matInput (keyup)="applyDraftFilter($event)" placeholder="Search" #input>
                                                            <i class="fa fa-search"></i>
                                                            </span>
                                                        </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="mat-elevation-z8">
                                                    <div class="data-container">
                                                        <table mat-table [dataSource]="dataSource" #draftSort="matSort" matSort>
                                                        <!-- Name Column -->
                                                        <ng-container matColumnDef="serid">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;"># </th>
                                                            <td mat-cell *matCellDef="let row;let i = index" role="button" style="vertical-align: middle;"> {{(draftPaginator.pageIndex * draftPaginator.pageSize) + (i+1)}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderId">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Order</th>
                                                            <td mat-cell *matCellDef="let row" role="button" class="orderlink" data-bs-toggle="modal" data-bs-target="#OrderDetail" (click)="getSelectedOrderDetail(row)"> {{row.orderNumber}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderDate">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Order Date</th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.createdDate | date:'dd-MMM-yyyy'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="customername">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Customer</th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.customerName}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="custmobile">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Mobile</th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.shippingMobile}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="frameAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Base ₹</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.baseAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="designCharge">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Design ₹</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.designCharge | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="adjustmentAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Adjustment ₹</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.adjustmentAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="gstAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Gst ₹</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.gstAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="deliveryCharges">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Delivery ₹</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.deliveryCharges | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="finalOrderAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Customer ₹</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" class="dealer-cell"> ₹ {{row.finalOrderAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="dlrNetAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Dealer Net ₹</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" class="dealer-cell"> ₹ {{row.dlrNetAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="dlrGstAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Dealer Gst </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center"class="dealer-cell"> ₹ {{row.dlrGstAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="dlrFinalAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Total Payable </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" class="dealer-cell"> ₹ {{row.dlrFinalAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="commission">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Commission</th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" class="dealer-cell"> ₹ {{row.dlrCommission | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <!-- <ng-container matColumnDef="totalAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Dealer Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.totalAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="dlrWithoutGst">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Dealer Without Gst Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.dlrWithoutGst | number:'1.2-2'}}</td>
                                                        </ng-container> -->
                                                        <!-- <ng-container matColumnDef="address">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Address </th>
                                                            <td mat-cell *matCellDef="let row"> {{row.address}}</td>
                                                        </ng-container> -->
                                                        <ng-container matColumnDef="expectedDeliveryDate">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Delivery </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.deliveryDate}}</td>
                                                        </ng-container>
                                                        <!-- <ng-container matColumnDef="orderStatus">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Order Status </th>
                                                            <td mat-cell *matCellDef="let row"> {{row.orderStatus}}</td>
                                                        </ng-container> -->
                                                        <ng-container matColumnDef="action">
                                                            <th mat-header-cell *matHeaderCellDef class="title-header"  style="vertical-align: middle;">
                                                              <div style="float: left;" >Action</div>
                                                                <div style="float: left; padding-left: 6.6rem;" align="right"><input type="checkbox" style="margin-left: 5px;height: 15px;width: 15px;" id="chkAllPayment" (click)="checkAllOrder()"></div>
                                                            </th>
                                                            <td mat-cell *matCellDef="let row;let i = index" style="vertical-align: middle;">
                                                              <div class="td-icons">
                                                               <a role="button" title="Payment" style="color: black;font-size: 18px;" (click)="createRazorPayOrderId(row)"><i class="fa fa-credit-card" aria-hidden="true"></i></a>
                                                              </div>
                                                              <div class="td-icons">
                                                               <a role="button" title="Print" style="color: black;font-size: 18px; margin-left: 5px;" (click)="downloadBill(row)"><i class="fa fa-print" aria-hidden="true"></i></a>
                                                              </div>
                                                              <div class="td-icons">
                                                              <a role="button" title="Delete" style="color: #ff3102;font-size: 18px; margin-left: 5px;" data-bs-toggle="modal" data-bs-target="#deleteModal" (click)="deleteDraftOrder(row)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                                              </div>
                                                              <div class="td-icons">
                                                              <input type="checkbox" style="margin-left: 5px;height: 15px;width: 15px;" [(ngModel)]="paymentCheckBox[i]" [id]="'onePayment'+i" (click)="selectedPaymentOrder(row)">
                                                              </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row class="tr-row" style="background-color: #3d3d3df5;" *matHeaderRowDef="displayedColumns;sticky: true "></tr>
                                                        <tr mat-row class="tr-row" *matRowDef="let row; columns: displayedColumns;"></tr>

                                                        <tr class="mat-row" *matNoDataRow>
                                                            <td class="mat-cell text-center" colspan="17">No Data Found</td>
                                                        </tr>
                                                        </table>
                                                        <!-- spinnner element  -->
                                                        <div class="d-flex justify-content-center mt-4">
                                                            <div class="spinner-border text-dark orderhidespinner" style="width: 4rem; height: 4rem;" role="status">
                                                                <span class="sr-only"></span>
                                                            </div>
                                                        </div>
                                                        <div class="orderpleasewait mt-1">
                                                            <p class="orderpleasewait text-center">Loading...</p>
                                                        </div>
                                                        <!-- Spinner Over-->
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <mat-paginator #draftPaginator class="d-flex" style="width: 100%;" [pageSizeOptions]="[20, 50, 100]">
                                                        </mat-paginator>
                                                        <p class="d-flex text-center align-items-center pe-4" style="width: auto;height: 55px;background-color: white; font-size: 18px;">₹{{finalNetAmount | number:'1.2-2'}}</p>
                                                        <button style="width: 100px;height: 55px; background-color: black;color: white;" (click)="makeAllPayment()">Pay</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane pt-3" id="address" role="tabpanel"
                                                aria-labelledby="address-tab">

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-auto" style="margin-left: 10px;">
                                                        <mat-form-field>
                                                            <!-- <mat-label>Search</mat-label> -->
                                                            <span class="d-flex justify-content-start">
                                                            <input matInput (keyup)="applyHistoryFilter($event)" placeholder="Search" #input>
                                                            <i class="fa fa-search"></i>
                                                            </span>
                                                        </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="mat-elevation-z8">
                                                    <div class="data-container">
                                                        <table mat-table [dataSource]="dataSourceModal" #detailsort="matSort" matSort>
                                                        <!-- Name Column -->
                                                        <ng-container matColumnDef="serid">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;"># </th>
                                                            <td mat-cell *matCellDef="let row;let i = index" style="vertical-align: middle;" role="button"> {{(detailPaginator.pageIndex * detailPaginator.pageSize) + (i+1)}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderId">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Order</th>
                                                            <td mat-cell *matCellDef="let row" class="orderlink" role="button" data-bs-toggle="modal" data-bs-target="#OrderDetail" (click)="getSelectedOrderDetail(row)"> {{row.orderNumber}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderDate">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Order Date </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.createdDate | date:'dd-MMM-yyyy'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="customername">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Customer </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.customerName}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="custmobile">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Mobile No. </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.shippingMobile}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.orderAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="deliveryCharges">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Delivery Charges </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.deliveryCharges | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="finalOrderAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Total Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.finalOrderAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="totalAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Net Amount </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;"> ₹ {{row.totalAmount | number:'1.2-2'}}</td>
                                                        </ng-container>
                                                        <!-- <ng-container matColumnDef="address">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Address </th>
                                                            <td mat-cell *matCellDef="let row"> {{row.address}}</td>
                                                        </ng-container> -->
                                                        <ng-container matColumnDef="expectedDeliveryDate">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Delivery </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.deliveryDate}}</td>
                                                        </ng-container>
                                                        <!-- <ng-container matColumnDef="shipmentID">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Shipment Id </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.shipmentId}}</td>
                                                        </ng-container> -->
                                                        <ng-container matColumnDef="shipmentOrderID">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Ship Order # </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.shipmentOrderId}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="orderStatus">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Status </th>
                                                            <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.orderStatus}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="action">
                                                            <th mat-header-cell *matHeaderCellDef class="title-header"  style="vertical-align: middle;"> Action </th>
                                                            <td mat-cell *matCellDef="let row" class="text-center" style="vertical-align: middle;">
                                                            <a role="button" title="Print" style="color: black;font-size: 20px;" (click)="downloadBill(row)"><i class="fa fa-print" aria-hidden="true"></i></a>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row class="tr-row" style="background-color: #3d3d3df5;" *matHeaderRowDef="displayedModalColumns;sticky: true "></tr>
                                                        <tr mat-row class="tr-row" *matRowDef="let row; columns: displayedModalColumns;"></tr>

                                                        <tr class="mat-row" *matNoDataRow>
                                                            <td class="mat-cell text-center" colspan="13">No Data Found</td>
                                                        </tr>
                                                        </table>
                                                        <!-- spinnner element  -->
                                                        <div class="d-flex justify-content-center mt-4">
                                                            <div class="spinner-border text-dark orderhistoryhidespinner" style="width: 4rem; height: 4rem;" role="status">
                                                                <span class="sr-only"></span>
                                                            </div>
                                                        </div>
                                                        <div class="orderhistorypleasewait mt-1">
                                                            <p class="orderhistorypleasewait text-center">Loading...</p>
                                                        </div>
                                                        <!-- Spinner Over-->
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <mat-paginator #detailPaginator class="d-flex" style="width: 100%;" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
                                                    </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="tab-pane pt-3" id="charges" role="tabpanel"
                                                aria-labelledby="charges-tab">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-auto" style="margin-left: 10px; height:46px;">

                                                        </div>
                                                    </div>
                                                    <div class="mat-elevation-z8 mt-2">
                                                        <div class="data-container">
                                                            <table mat-table [dataSource]="dataSourcecharges" #chargessort="matSort" matSort>
                                                                <!-- Name Column -->
                                                                <!-- <ng-container matColumnDef="serid">
                                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;"># </th>
                                                                    <td mat-cell *matCellDef="let row;let i = index" style="vertical-align: middle;" role="button"> {{(settingdetailPaginator.pageIndex * settingdetailPaginator.pageSize) + (i+1)}}</td>
                                                                </ng-container> -->
                                                                <ng-container matColumnDef="DesignCharges">
                                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Design Charge</th>
                                                                    <td mat-cell *matCellDef="let row" style="vertical-align: middle;" role="button"> ₹ {{row.designcharge | number:'1.2-2'}}</td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="AdjustmentPersontage">
                                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Adjustment(%)</th>
                                                                    <td mat-cell *matCellDef="let row" style="vertical-align: middle;"> {{row.adjustmentpercentage}}</td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="action">
                                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-header" style="vertical-align: middle;">Action </th>
                                                                    <td mat-cell *matCellDef="let row" style="vertical-align: middle;" data-bs-toggle="modal" data-bs-target="#chargesModel" (click)="updateCharges()"> <i class="fa fa-edit" aria-hidden="true"></i></td>
                                                                </ng-container>

                                                                <tr mat-header-row class="tr-row" style="background-color: #3d3d3df5;" *matHeaderRowDef="displayedchargesColumns;sticky: true "></tr>
                                                                <tr mat-row class="tr-row" *matRowDef="let row; columns: displayedchargesColumns;"></tr>

                                                                <tr class="mat-row" *matNoDataRow>
                                                                    <td class="mat-cell text-center" colspan="4">No Data Found</td>
                                                                </tr>
                                                                </table>
                                                        </div>
                                                        <!-- <div class="d-flex align-items-center">
                                                            <mat-paginator #settingdetailPaginator class="d-flex" style="width: 100%;" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- <div class="col-lg-4 account_right_block">
                        <div class="account_right_box">
                            <div class="card mb-3">
                            <div class="card-body">
                                <div class="px-xl-3">
                                    <button class="btn btn-block btn-secondary">
                                        <i class="fas fa-power-off"></i>
                                        <span style="margin-left: 5px;">Logout</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-body">
                                <h6 class="card-title font-weight-bold">Support</h6>
                                <p class="card-text">Get fast, free help from our friendly assistants.</p>
                                <button type="button" class="btn btn-primary">Contact Us</button>
                            </div>
                        </div>
                        </div>
                        </div> -->
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<app-footer-studio class="fix-footer"></app-footer-studio>
</div>
<div class="modal fade shipping_address" id="OrderDetail" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Order Detail({{orderNumber}})</h5>
          <a role="button" title="Print" style="color: black;font-size: 18px;margin-left: 15px;" (click)="modalDownloadBill()"><i class="fa fa-print" aria-hidden="true"></i></a>
          <a role="button" class="btn_fill ms-4" title="Download Image" [href]="dwnldOriginalimage" style="background-color: black;color: white;padding: 0.4rem 1.7rem;line-height: 3rem;height: 4rem;" >Download Image</a>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- spinnner element  -->
      <div class="d-flex justify-content-center">
          <div class="spinner-border text-dark modalhidespinner" style="width: 4rem; height: 4rem;" role="status">
              <span class="sr-only"></span>
          </div>
      </div>
      <div class="modalpleasewait mt-1">
          <p class="modalpleasewait text-center">Loading...</p>
      </div>
      <!-- Spinner Over-->
        <div class="modal-body orderbody">
         <form action="" *ngIf="modalEnable">
            <!-- <div class="form_group form_column_tow" >
                <div class="form_box">
                  <p class="mb-3"><span style="font-size: medium;">Client Name :</span><span class="ms-4">{{customer_name}}</span></p>
                  <p class="mb-2"><span style="font-size: medium;">Client Mobile :</span><span class="ms-3">{{customer_Mobile}}</span></p>
                  <p class="mb-2"><span style="font-size: medium;">Address :</span> &nbsp;&nbsp;&nbsp;<span class="ms-5">{{shipaddres}}</span></p>
                  <p>
                      <img [src]="shipMianImage" height="250px" style="max-width: 100%;">
                  </p>
                </div>
                <div class="form_box">
                    <p class="mb-2"><span style="font-size: medium;">Order Amount :</span><span class="ms-4">&nbsp;&nbsp;₹{{orderAmount - gstAmount  | number : '1.2-2'}}</span></p>
                    <p class="mb-2"><span style="font-size: medium;">Delivery Charge :</span><span class="ms-3">₹{{baseGstamount  | number : '1.2-2'}}</span></p>
                    <p class="mb-2"><span style="font-size: medium;">GST Amount :</span><span class="ms-4">&nbsp;&nbsp;&nbsp;&nbsp;₹{{gstAmount + (deliveryCharge - baseGstamount)  | number : '1.2-2'}}</span></p>
                    <p class="mb-2"><span style="font-size: medium;">Total Amount :</span><span class="ms-4">&nbsp;&nbsp;₹{{totalAmount  | number : '1.2-2'}}</span></p>
                    <p>
                        <img [src]="shipPreviewImage" height="250px" style="max-width: 100%;">
                    </p>
                </div>
            </div> -->


            <div class="form_group form_column_tow">
                <div class="form_box">
                  <table class="table">
                      <tbody>
                          <tr>
                              <th class="static-td-width">Client Name </th>
                              <td>{{customer_name}}</td>
                          </tr>
                          <tr>
                              <th class="static-td-width">Client Mobile</th>
                              <td>{{customer_Mobile}}</td>
                          </tr>
                          <tr>
                              <th class="static-td-width">Address</th>
                              <td>{{shipaddres}}</td>
                          </tr>
                          <!-- <p>
                            <img [src]="shipMianImage" height="250px" style="max-width: 100%;">
                          </p> -->
                      </tbody>
                  </table>
                </div>
                <div class="form_box">
                  <table class="table">
                      <tbody>
                          <tr>
                              <th class="static-td-width">Order Amount </th>
                              <td>₹ {{orderAmount - gstAmount  | number : '1.2-2'}}</td>
                          </tr>
                          <tr>
                              <th class="static-td-width">Delivery Charge</th>
                              <td>₹ {{deliveryCharge | number : '1.2-2'}}</td>
                          </tr>
                          <tr>
                              <th class="static-td-width">GST Amount</th>
                              <!-- <td>₹ {{gstAmount + (deliveryCharge - baseGstamount)  | number : '1.2-2'}}</td> -->
                              <td>₹ {{gstAmount | number : '1.2-2'}}</td>
                          </tr>
                          <tr>
                              <th class="static-td-width">Total Amount</th>
                              <td>₹ {{totalAmount  | number : '1.2-2'}}</td>
                          </tr>
                          <!-- <p>
                              <img [src]="shipPreviewImage" height="250px" style="max-width: 100%;">
                          </p> -->
                      </tbody>
                  </table>
                </div>
            </div>

            <div class="form_group form_column_tow">
                <div class="form_box">
                  <table class="table">
                      <tbody>
                          <p>
                            <img [src]="shipMianImage" height="250px" style="max-width: 100%;">
                          </p>
                      </tbody>
                  </table>
                </div>
                <div class="form_box">
                  <table class="table">
                      <tbody>
                          <p>
                              <img [src]="shipPreviewImage" height="250px" style="max-width: 100%;">
                          </p>
                      </tbody>
                  </table>
                </div>
            </div>

            <div class="form_group form_column_tow" style="border-bottom: 1px solid #ececec;">
                <!-- <div class="form_box">
                  <a role="button" class="btn_fill mb-3" title="Download Image" [href]="dwnldOriginalimage" style="background-color: black;color: white;" >Download Image</a>
                </div> -->
            </div>
            <div class="form_group form_column_tow">
              <div class="form_box">
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="static-td-width">Frame </th>
                            <td>{{orderMainFrameType}}</td>
                        </tr>
                        <tr>
                            <th class="static-td-width">Frame Type</th>
                            <td>{{orderFrameProfile}}</td>
                        </tr>
                        <tr *ngIf="orderMat != null">
                            <th class="static-td-width">Mount</th>
                            <td>{{orderMat}}</td>
                        </tr>
                        <tr *ngIf="orderWidth != null">
                            <th class="static-td-width">Width</th>
                            <td>{{orderWidth}}</td>
                        </tr>
                        <tr *ngIf="orderGlass != null">
                            <th class="static-td-width">Glass</th>
                            <td>{{orderGlass}}</td>
                        </tr>
                        <tr *ngIf="orderCanvasWrap != null">
                            <th class="static-td-width">Canvas Wrap</th>
                            <td>{{orderCanvasWrap}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
              <div class="form_box">
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="static-td-width">Size </th>
                            <td>{{orderSize}}</td>
                        </tr>
                        <tr>
                            <th class="static-td-width">Frame Color</th>
                            <td>{{orderFrameColor}}</td>
                        </tr>
                        <tr *ngIf="orderLamination != null">
                            <th class="static-td-width">Lamination</th>
                            <td>{{orderLamination}}</td>
                        </tr>
                        <tr *ngIf="orderMount != null">
                            <th class="static-td-width">Mount</th>
                            <td>{{orderMount}}</td>
                        </tr>
                        <tr *ngIf="orderCanvasType != null">
                            <th class="static-td-width">Canvas Type</th>
                            <td>{{orderCanvasType}}</td>
                        </tr>
                        <tr *ngIf="orderShape != null">
                            <th class="static-td-width">Shape</th>
                            <td>{{orderShape}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

<!-- Delete Modal -->
<div class="modal fade shipping_address" id="deleteModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Record</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body orderbody">
            <p>Are you sure you want to delete this record ?</p>
        </div>
        <div class="modal-footer">
            <button class="btn_fill btn-sm" style="height: 3.6rem;line-height: 2.7rem;" (click)="confirmDelete()">Confirm</button>
            <button class="btn_fill" style="height: 3.6rem;line-height: 2.7rem;" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

<div class="modal fade shipping_address" id="chargesModel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Charges</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearCharges()"></button>
      </div>
      <div class="modal-body">
        <form action="">
          <div class="form_group form_column_tow">
            <div class="form_box">
              <label class="mb-2" style="font-weight: 500;">&nbsp;Design Charge</label>
              <input type="text" placeholder="Design Charge" name="txtDesignCharge" [(ngModel)]="txtDesignCharge" (keypress)="numberandDotOnly($event)">
            </div>
            <div class="form_box">
                <label class="mb-2" style="font-weight: 500;">&nbsp;Adjustment(%)</label>
                <input type="text" placeholder="Adjustment(%)" name="txtAdjustmentPer" [(ngModel)]="txtAdjustmentPer" (keypress)="numberandDotOnly($event)">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
       <button class="btn_fill" (click)="saveCharges()">Update</button>
      </div>
    </div>
  </div>
</div>
