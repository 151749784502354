import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentYear: any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  redirecthomeabout() {
    var cmp =  localStorage.getItem("component");
    if (cmp == "HomeComponent") {
      window.location.reload();
    } else {
      this.router.navigate(['']);
    }
  }
}
