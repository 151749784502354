<header id="header" class="balck-header">
    <div class="header_content">      
        <nav class="navbar navbar-expand-lg p-0">
          <div class="wrapper d-flex flex-row flex-wrap justify-content-start align-items-center header-manage">
            <a class="logo me-auto" role="button">
              <!-- <img src="assets/img/logo.png" alt="">-->
              <p>{{userName}}</p>
            </a>
            <div class="collapse navbar-collapse nav" id="navbarNav">        
              <ul class="navbar-nav header-link">
                <li class="menu-item">
                  <a class="nav-link d-block p-0 _14_b_bj" aria-current="page" role="button" (click)="goToStudioPage()">
                      <i class="fa fa-plus" aria-hidden="true"></i> Orders
                  </a>
                </li>
                <li class="menu-item">
                  <a class="nav-link d-block p-0 _14_b_bj" aria-current="page" role="button" (click)="goToAccount()" >Account</a>
                </li>
                <li class="menu-item">
                  <a class="nav-link d-block p-0 _14_b_bj" aria-current="page" role="button" (click)="logoutUser()">Logout</a>
                </li>
                <!-- <li class="menu-item">
                  <a class="nav-link d-block p-0 _14_b_bj" role="button">Segment</a>
                  <ul class="dropdown">
                    <li><a role="button" (click)="goToStudioPage()">Studio</a></li>
                    <li><a role="button">Hospital</a></li>
                    <li><a role="button">Hotel</a></li>
                    <li><a role="button">Interior</a></li>
                  </ul>
                </li> -->
                <!-- <li class="menu-item" *ngIf="!isLoginUser">
                  <a class="nav-link" href="javascript:void(0);" data-bs-toggle="modal"
                    data-bs-target="#loginModal" tooltip="LOGIN/SIGN UP" flow="down">Login<i style="vertical-align: middle;" class="fas fa-sign-in-alt"></i>
                  </a>
              </li> -->
              </ul> 
          </div>              
            <!-- <div class="user_btn active" *ngIf="isLoginUser">
              <p>                  
                <a role="button" class="user_link d-flex flex-row flex-nowrap justify-content-between align-items-center">
                  <span class="user_name active">{{userName}}</span>
                  <img src="assets/img/user.svg" alt="">
                </a>
              </p>
              <ul class="dropdown_cart">
                <li><a role="button" [routerLink]="['/myaccount']" >My Account</a></li>
                <li><a role="button" (click)="logoutUser()">Logout</a></li>
              </ul>
            </div> -->
            <!-- <div class="cart_btn active">
              <a href="#" class="cart_link">
                <img src="assets/img/cart.svg" alt="">
                <span class="cart_velue">3</span>
              </a>
            </div> -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <!-- /container -->           
        </nav>
        <!-- /nav -->  
    </div>
    <!-- header_content -->
  </header>