<app-header></app-header>
<div id="main">
    <div class="wrapper">
      <section class="banner_black">
        <div class="banner_inner d-flex flex-row flex-wrap justify-content-between align-items-center">
          <div class="banner_right">
            <figure>
              <img src="assets/img/img_3.jpg" alt="">
            </figure>
          </div>
          <div class="banner_right">
            <section class="detail_form">
              <div class="detail_form_inner">
                <!-- <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="btn_fill active" id="login-tab" data-bs-toggle="pill" data-bs-target="#login-home"
                      type="button" role="tab" aria-controls="login-home" aria-selected="true">Login</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="btn_fill" id="signup-tab" data-bs-toggle="pill" data-bs-target="#signup-tab-profile"
                      type="button" role="tab" aria-controls="signup-tab-profile" aria-selected="false">SignUp</button>
                  </li>
                </ul> -->
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="login-home" role="tabpanel" aria-labelledby="login-tab">
                    <form action="">
                      <div class="tab_form tab_form_full d-flex flex-row flex-wrap align-items-stretch justify-content-center">
                        <div class="form-group">
                          <label name="email" for="exampleInputEmail1">Mobile Number <span style="color: red;">*</span></label>
                          <input type="tel" maxlength="10" class="form-control" id="floatingInput" required [(ngModel)]="txtmobilenumber"
                          name="txtmobilenumber" placeholder="eg. 9988774455" (keypress)="numberOnly($event)">
                        </div>
                        <div class="form-group" *ngIf="otpTextEnable">
                          <label for="exampleInputPassword1">OTP <span style="color: red;">*</span></label>
                          <input type="tel" class="form-control" id="floatingPassword" required [(ngModel)]="txtLoginOTP"
                          name="txtLoginOTP" placeholder="Enter OTP" (keypress)="numberOnly($event)">
                        </div>
                      </div>
                      <div style="text-align: center;margin: 2rem auto 0">
                        <button class="btn_fill login_inner_action_btn" type="submit" *ngIf="!btnVerifyEnable && !paymentBtn" (click)="loginUser()">Login</button>
                        <a role="button" id="logsenddiv1" style="margin-top: 10px; margin-left: 10%; float:left;display: none;" (click)="resendOtptouser()">Resend OTP?</a>
                        <button class="btn_fill login_inner_action_btn" type="submit" *ngIf="btnVerifyEnable" (click)="verifyUser()">Verify</button>
                        <button class="btn_fill login_inner_action_btn" type="submit" *ngIf="paymentBtn" (click)="createRazorPayOrderId()">Make Payment</button>
                        <button class="btn_fill login_inner_action_btn" type="submit" (click)="gotoSignUp()">SignUp</button>
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="signup-tab-profile" role="tabpanel" aria-labelledby="signup-tab">
                    <form action="">
                      <div class="tab_form d-flex flex-row flex-wrap align-items-stretch justify-content-center">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Company Name <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="txtDealerName" name="txtDealerName"
                          id="floatingName" placeholder="Company Name" (focusout)="toUpperCaseDealerName()">
                        </div>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Owner Name  <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="txtOwnerName" name="txtOwnerName"
                          id="floatingName" placeholder="Owner Name" (focusout)="toUpperCaseOwnerName()">
                        </div>
                        <div class="form-group">
                          <label name="email" for="exampleInputEmail1">Contact Person <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" required [(ngModel)]="txtContactPerson"
                          name="txtContactPerson" id="floatingInstitute" (focusout)="toUpperCaseContactPerson()" placeholder="Contact Person">
                        </div>
                        <div class="form-group" style="position: relative;">
                          <label for="exampleInputPassword1">Sales Person <span style="color: red;"></span></label>
                          <input type="text" id="floatingName" class="form-control" name="txtSalesPerson" [(ngModel)]="txtSalesPerson" 
                          placeholder="Sales Person" [value]="searchSalesPerson" [typeahead]="searchSalesPerson"
                          [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="10" typeaheadOptionField="salesCode" 
                          (typeaheadOnSelect)="selectedSalesPerson($event)" [typeaheadItemTemplate]="rct">
                          <ng-template #rct let-rc="item">
                            <div class="mb-0 ">
                              <span class="m-1">{{rc.salesCode}} - {{rc.name}}</span>
                            </div>
                        </ng-template>
                          <!-- <input type="text" class="form-control" required [(ngModel)]="txtSalesPerson" name="txtSalesPerson"
                          id="floatingName" placeholder="Sales Person"> -->
                        </div>
                        <div class="form-group">
                          <label for="exampleInputPassword1">GSTIN <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" required [(ngModel)]="txtGstin"
                          name="txtGstin" id="floatingDise" placeholder="GSTIN">
                        </div>
                        <div class="form-group">
                          <label name="email" for="exampleInputEmail1">Email <span style="color: red;">*</span></label>
                          <input type="email" class="form-control" required [(ngModel)]="txtemail" name="txtemail"
                            id="floatingInput" placeholder="name@example.com">
                        </div>
                        <div class="form-group">
                          <label for="exampleInputPassword1">Mobile Number <span style="color: red;">*</span></label>
                          <input type="tel" class="form-control" required [(ngModel)]="txtsignupmobilenumber"
                            name="txtsignupmobilenumber" id="floatingName" (focusout)="checkUserMobileNoExit()" maxlength="10" placeholder="Mobile Number" (keypress)="numberOnly($event)">
                        </div>
                        <div class="form-group">
                          <label name="address" for="exampleInputaddress">Address <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" required [(ngModel)]="txtAddress" name="txtAddress"
                          id="floatingName" placeholder="Address">
                        </div>
                        <div class="form-group">
                          <label name="email" for="exampleInputEmail1">Country <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" required [(ngModel)]="txtCountry" name="txtCountry"
                          id="floatingName" placeholder="Country" (focusout)="toUpperCaseCountry()">
                        </div>
                        <div class="form-group">
                          <label for="exampleInputPassword1">State <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" required [(ngModel)]="txtstate" name="txtstate"
                          id="floatingName" placeholder="State" (focusout)="toUpperCaseState()">
                        </div>
                        <div class="form-group">
                          <label name="email" for="exampleInputEmail1">City <span style="color: red;">*</span></label>
                          <input type="text" class="form-control" required [(ngModel)]="txtcity" name="txtcity"
                          id="floatingName" placeholder="City" (focusout)="toUpperCaseCity()">
                        </div>
                        <div class="form-group">
                          <label for="exampleInputPassword1">Pincode <span style="color: red;">*</span></label>
                          <input type="tel" class="form-control" required [(ngModel)]="txtpincode" name="txtpincode"
                          id="floatingName" maxlength="6" placeholder="Pincode" (keypress)="numberOnly($event)">
                        </div>
                      </div>
                      <button class="btn_fill" type="submit" (click)="signup()">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <section class="framing_block">
        <div class="framing_inner">
          <br>
          <h2 class="section_title mo_35_b text-center">
            <!-- <span class="mo_14_m">You can Framebridge just about anything.</span> -->
            True <strong>custom framing</strong> made<br> truly simple.
          </h2>
          <ul class="d-flex flex-row flex-wrap justify-content-between align-items-stretch">
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/step_1.svg" alt="">
                </figure>
                <h3 class="mo_20_r">Sing up with Details</h3>
              </div>
            </li>
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/step_2.svg" alt="">
                </figure>
                <h3 class="mo_20_r">One time registration Fees</h3>
              </div>
            </li>
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/step_3.svg" alt="">
                </figure>
                <h3 class="mo_20_r">Approval from Admin</h3>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <!-- <section class="detail_form text-center">
        <button class="btn_fill" type="submit" [routerLink]="['/studio-reg']">SignUp</button>
      </section> -->
    </div>
</div>
  <!-- #main -->
<app-footer></app-footer>
<div class="modal fade shipping_address" id="studioOTPModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Verify OTP</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form action="">
            <div class="form_group form_column_one">
              <div class="form_box">
                <input type="tel" class="form-control" id="pass" name="txtUserOtp"
                [(ngModel)]="txtUserOtp" placeholder="Enter OTP" (keypress)="numberOnly($event)">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
            <a class="" role="button" id="studioresenddivReg" style="float:left; display: none;" title="Resend OTP" rel="nofollow"
            (click)="regResendOtptouser()">Resend OTP?</a>
         <button class="btn_fill" (click)="regVerifyUser()">Verify</button>
        </div>
      </div>
    </div>
</div>