import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-studio-init',
  templateUrl: './studio-init.component.html',
  styleUrls: ['./studio-init.component.css']
})
export class StudioInitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    localStorage.setItem("component", this.constructor.name);
  }

}
