import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(
    private http: Http,
  ) { }

  checkUserMobileNoExit(txtmobilenumber: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/clientuser/mobile/' + txtmobilenumber, { headers })
      .pipe(map(res => res.json()));
  }
  
  checkDealerExitOrNot(mobile: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/dealer/check/' + mobile, { headers })
      .pipe(map(res => res.text()));
  }

  signup(txtDealerName: string, txtOwnerName: string, txtContactPerson: string, txtsignupmobilenumber: number, txtemail: string, txtGstin: number, txtCity: string, txtState: string, txtPincode: number, txtCountry: string,txtAddress :string, txtSalesPerson: string) {
    const headers = new Headers
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      companyName: txtDealerName,
      ownerPerson: txtOwnerName,
      contactPerson: txtContactPerson,
      mobile: txtsignupmobilenumber,
      email: txtemail,
      gSTIN: txtGstin,
      city: txtCity,
      state: txtState,
      pincode: txtPincode,
      country: txtCountry,
      address: txtAddress,
      refId: txtSalesPerson,
      // refId: txtRedId
      // description: txtAction,
      // companyName: txtCompanyName
    });
    return this.http.post('/api/dealer', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateRegUser(userid: string, txtfirstname: string, txtlastname: string, txtemail: string, txtmobilenumber: string, txtaddress: string, txtcity: string, txtstate: string, txtpincode: string, txtuserMode: string, txtContactPerson: string, txtInstituteDiseNo: string, txtInstituteType: string) {
    const headers = new Headers
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      clientuserid: userid,
      firstName: txtfirstname,
      lastName: txtlastname,
      email: txtemail,
      mobileNo: txtmobilenumber,
      address1: txtaddress,
      city: txtcity,
      state: txtstate,
      pincode: txtpincode,
      userMode: txtuserMode,
      contactPerson: txtContactPerson,
      diseNumber: txtInstituteDiseNo,
      instituteType: txtInstituteType,
    });
    return this.http.put('/api/update/clientuser', body, { headers })
      .pipe(map(res => res.json()));
  }


  sendOtptouser(mobileno: string, status: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // var templateid = "1207161616660524493"
    // var verifymsg = " Welcome to kevalam.info, your OTP code is " + otp + ". Thank you for registering on our website."
    return this.http.get("/api/get/otp/" + status + "/" + mobileno, { headers })
      .pipe(map(res => res));
  }

  loginUserOtp(mobileno: string, status: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // var templateid = "1207161616654896090"
    // var verifymsg = " Welcome to kevalam.info, your OTP code is " + otp + ". Thank you for login on our website."
    return this.http.get("/api/get/otp/" + status + "/" + mobileno, { headers })
      .pipe(map(res => res.json()));
  }

  getLoginUserData() {
    var headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/client/get/loginuser', { headers: headers })
      .pipe(map(res => res.json()));
  }

  authenticatUser(txtmobile: string, otp: string) {
    var headers = new Headers();
    // var token = localStorage.getItem('studio-token');
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      mobile: txtmobile,
      otp: otp,
    });
    return this.http.post('/api/auth/user', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getOrderHistoryByUser(txtmobilenumber: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/orderhistory/user/' + txtmobilenumber, { headers })
      .pipe(map(res => res.json()));
  }

  cancelShipOrder(txtshiporderid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.post('/api/cancel/ship/order/' + txtshiporderid, { headers })
      .pipe(map(res => res));
  }

  getDealerDataById(id: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('studio-token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/dealer/' + id, { headers })
      .pipe(map(res => res.json()));
  }
  
  getDealerByMobile(mobile: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('studio-token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/dealer/mobile/' + mobile, { headers })
      .pipe(map(res => res.json()));
  }
}
