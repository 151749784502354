import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { SignupService } from '../service/signup/signup.service';
import { CheckoutService } from '../service/checkout/checkout.service';
import { ShippingOrderDTO } from '../service/checkout/ShippingOrderDTO';

@Component({
  selector: 'app-cart-detail',
  templateUrl: './cart-detail.component.html',
  styleUrls: ['./cart-detail.component.css']
})
export class CartDetailComponent implements OnInit {

  txtBillingFirstName;
  txtBillingLastName;
  txtBillingEmail;
  txtBillingMobileNumber;
  txtBillingAddress;
  txtBillingState;
  txtBillingCity;
  txtBillingPincode;
  txtBillingCountry = "India";
  txtshippingFirstName;
  txtshippingLastName;
  txtshippingEmail;
  txtshippingMobileNumber;
  txtshippingAddress;
  txtshippingState;
  txtshippingCity;
  txtshippingPincode;
  txtshippingCountry = "India";
  existingpincode;
  existingShippincode = "394150";

  resExistUserData;
  txtuserId;
  billOrderId;
  userMobile;
  totalBill = 0;
  totalNetAmount = 0;
  totalDiscount = 0;
  shippingAmount = 0;
  totalWeight;
  cartItems;
  expectedDeliveryDate = "";

  constructor(
    private router: Router,
    private signupService: SignupService,
    private checkoutservice: CheckoutService,
  ) { }

  ngOnInit(): void {
    localStorage.setItem("component", this.constructor.name)
    this.getCartProduct();
    this.getUserData();
  }

  getCartProduct() {
    this.cartItems = JSON.parse(localStorage.getItem("studio-cartProducts"));
    // console.log(this.cartItems);
    if (this.cartItems == null || this.cartItems == [] || this.cartItems.length == 0) {
      this.totalBill = 0;
      this.totalNetAmount = 0;
      this.totalDiscount = 0;
      this.totalNetAmount = this.totalNetAmount + this.shippingAmount
      this.totalWeight = 0;
    } else {
      this.totalBill = 0;
      this.totalBill = 0;
      this.totalNetAmount = 0;
      this.totalDiscount = 0;
      this.totalWeight = 0;
      for (let cartitem of this.cartItems) {
        var sum = 0;
        sum = sum + cartitem.subTotal;
        this.totalBill = this.totalBill + sum;
        this.totalNetAmount = this.totalBill;

        this.totalWeight = this.totalWeight + cartitem.totalweight;
      }
      this.totalNetAmount = this.totalNetAmount + this.shippingAmount;
    }
  }

  removeItem(item) {
    var index = this.cartItems.indexOf(item);
    if (index !== -1) {
      this.cartItems.splice(index, 1);
      // this.toastr.success("item removed from cart.");
      localStorage.setItem('studio-cartProducts', JSON.stringify(this.cartItems));
    }
    this.getCartProduct();
  }
  getUserData() {
    this.getShippingCharges();
    this.signupService.getLoginUserData().subscribe(res => {
      // localStorage.setItem("studio-loginuser", JSON.stringify(res));
      this.resExistUserData = JSON.parse(localStorage.getItem("studio-loginuser"));
      this.txtuserId = this.resExistUserData.clientuserid;
      this.txtBillingMobileNumber = this.resExistUserData.mobileNo;
      this.userMobile = this.resExistUserData.mobileNo;
      this.txtBillingFirstName = this.resExistUserData.firstName;
      this.txtBillingLastName = this.resExistUserData.lastName;
      this.txtBillingEmail = this.resExistUserData.email;
      this.txtBillingAddress = this.resExistUserData.address1;
      this.txtBillingCity = this.resExistUserData.city;
      this.txtBillingState = this.resExistUserData.state;
      // this.txtAddress2 = this.resExistUserData.address2;
      this.txtBillingPincode = this.resExistUserData.pincode;
      this.existingpincode = this.resExistUserData.pincode;
      //same shipping address
      this.txtshippingMobileNumber = this.resExistUserData.mobileNo;
      this.txtshippingFirstName = this.resExistUserData.firstName;
      this.txtshippingLastName = this.resExistUserData.lastName;
      this.txtshippingEmail = this.resExistUserData.email;
      this.txtshippingAddress = this.resExistUserData.address1;
      this.txtshippingCity = this.resExistUserData.city;
      this.txtshippingState = this.resExistUserData.state;
      // this.txtAddress2 = this.resExistUserData.address2;
      this.txtshippingPincode = this.resExistUserData.pincode;
      this.existingShippincode = this.resExistUserData.pincode;
      // this.getAllBillAddressOfUserByMobile();
      this.getShippingCharges();
    }, error => {
      // this.toastr.error("please login.");
      // localStorage.removeItem("studio-loginuser");
      // localStorage.removeItem("studio-token");
      // this.router.navigate(['']);
    })
  }

  getShippingCharges() {
    this.shippingAmount = 0;
    this.totalNetAmount = this.totalBill;
    this.checkoutservice.getShippingCharges(this.existingShippincode, this.totalWeight).subscribe(res => {
      // console.log(res);
      this.shippingAmount = res.rate;
      this.expectedDeliveryDate = formatDate(res.etd, "dd-MM-yyyy", 'en-US')
      this.totalNetAmount = this.totalNetAmount + this.shippingAmount;
      // this.isMakePaymentBtn = true;
    }, error => {
      this.totalNetAmount = this.totalNetAmount + this.shippingAmount;
      // this.isMakePaymentBtn = false;
      this.expectedDeliveryDate = "";
      // let message = JSON.parse(error._body).message;
      // this.toastr.error(JSON.stringify(message));
    })
  }

  //============================create shipment order======================================// 
  txtorderid;
  maxbreadth;
  maxlength;
  maxheight;
  orderdate = formatDate(new Date(), "yyyy-MM-dd", 'en-US');
  billing_customer_name;
  shipping_is_billing = true;
  shipping_customer_name;
  shippingItemDetail: Array<ShippingOrderDTO> = [];
  mmtocmValue = 10;
  txtcurrency = "INR";
  razorpayOrderId;
  transactionId;
  razorpay_signature;
  createShippingOrder() {
    var maxlen = Math.max.apply(Math, this.cartItems.map(function (o) { return o.length; }))
    var maxhit = Math.max.apply(Math, this.cartItems.map(function (o) { return o.height; }))
    var maxbreadth = Math.max.apply(Math, this.cartItems.map(function (o) { return o.breadth; }))
    this.maxlength = maxlen / this.mmtocmValue;
    this.maxheight = maxhit / this.mmtocmValue;
    this.maxbreadth = maxbreadth / this.mmtocmValue;
    for (let item of this.cartItems) {
      var detail = new ShippingOrderDTO();
      detail.name = item.productName;
      detail.sku = "product" + item.productid;
      detail.units = item.productQuantity;
      detail.selling_price = item.productPrice;
      this.shippingItemDetail.push(detail);
    }
    if (this.shipping_is_billing == true) {
      this.shipping_customer_name = "";
      this.txtshippingAddress = "";
      this.txtshippingCity = "";
      this.txtshippingPincode = "";
      this.txtshippingCountry = "";
      this.txtshippingState = "";
      this.txtshippingEmail = "";
      this.txtshippingMobileNumber = "";
    }
    this.checkoutservice.createShippingOrder(this.txtorderid, this.orderdate, this.billing_customer_name, this.txtBillingAddress, this.txtBillingCity, this.txtBillingPincode, this.txtBillingState, this.txtBillingCountry, this.txtBillingEmail, this.txtBillingMobileNumber,
      this.shipping_is_billing, this.shipping_customer_name, this.txtshippingAddress, this.txtshippingCity, this.txtshippingPincode, this.txtshippingCountry, this.txtshippingState, this.txtshippingEmail, this.txtshippingMobileNumber, this.shippingItemDetail, this.totalBill,
      this.totalWeight, this.maxlength, this.maxheight, this.maxbreadth, this.razorpayOrderId, this.transactionId, this.razorpay_signature).subscribe(res => {
        // console.log(res);
        this.shippingItemDetail = [];
        this.shipping_is_billing = true;
        this.shippingAmount = 0;
        // this.toastr.success("order placed successfully.");
        // localStorage.removeItem("studio-cartProducts");
        // localStorage.setItem("ssm-invoiceid", this.billOrderId);
        // this.router.navigate(['/invoice']);
        var element = <HTMLInputElement>document.getElementById("orderbtn");
        element.disabled = false;
      }, error => {
        var element = <HTMLInputElement>document.getElementById("orderbtn");
        element.disabled = false;
        this.shippingAmount = 0;
        console.log("shipment order error");
        // this.toastr.error("something went wrong.");
      })
  }
}
