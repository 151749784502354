import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import myData from '../../assets/data/my-data.json';
import { CheckoutService } from '../service/checkout/checkout.service';
import { OrderDetailDto } from '../service/checkout/OrderDetailDto';
import { ProductsCartDetail } from '../service/checkout/ProductCartDetail';
import { SignupService } from '../service/signup/signup.service';
import { formatDate } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('canvasm1', { static: true }) canvasm1: ElementRef;
  @ViewChild('canvasthumb', { static: true }) canvasthumb: ElementRef;
  @ViewChild('canvasthumb2', { static: true }) canvasthumb2: ElementRef;
  @ViewChild('canvasthumb3', { static: false }) canvasthumb3: ElementRef;
  @ViewChild('canvasthumb4', { static: false }) canvasthumb4: ElementRef;
  @ViewChild('productComImg', { static: false }) productComImg: ElementRef;
  responseImg;
  jsonData = myData;
  subTypeDetail;
  framTypeColors = null;
  selectedUserImage;
  txtMount;
  txtGlass;
  txtLamination;
  txtMat;
  txtProfile;
  txtFramColor;
  txtSize;
  txtCanvasWrap;
  txtCanvasType;
  txtWidth;
  productimg = "https://ssm-image.s3.ap-south-1.amazonaws.com/Veer%20Savarkar_image.jpg";
  selectedTypeIndex = 0;
  sizeIndex = 0;
  profileIndex = 0;
  colorIndex = 0;
  matIndex = 0;
  laminationIndex = 0;
  glassIndex = 0;
  mountIndex = 0;
  dealerId;
  userDetail;
  txtShape;
  thumbStaticImages;
  constructor(
    private toastr: ToastrService,
    private checkoutService: CheckoutService,
    private signupService: SignupService,
  ) { }

  ngOnInit(): void {
    localStorage.setItem("component", this.constructor.name)
    this.getLoginUserData();
  }
  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }

  toUpperCaseCustomerName() {
    const temp = this.txtCustomerName;
    this.txtCustomerName = temp.charAt(0).toUpperCase() + temp.slice(1);
    return this.txtCustomerName;
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  tempDlrMobile: any;
  getLoginUserData() {
    this.signupService.getLoginUserData().subscribe(res => {
      // console.log(res);
      this.userDetail = res;
      this.dealerId = res.dealerId;
      this.existingShippincode = res.pincode;
      this.tempDlrMobile = res.mobile;
      if(res.adjustmentPersontage != null || res.designCharges != null){
        this.adjustmentPersontage = res.adjustmentPersontage;
        this.designCharge = res.designCharges;
      } else {
        this.adjustmentPersontage = 0;
        this.designCharge = 0;
      }
      this.getinitValue();
    }, error => {
      this.existingShippincode = null;
      this.dealerId = null;
      this.adjustmentPersontage = 0;
      this.designCharge = 0;
      this.getinitValue();
    })
  }
  profilePrice = 0;
  colorPrice = 0;
  matPrice = 0;
  laminationPrice = 0;
  glassPrice = 0;
  mountPrice = 0;
  totalAmount = 0;
  orderAmount = 0;
  designCharge = 0;
  adjustmentPersontage = 0;
  adjustmentAmount = 0;
  canvasWrapPrice = 0;
  canvasTypePrice = 0;
  widthPrice = 0;
  sizeWidth = [];
  getinitValue() {
    this.sizeWidth = [];
    this.subTypeDetail = this.jsonData.configs.types[0];
    this.manufacturingDays = this.jsonData.configs.types[0].manufacturingDays;
    this.txtSize = this.subTypeDetail.configs[0].size[0].options[0];
    if(this.txtSize.cm_height < this.txtSize.cm_width) {
      var totwidth = 0;
      totwidth = (this.txtSize.cm_width / 2) - 1
      var wdtopt = this.subTypeDetail.configs[0].width.options;
      if (totwidth > wdtopt.length) {
        this.sizeWidth = this.subTypeDetail.configs[0].width.options;
      } else {
        for(let iw = 0; totwidth < wdtopt.length; iw++) {
          if (iw <= Math.round(totwidth)) {
            this.sizeWidth.push(wdtopt[iw])
          } else {
            break;
          }
        }
      }
    } else {
      var totwidth = 0;
      totwidth = (this.txtSize.cm_width / 2) - 1
      var wdtopt = this.subTypeDetail.configs[0].width.options; 
      if (totwidth > wdtopt.length) {
        this.sizeWidth = this.subTypeDetail.configs[0].width.options;
      } else {
        for(let iw = 0; totwidth < wdtopt.length; iw++) {
          if (iw <= Math.round(totwidth)) {
            this.sizeWidth.push(wdtopt[iw])
          } else {
            break;
          }
        }
      }
    }
    this.chkSizelabel = this.subTypeDetail.configs[0].size[0].label;
    this.txtShapevalue = this.subTypeDetail.configs[0].size[0].label;
    this.txtShape = this.subTypeDetail.configs[0].size[0].shapeoption;
    this.txtProfile = this.subTypeDetail.configs[0].profile.options[0]
    this.txtMat = this.subTypeDetail.configs[0].mat.options[0];
    this.txtWidth = this.subTypeDetail.configs[0].width.options[0];
    this.txtLamination = this.subTypeDetail.configs[0].lamination.options[0];
    this.txtGlass = this.subTypeDetail.configs[0].glass.options[0];
    if (this.subTypeDetail.configs[0].profile.options[0] != null) {
      this.framTypeColors = this.subTypeDetail.configs[0].profile.options[0].colors;
      this.txtFramColor = this.subTypeDetail.configs[0].profile.options[0].colors[0];
      this.thumbStaticImages = this.subTypeDetail.configs[0].profile.options[0].colors[0].staticImage;
      // console.log(this.framTypeColors);
    }
    if (this.subTypeDetail.configs[0].mount != null) {
      this.txtMount = this.subTypeDetail.configs[0].mount.options[0];
    } else {
      this.txtMount = null;
    }

    if (this.subTypeDetail.configs[0].canvaswrap != null) {
      this.txtCanvasWrap = this.subTypeDetail.configs[0].canvaswrap.options[0];
    } else {
      this.txtCanvasWrap = null;
    }
    if (this.subTypeDetail.configs[0].canvastype != null) {
      this.txtCanvasType = this.subTypeDetail.configs[0].canvastype.options[0];
    } else {
      this.txtCanvasType = null;
    }
    // this.imageCombineCode();
    this.getPrice();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
  }

  globalInchValue = 2;
  baseDeliveryAmt = 0;
  gstAmount = 0;
  gstPercentage = 12;
  baseAmount = 0;
  tempgst = 0;
  getPrice() {
    this.totalAmount = 0;
    this.orderAmount = 0;
    this.adjustmentAmount = 0;
    this.profilePrice = 0;
    this.matPrice = 0;
    this.laminationPrice = 0;
    this.glassPrice = 0;
    this.mountPrice = 0;
    this.canvasWrapPrice = 0;
    this.canvasTypePrice = 0;
    this.widthPrice = 0;
    this.baseDeliveryAmt = 0;
    this.gstAmount = 0;
    this.baseAmount = 0;
    this.shippingCharges = 0

    if(this.txtSize != null) {
      var tempaccessoriCost = Math.round(this.txtSize.accessoriCost);
      var tempPrintCost = Math.round(parseFloat(Number(this.txtSize.printCost * this.txtSize.cm_width * this.txtSize.cm_height).toFixed(2)));
      var tempPackagingCost = Math.round(parseFloat(Number(this.txtSize.packagingCost * this.txtSize.cm_width * this.txtSize.cm_height).toFixed(2)));
      var tempLaborCost = Math.round(parseFloat(Number(this.txtSize.laborCost * this.txtSize.cm_width * this.txtSize.cm_height).toFixed(2)));
      this.totalAmount = Math.round(parseFloat(Number(this.totalAmount + tempaccessoriCost + tempPrintCost + tempPackagingCost + tempLaborCost).toFixed(2)));
      this.orderAmount = this.totalAmount;
      this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
      this.orderAmount = Math.round(this.totalAmount +this.gstAmount);
    }

    if (this.txtProfile != null) {
      var temframPrice = 0;
      for (let pro of this.txtProfile.rate) {
        if (this.txtSize.sizeId == pro.sizeId) {
          temframPrice = Math.round((this.txtSize.cm_width *this.globalInchValue) + (this.txtSize.cm_height * this.globalInchValue));
          this.profilePrice = Math.round(temframPrice * pro.price);
          this.totalAmount = Math.round(this.totalAmount + this.profilePrice);
          this.orderAmount = this.totalAmount;
          this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
          this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
          break;
        }
      }
    }
    if (this.txtFramColor != null) {
      var temcolorprice = 0;
      for (let fcol of this.txtFramColor.rate) {
        if (this.txtSize.sizeId == fcol.sizeId) {
          temcolorprice = Math.round((this.txtSize.cm_width *this.globalInchValue) + (this.txtSize.cm_height * this.globalInchValue));
          this.colorPrice = Math.round(temcolorprice * fcol.price);
          this.totalAmount = Math.round(this.totalAmount + this.colorPrice);
          this.orderAmount = this.totalAmount;
          this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100)) * 100).toFixed(2)));
          this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
          break;
        }
      }
    }
    if (this.subTypeDetail.typeid == 1) {
      if (this.txtMat != null) {
        for (let mat of this.txtMat.rate) {
          if (this.txtSize.sizeId == mat.sizeId) {
            this.matPrice = Math.round(mat.price * this.txtSize.cm_width * this.txtSize.cm_height);
            this.totalAmount = Math.round(this.totalAmount + this.matPrice);
            this.orderAmount = this.totalAmount;
            this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
            this.orderAmount = Math.round(this.orderAmount + this.gstAmount)
            break;
          }
        }
      }
      if (this.txtLamination != null) {
        for (let lmi of this.txtLamination.rate) {
          if (this.txtSize.sizeId == lmi.sizeId) {
            this.laminationPrice = Math.round(lmi.price *this.txtSize.cm_width * this.txtSize.cm_height);
            this.totalAmount = Math.round(this.totalAmount + this.laminationPrice);
            this.orderAmount = this.totalAmount;
            this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
            this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
            break;
          }
        }
      }
      if (this.txtGlass != null) {
        for (let gls of this.txtGlass.rate) {
          if (this.txtSize.sizeId == gls.sizeId) {
            this.glassPrice = Math.round(gls.price * this.txtSize.cm_width * this.txtSize.cm_height);
            this.totalAmount = Math.round(this.totalAmount + this.glassPrice);
            this.orderAmount = this.totalAmount;
            this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
            this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
            break;
          }
        }
      }
      if (this.txtMount != null) {
        for (let mnt of this.txtMount.rate) {
          if (this.txtSize.sizeId == mnt.sizeId) {
            this.mountPrice = Math.round(mnt.price);
            this.totalAmount = Math.round(this.totalAmount + mnt.price);
            this.orderAmount = this.totalAmount;
            this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
            this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
            break;
          }
        }
      }
      if (this.txtWidth != null) {
        for (let widt of this.txtWidth.rate) {
          if (this.txtSize.sizeId == widt.sizeId) {
            this.widthPrice = Math.round(widt.price);
            this.totalAmount = Math.round(this.totalAmount + widt.price);
            this.orderAmount = this.totalAmount;
            this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
            this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
            break;
          }
        }
      }
    } else {
      for (let cvswrp of this.txtCanvasWrap.rate) {
        if (this.txtCanvasWrap != null) {
          if (this.txtSize.sizeId == cvswrp.sizeId) {
            this.canvasWrapPrice = Math.round(cvswrp.price * this.txtSize.cm_width * this.txtSize.cm_height);
            this.totalAmount = Math.round(this.totalAmount + this.canvasWrapPrice);
            this.orderAmount = this.totalAmount;
            this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
            this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
            break;
          }
        }
      }
      if (this.txtCanvasType != null) {
        for (let cantype of this.txtCanvasType.rate) {
          if (this.txtSize.sizeId == cantype.sizeId) {
            this.canvasTypePrice = Math.round(cantype.price * this.txtSize.cm_width * this.txtSize.cm_height);
            this.totalAmount = Math.round(this.totalAmount + this.canvasTypePrice);
            this.orderAmount = this.totalAmount;
            this.gstAmount =  Math.round(parseFloat(Number(this.totalAmount - (this.totalAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
            this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
            break;
          }
        }
      }
    }
    this.tempgst = Math.round(parseFloat(Number(this.orderAmount - (this.orderAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
    this.baseAmount = Math.round(this.totalAmount);
    // console.log("Base Amount : "+ this.baseAmount);
    this.adjustMentAmountCal();
  }

  adjustMentAmountCal(){
    // console.log("Total : "+this.totalAmount);
    // console.log("Order : "+this.orderAmount);
    this.adjustmentAmount = 0;
    if(this.adjustmentPersontage != null){
      this.adjustmentAmount = ((this.baseAmount) * this.adjustmentPersontage / 100); 
      this.totalAmount = Math.round(this.totalAmount +this.adjustmentAmount + this.designCharge);
      this.orderAmount = this.totalAmount;
      // console.log("Before gst Order : "+this.orderAmount);
      // this.gstAmount =  Math.round(parseFloat(Number(this.orderAmount - (this.orderAmount / (this.gstPercentage + 100) * 100)).toFixed(2)));
      // ============final gst calculation=================== //
      this.gstAmount =  Math.round(parseFloat(Number( (this.orderAmount * (this.gstPercentage ) /100)).toFixed(2)));
      this.orderAmount = Math.round(this.orderAmount + this.gstAmount);
      // console.log("Adjust : "+this.adjustmentAmount);
      // console.log("design : "+this.designCharge);
      // console.log("Gst : "+this.gstAmount);
      // console.log("Order : "+this.orderAmount);
    }
  }

  imagePreview;
  selectedUserImgSize = 0;
  onImageUpload(event) {
    this.selectedUserImage = event.target.files[0];
    var fileSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    var fSize = this.selectedUserImage.size; let i=0;while(fSize>900){fSize/=1024;i++;}
    this.selectedUserImgSize = Math.round(fSize*100)/100;
    // alert((Math.round(fSize*100)/100)+' '+fileSExt[i]);

    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
      // this.imageCombineCode();
      this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    };
    reader.readAsDataURL(this.selectedUserImage);
  }

  drowFrame(width, height, bg, repeatImageId){
    let img = new Image();
    img.addEventListener('load', () => this.createFrame(width, height, bg, img) , false);
    img.src = repeatImageId;
  }
  createFrame(width, height, bg, img) {

    let canvas: HTMLCanvasElement = this.canvas.nativeElement;;
    const ctx = canvas.getContext("2d");
    canvas.height = height;
    canvas.width = width
    //reset 
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // let img = document.getElementById(repeatImageId)as HTMLImageElement
    
    const repeatImageHeight = img.height;
    const pattern = ctx.createPattern(img, "repeat");

    ctx.fillStyle = pattern;
    ctx.lineWidth = 0.7; // edge line width

    //Top
    this.drawLine(ctx, width, height, repeatImageHeight, bg);

    //Right
    ctx.translate(width, 0);
    ctx.rotate((Math.PI / 180) * 90);
    this.drawLine(ctx, height, width, repeatImageHeight, bg);

    //bottom
    ctx.translate(height, 0);
    ctx.rotate((Math.PI / 180) * 90);
    this.drawLine(ctx, width, height, repeatImageHeight, bg);

    //left
    ctx.translate(width, 0);
    ctx.rotate((Math.PI / 180) * 90);
    this.drawLine(ctx, height, width, repeatImageHeight, bg);
    // this.framImg = canvas.toDataURL('image/jpeg');
    this.framImg = canvas.toDataURL();
    this.imageCombineCode(repeatImageHeight);
  }

  framImg;
  drawLine(ctx, width, height, repeatImageHeight, bg) {
    // console.log('width= ' + width);
    ctx.save();
    ctx.beginPath();//define the polygon
    ctx.moveTo(0, 0);
    ctx.lineTo(width, 0);
    ctx.lineTo((width - repeatImageHeight), repeatImageHeight);
    ctx.lineTo(repeatImageHeight, repeatImageHeight);
    ctx.closePath();
    ctx.clip();//draw the image
    ctx.fill();
    ctx.restore();
    // Border/ Corner color behind cross edge merge
    ctx.strokeStyle = bg;
    ctx.stroke();
  }
  
  thumbArray = [];
  iveryColor = '#FFFFF0';
  imageCombineCode(repeatImageHeight) {
    this.thumbArray = [];
    let canvas: HTMLCanvasElement = this.canvas.nativeElement;
    let canvasm1: HTMLCanvasElement = this.canvasm1.nativeElement;
    let context = canvas.getContext('2d');
    let contextm1 = canvasm1.getContext('2d');

    let img1 = new Image();
    let img2 = new Image();
    let img3 = new Image();

    // const image = e.target.files[0];

    // const reader = new FileReader();
    // reader.onload = () => {
    // img1.src = this.txtProfile.url;
    img1.src = this.framImg;
    //this.selectedImg = reader.result;
    // };
    // reader.readAsDataURL(image);

    img1.onload = () => {
      canvas.width = img1.width;
      canvasm1.width = img1.width;
      canvas.height = img1.height;
      canvasm1.height = img1.height;
      if (this.subTypeDetail.typeid == 2) {
        if (this.imagePreview == null || this.imagePreview == undefined) {
          context.drawImage(img1, 0, 0);
          contextm1.drawImage(img1, 0, 0);
          const imgUrl = canvas.toDataURL('image/png',0.5);
          const imgUrlm1 = canvasm1.toDataURL();
          this.responseImg = imgUrl;
          this.imageThumbCombine(repeatImageHeight);
          this.imageThumbCombine2(repeatImageHeight);
          this.productImageCode(repeatImageHeight);
        } else {
          img2.src = this.imagePreview;
        }
      } else {
        if (this.txtMat.url == null) {
          this.withoutMatImageCode(repeatImageHeight);
        } else {
          img2.src = this.txtMat.url;
          img3.src = this.imagePreview;
        }
      }
    };
    img2.onload = () => {
      // canvas.width = img2.width;
      // canvas.height = img2.height;
      context.globalAlpha = 1;
      contextm1.globalAlpha = 1;
      context.drawImage(img1, 0, 0);
      contextm1.drawImage(img1, 0, 0);
      if (this.subTypeDetail.typeid == 2) {
        context.globalAlpha = 1;
        contextm1.globalAlpha = 1;
        context.drawImage(img2, repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
        contextm1.drawImage(img2, repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
      } else {
        context.globalAlpha = 0.9;
        contextm1.globalAlpha = 0.9;
        context.drawImage(img2, repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
        contextm1.drawImage(img2, repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
        context.globalAlpha = 1;
        contextm1.globalAlpha = 1;
        // if (this.txtWidth.label == "10''") {
        //   // context.drawImage(img3, 332, 284);
        //   context.drawImage(img3, (canvas.width - img3.width) / 2, (canvas.height - img3.height) / 2);
        //   contextm1.drawImage(img3, (canvas.width - img3.width) / 2, (canvas.height - img3.height) / 2);
        // } else {
          var hRatio = canvas.width / img3.width    ;
          var vRatio = canvas.height / img3.height  ;
          var ratio  = Math.min ( hRatio, vRatio );
          var centerShift_x = ( canvas.width - img3.width*ratio ) / 2;
          var centerShift_y = ( canvas.height - img3.height*ratio ) / 2;
          // this line for resize using ratio of image
          // context.drawImage(img3, 0,0, img3.width, img3.height,centerShift_x,centerShift_y,img3.width*ratio, img3.height*ratio); 
          if (this.txtMat.url != null && this.txtWidth.label == "0''") {
            // selected image border color
            context.strokeStyle = this.iveryColor;
            context.strokeRect(repeatImageHeight - 1, repeatImageHeight - 1, this.txtSize.wpixel-(2*repeatImageHeight) + 2, this.txtSize.hpixel-(2*repeatImageHeight) + 2);
            contextm1.strokeStyle = this.iveryColor;
            contextm1.strokeRect(repeatImageHeight - 1, repeatImageHeight -1, this.txtSize.wpixel-(2*repeatImageHeight) + 2, this.txtSize.hpixel-(2*repeatImageHeight) + 2);            
            context.drawImage(img3,repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
            contextm1.drawImage(img3,repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
          } else {
            // selected image border color
            context.strokeStyle = this.iveryColor;
            context.strokeRect(((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)) -1,((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)) -1, this.txtSize.wpixel-(2*(this.txtSize.mat1inchpixel*this.txtWidth.widthvalue)) +2, this.txtSize.hpixel - (2*(this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)) +2);
            contextm1.strokeStyle = this.iveryColor;
            contextm1.strokeRect(((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)) -1,((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)) -1, this.txtSize.wpixel-(2*(this.txtSize.mat1inchpixel*this.txtWidth.widthvalue)) +2, this.txtSize.hpixel - (2*(this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)) +2);
            context.drawImage(img3,((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)),((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)), this.txtSize.wpixel-(2*(this.txtSize.mat1inchpixel*this.txtWidth.widthvalue)), this.txtSize.hpixel - (2*(this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)));
            contextm1.drawImage(img3,((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)),((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)), this.txtSize.wpixel-(2*(this.txtSize.mat1inchpixel*this.txtWidth.widthvalue)), this.txtSize.hpixel - (2*(this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)));
          }
        // }
      }
      const imgUrl = canvas.toDataURL('image/png',0.5);
      const imgUrlm1 = canvasm1.toDataURL();
      this.responseImg = imgUrl;
      this.imageThumbCombine(repeatImageHeight);
      this.imageThumbCombine2(repeatImageHeight);
      this.productImageCode(repeatImageHeight);
    };

    // img3.onload = () => {
    // context.drawImage(img1, 0, 0);
    // context.drawImage(img2, 80, 100, 845, 800);
    // context.drawImage(img3, (canvas.width-img3.width)/2, (canvas.height-img3.height)/2);

    // const imgUrl = canvas.toDataURL();
    // this.selectedImg = imgUrl;
    // };
  }

  withoutMatImageCode(repeatImageHeight) {
    let canvas: HTMLCanvasElement = this.canvas.nativeElement;
    let canvasm1: HTMLCanvasElement = this.canvasm1.nativeElement;
    let context = canvas.getContext('2d');
    let contextm1 = canvasm1.getContext('2d');

    let img1 = new Image();
    let img3 = new Image();
    // img1.src = this.txtProfile.url;
    // img1.src = this.txtFramColor.url;
    img1.src = this.framImg;
    img1.onload = () => {
      canvas.width = img1.width;
      canvasm1.width = img1.width;
      canvas.height = img1.height;
      canvasm1.height = img1.height;
      if (this.imagePreview == null || this.imagePreview == undefined) {
        context.drawImage(img1, 0, 0);
        contextm1.drawImage(img1, 0, 0);
        const imgUrl = canvas.toDataURL('image/png',0.5);
        const imgUrlm1 = canvasm1.toDataURL();
        this.responseImg = imgUrl;
        this.imageThumbCombine(repeatImageHeight);
        this.imageThumbCombine2(repeatImageHeight);
        this.productImageCode(repeatImageHeight);
      } else {
        img3.src = this.imagePreview;
      }
    };
    img3.onload = () => {
      context.globalAlpha = 1;
      contextm1.globalAlpha = 1;
      context.drawImage(img1, 0, 0);
      contextm1.drawImage(img1, 0, 0);
      context.globalAlpha = 1;
      contextm1.globalAlpha = 1;
      context.strokeStyle = this.iveryColor;
      context.strokeRect(repeatImageHeight -1, repeatImageHeight -1, this.txtSize.wpixel-(2*repeatImageHeight) +2, this.txtSize.hpixel-(2*repeatImageHeight) +2);
      contextm1.strokeStyle = this.iveryColor;
      contextm1.strokeRect(repeatImageHeight -1, repeatImageHeight -1, this.txtSize.wpixel-(2*repeatImageHeight) +2, this.txtSize.hpixel-(2*repeatImageHeight) +2);
      context.drawImage(img3,repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
      contextm1.drawImage(img3,repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
      const imgUrl = canvas.toDataURL('image/png',0.5);
      const imgUrlm1 = canvasm1.toDataURL();
      this.responseImg = imgUrl;
      this.imageThumbCombine(repeatImageHeight);
      this.imageThumbCombine2(repeatImageHeight);
      this.productImageCode(repeatImageHeight);
    };
  }

  manufacturingDays = 0;
  getSelectedTypeData(type, i) {
    this.manufacturingDays = type.manufacturingDays;
    // console.log(type);
    this.isShipping = false;
    this.sizeWidth = [];
    $('.slick-slider').slick('refresh');
    this.selectedTypeIndex = i;
    this.sizeIndex = 0;
    this.profileIndex = 0;
    this.colorIndex = 0;
    this.matIndex = 0;
    this.laminationIndex = 0;
    this.glassIndex = 0;
    this.mountIndex = 0;
    this.subTypeDetail = type;
    if (this.subTypeDetail.configs[0].canvaswrap != null) {
      this.txtCanvasWrap = this.subTypeDetail.configs[0].canvaswrap.options[0];
    }
    if (this.subTypeDetail.configs[0].canvastype != null) {
      this.txtCanvasType = this.subTypeDetail.configs[0].canvastype.options[0];
    }
    this.txtSize = this.subTypeDetail.configs[0].size[0].options[0];
    this.txtShape = this.subTypeDetail.configs[0].size[0].shapeoption;
    this.chkSizelabel = this.subTypeDetail.configs[0].size[0].label;
    this.txtShapevalue = this.subTypeDetail.configs[0].size[0].label;
    this.txtProfile = this.subTypeDetail.configs[0].profile.options[0]
    if (this.subTypeDetail.configs[0].profile.options[0] != null) {
      this.framTypeColors = this.subTypeDetail.configs[0].profile.options[0].colors;
      this.txtFramColor = this.subTypeDetail.configs[0].profile.options[0].colors[0];
      // console.log(this.framTypeColors);
    }
    if (this.subTypeDetail.configs[0].mat != null) {
      this.txtMat = this.subTypeDetail.configs[0].mat.options[0];
    } else {
      this.txtMat = null;
    }
    if (this.subTypeDetail.configs[0].width != null) {
      this.txtWidth = this.subTypeDetail.configs[0].width.options[0];
      if(this.txtSize.cm_height < this.txtSize.cm_width) {
        var totwidth = 0;
        totwidth = (this.txtSize.cm_width / 2) - 1
        var wdtopt = this.subTypeDetail.configs[0].width.options;
        if (totwidth > wdtopt.length) {
          this.sizeWidth = this.subTypeDetail.configs[0].width.options;
        } else {
          for(let iw = 0; totwidth < wdtopt.length; iw++) {
            if (iw <= Math.round(totwidth)) {
              this.sizeWidth.push(wdtopt[iw])
            } else {
              break;
            }
          }
        }
      } else {
        var totwidth = 0;
        totwidth = (this.txtSize.cm_width / 2) - 1
        var wdtopt = this.subTypeDetail.configs[0].width.options; 
        if (totwidth > wdtopt.length) {
          this.sizeWidth = this.subTypeDetail.configs[0].width.options;
        } else {
          for(let iw = 0; totwidth < wdtopt.length; iw++) {
            if (iw <= Math.round(totwidth)) {
              this.sizeWidth.push(wdtopt[iw])
            } else {
              break;
            }
          }
        }
      }
    } else {
      this.txtWidth = null;
    }
    if (this.subTypeDetail.configs[0].lamination != null) {
      this.txtLamination = this.subTypeDetail.configs[0].lamination.options[0];
    } else {
      this.txtLamination = null;
    }
    if (this.subTypeDetail.configs[0].glass != null) {
      this.txtGlass = this.subTypeDetail.configs[0].glass.options[0];
    } else {
      this.txtGlass = null;
    }
    if (this.subTypeDetail.configs[0].mount != null) {
      this.txtMount = this.subTypeDetail.configs[0].mount.options[0];
    } else {
      this.txtMount = null;
    }
    if (this.subTypeDetail.configs[0].canvaswrap != null) {
      this.txtCanvasWrap = this.subTypeDetail.configs[0].canvaswrap.options[0];
    } else {
      this.txtCanvasWrap = null
    }
    if (this.subTypeDetail.configs[0].canvastype != null) {
      this.txtCanvasType = this.subTypeDetail.configs[0].canvastype.options[0];
    } else {
      this.txtCanvasType = null;
    }
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  getselectedFramColors(frmtype, pi) {
    // console.log(frmtype);
    this.isShipping = false;
    this.profileIndex = pi;
    this.framTypeColors = frmtype.colors;
    this.changeImageProfile(frmtype, pi);
    this.getPrice();
  }

  chkSizelabel;
  changeImageSize(size, si,shapelabel) {
    this.sizeWidth = [];
    this.isShipping = false;
    this.sizeIndex = si
    this.chkSizelabel = shapelabel.label;
    this.txtShapevalue = shapelabel.label;
    this.txtSize = size;
    this.txtShape = shapelabel.shapeoption;
    // console.log(this.txtShape);
    $('.slick-slider').slick('refresh');
    // this.imageCombineCode();
    if (this.subTypeDetail.configs[0].width != null) {
        this.txtWidth = this.subTypeDetail.configs[0].width.options[0];
        if(this.txtSize.cm_height < this.txtSize.cm_width) {
          var totwidth = 0;
          totwidth = (this.txtSize.cm_height / 2) - 1
          var wdtopt = this.subTypeDetail.configs[0].width.options;
          if (totwidth > wdtopt.length) {
            this.sizeWidth = this.subTypeDetail.configs[0].width.options;
          } else {
            for(let iw = 0; totwidth < wdtopt.length; iw++) {
              if (iw <= Math.round(totwidth)) {
                this.sizeWidth.push(wdtopt[iw])
              } else {
                break;
              }
            }
          }
        } else {
          var totwidth = 0;
          totwidth = (this.txtSize.cm_width / 2) - 1
          var wdtopt = this.subTypeDetail.configs[0].width.options; 
          if (totwidth > wdtopt.length) {
            this.sizeWidth = this.subTypeDetail.configs[0].width.options;
          } else {
            for(let iw = 0; totwidth < wdtopt.length; iw++) {
              if (iw <= Math.round(totwidth)) {
                this.sizeWidth.push(wdtopt[iw])
              } else {
                break;
              }
            }
          }
        }
      }
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageProfile(framtype, pi) {
    this.isShipping = false;
    this.profileIndex = pi;
    this.colorIndex = 0;
    this.txtProfile = framtype;
    if (framtype.colors != null) {
      this.txtFramColor = framtype.colors[0];
    }
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageFramColor(color, ci) {
    this.isShipping = false;
    this.colorIndex = ci;
    this.txtFramColor = color;
    this.thumbStaticImages = color.staticImage;
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeWidth() {
    this.isShipping = false;
    // console.log(this.txtWidth);
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageMat(mat, mi) {
    this.isShipping = false;
    this.matIndex = mi;
    this.txtMat = mat;
    if (this.txtMat.url == null) {
      this.txtWidth = this.subTypeDetail.configs[0].width.options[0];
    }
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageLamination(lamination, li) {
    this.isShipping = false;
    this.laminationIndex = li;
    this.txtLamination = lamination;
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageGlass(glass, gi) {
    this.isShipping = false;
    this.glassIndex = gi;
    this.txtGlass = glass;
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageMount(mount, mti) {
    this.isShipping = false;
    this.mountIndex = mti;
    this.txtMount = mount;
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageCanvasWrap() {
    this.isShipping = false;
    // console.log(this.txtCanvasWrap);
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  changeImageCanvastype() {
    this.isShipping = false;
    // console.log(this.txtCanvasType);
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  txtShapevalue;
  changeImageShape() {
    this.isShipping = false;
    // console.log(this.txtShape);
    this.txtShapevalue = this.txtShape.label;
    // this.imageCombineCode();
    this.drowFrame(this.txtSize.wpixel,this.txtSize.hpixel,this.txtFramColor.joinColor,this.txtFramColor.sline);
    this.getPrice();
  }

  resLoopImgs = ['https://ssm-image.s3.ap-south-1.amazonaws.com/Portrait_Small_Standard_Standard%20black_img_2021-04-18T00%3A40%3A36.370_2.jpg', 'https://ssm-image.s3.ap-south-1.amazonaws.com/Portrait_Small_Standard_Standard%20black_img_2021-04-18T00%3A40%3A36.370_1.jpg']
  thumbimg1 = 'assets/img/thumb-1.jpg';
  thumbimg2 = 'assets/img/thumb-2-1.jpg';

  imageThumbCombine(repeatImageHeight) {
    let canvas: HTMLCanvasElement = this.canvasthumb.nativeElement;
    let canvas3: HTMLCanvasElement = this.canvasthumb3.nativeElement;
    let context = canvas.getContext('2d');
    let context3 = canvas3.getContext('2d');

    let img1 = new Image();
    let img2 = new Image();

    img1.src = this.thumbimg1;
    img1.onload = () => {
      canvas.width = img1.width;
      canvas3.width = img1.width;
      canvas.height = img1.height;
      canvas3.height = img1.height;
      img2.src = this.responseImg;
    };
    img2.onload = () => {
      var a = img2.offsetWidth;
      var b = img2.offsetHeight;
      // context.globalAlpha = 1;
      context.drawImage(img1, 0, 0);
      context3.drawImage(img1, 0, 0);
      // context.globalAlpha = 0.3;
      // context.drawImage(img2, 290, 60, 845, 800);
      if (this.txtShape.label == "Square") {
        context.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
        context3.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
      } else if (this.txtShape.label == "Vertical") {
        context.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
        context3.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
      } else if(this.txtShape.label == "Horizontal") {
        context.drawImage(img2, 350, 120, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
        context3.drawImage(img2, 350, 120, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
      }
      
      // context.drawImage(img2, (canvas.width - img2.width) / 2, (canvas.height - img2.height) / 2);
      // context.globalAlpha = 1;
      // const imgUrl = canvas.toDataURL();
      // const imgUrl1 = canvas3.toDataURL();
      // this.responseImg = imgUrl;
      // this.thumbArray.push(imgUrl);
      this.imageThumbCombine2(repeatImageHeight);
    };
  }
  imageThumbCombine2(repeatImageHeight) {
    let canvas: HTMLCanvasElement = this.canvasthumb2.nativeElement;
    let canvas4: HTMLCanvasElement = this.canvasthumb4.nativeElement;
    let context = canvas.getContext('2d');
    let context4 = canvas4.getContext('2d');

    let img1 = new Image();
    let img2 = new Image();

    img1.src = this.thumbimg2;
    img1.onload = () => {
      canvas.width = img1.width;
      canvas4.width = img1.width;
      canvas.height = img1.height;
      canvas4.height = img1.height;
      img2.src = this.responseImg;
    };
    img2.onload = () => {
      var a = img2.offsetWidth;
      var b = img2.offsetHeight;
      // context.globalAlpha = 1;
      context.drawImage(img1, 0, 0);
      context4.drawImage(img1, 0, 0);
      // context.globalAlpha = 0.3;
      // context.drawImage(img2, 290, 60, 845, 800);  //old resize 
      // context.drawImage(img2, 290, 60, 415, 560);
      // context4.drawImage(img2, 290, 60, 415, 560);
      if (this.txtShape.label == "Square") {
        context.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
        context4.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
      } else if (this.txtShape.label == "Vertical") {
        context.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
        context4.drawImage(img2, 400, 100, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
      } else if(this.txtShape.label == "Horizontal") {
        context.drawImage(img2, 350, 120, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
        context4.drawImage(img2, 350, 120, this.txtSize.wpixel/2, this.txtSize.hpixel/2);
      }
      // context.drawImage(img2, (canvas.width - img2.width) / 2, (canvas.height - img2.height) / 2);
      // context.globalAlpha = 1;
      // const imgUrl = canvas.toDataURL();
      // const imgUrl4 = canvas4.toDataURL();
      // this.responseImg = imgUrl;
    };
  }

  //=========================Add to Cart Product =================================//

  cartProducts: Array<ProductsCartDetail> = [];
  cartlastid = 0;
  newproductid;
  newproduct;
  // selectedPrice = 100;
  productDiscount = 0;
  finalOrderAmount = 0;
  shippingCharges = 0;
  expectedDeliveryDate = "";
  razorPayOrderId;
  razorpayTransationId;
  razorpaySignature
  addToCart() {
    // console.log(listproduct);
    this.cartProducts = JSON.parse(localStorage.getItem("studio-cartProducts"));
    var tempProducts = this.cartProducts
    if (this.cartProducts == null || this.cartProducts.length == 0 || tempProducts.length == 0) {
      this.cartProducts = [];
      let cartdtl = new ProductsCartDetail();
      if (this.cartProducts.length == 0) {
        cartdtl.id1 = this.cartlastid;
      } else if (this.cartProducts.length != 0) {
        cartdtl.id1 = this.cartlastid + 1;
      }
      this.cartlastid = cartdtl.id1;
      cartdtl.productid = this.subTypeDetail.typeid;
      cartdtl.productName = this.subTypeDetail.typeLabel;
      cartdtl.productQuantity = 1;
      cartdtl.productimage = this.responseImg;
      cartdtl.productPrice = this.orderAmount;
      cartdtl.size = this.txtSize.cm;
      cartdtl.frame = this.txtProfile.label;
      cartdtl.frameColor = this.txtFramColor.label;
      cartdtl.height = this.txtSize.h;
      cartdtl.length = this.txtSize.l;
      cartdtl.breadth = this.txtSize.b;
      cartdtl.weight = this.txtSize.weight;
      if (this.subTypeDetail.typeid == 2) {
        cartdtl.canvasType = this.txtCanvasType.label;
        cartdtl.canvasWrap = this.txtCanvasWrap.label;
        cartdtl.mat = null;
        cartdtl.lamination = null;
        cartdtl.glass = null;
        cartdtl.mount = null;
      } else {
        cartdtl.mat = this.txtMat.label;
        cartdtl.lamination = this.txtLamination.option;
        cartdtl.glass = this.txtGlass.option;
        cartdtl.mount = this.txtMount.option;
        cartdtl.canvasType = null;
        cartdtl.canvasWrap = null;
      }
      cartdtl.totalweight = parseFloat(Number(this.txtSize.weight * Number(1)).toFixed(2));
      cartdtl.subTotal = parseFloat(Number(this.orderAmount * Number(cartdtl.productQuantity)).toFixed(2));
      cartdtl.staticimg = this.responseImg;
      this.cartProducts.push(cartdtl);
      // console.log(this.cartProducts)
      localStorage.setItem("studio-cartProducts", JSON.stringify(this.cartProducts));
      // this.toastr.success("item added into your cart.");
    } else {
      // console.log("Else condition");
      for (let updateprodt of tempProducts) {
        this.newproductid = updateprodt.productid
        if (updateprodt.productid == this.subTypeDetail.typeid) {
          updateprodt.id1 = updateprodt.id1;
          updateprodt.productid = this.subTypeDetail.typeid;
          // updateprodt.productName = this.subTypeDetail.typeLabel;
          updateprodt.productQuantity = 1;
          // updateprodt.productimage = this.responseImg;
          // updateprodt.productPrice = this.selectedPrice;
          // updateprodt.size = this.txtSize.cm;
          // updateprodt.frame = this.txtProfile.label;
          // updateprodt.frameColor = this.txtFramColor.label;
          // updateprodt.height = this.txtSize.h;
          // updateprodt.length = this.txtSize.l;
          // updateprodt.breadth = this.txtSize.b;
          // updateprodt.weight = this.txtSize.weight;
          // if (this.subTypeDetail.typeid == 2) {
          // updateprodt.canvasType = this.txtCanvasType.label;
          // updateprodt.canvasWrap = this.txtCanvasWrap.label;
          // updateprodt.mat = null;
          // updateprodt.lamination = null;
          // updateprodt.glass = null;
          // updateprodt.mount = null;
          // } else {
          // updateprodt.mat = this.txtMat.label;
          // updateprodt.lamination = this.txtLamination.option;
          // updateprodt.glass = this.txtGlass.option;
          // updateprodt.mount = this.txtMount.option;
          // updateprodt.canvasType = null;
          // updateprodt.canvasWrap = null;
          // }
          updateprodt.totalweight = parseFloat(Number(this.txtSize.weight * Number(1)).toFixed(2));
          updateprodt.subTotal = parseFloat(Number(this.orderAmount * Number(updateprodt.productQuantity)).toFixed(2));
          var item = updateprodt
          var index = this.cartProducts.indexOf(updateprodt);
          if (index !== -1) {
            this.cartProducts[index] = updateprodt;
          }
          localStorage.setItem('studio-cartProducts', JSON.stringify(this.cartProducts));
          break;
        } else {
          let cartdtl = new ProductsCartDetail();
          if (this.cartProducts.length == 0) {
            cartdtl.id1 = this.cartlastid;
          } else if (this.cartProducts.length != 0) {
            cartdtl.id1 = this.cartlastid + 1;
          }
          this.cartlastid = cartdtl.id1;
          cartdtl.productid = this.subTypeDetail.typeid;
          cartdtl.productName = this.subTypeDetail.typeLabel;
          cartdtl.productQuantity = 1;
          cartdtl.productimage = this.responseImg;
          cartdtl.productPrice = this.orderAmount;
          cartdtl.size = this.txtSize.cm;
          cartdtl.frame = this.txtProfile.label;
          cartdtl.frameColor = this.txtFramColor.label;
          cartdtl.height = this.txtSize.h;
          cartdtl.length = this.txtSize.l;
          cartdtl.breadth = this.txtSize.b;
          cartdtl.weight = this.txtSize.weight;
          if (this.subTypeDetail.typeid == 2) {
            cartdtl.canvasType = this.txtCanvasType.label;
            cartdtl.canvasWrap = this.txtCanvasWrap.label;
            cartdtl.mat = null;
            cartdtl.lamination = null;
            cartdtl.glass = null;
            cartdtl.mount = null;
          } else {
            cartdtl.mat = this.txtMat.label;
            cartdtl.lamination = this.txtLamination.option;
            cartdtl.glass = this.txtGlass.option;
            cartdtl.mount = this.txtMount.option;
            cartdtl.canvasType = null;
            cartdtl.canvasWrap = null;
          }
          cartdtl.totalweight = parseFloat(Number(this.txtSize.weight * Number(1)).toFixed(2));
          cartdtl.subTotal = parseFloat(Number(this.orderAmount * Number(cartdtl.productQuantity)).toFixed(2));
          cartdtl.staticimg = this.responseImg;
          this.newproduct = cartdtl
        }
      }
      if ((this.newproductid != this.subTypeDetail.productid) && (this.newproduct != null || this.newproduct != undefined)) {
        this.cartProducts.push(this.newproduct);
        localStorage.setItem("studio-cartProducts", JSON.stringify(this.cartProducts));
        // this.toastr.success("item added into your cart.");
      } else {
        // console.log("old product");
      }
    }
  }

  public loading: boolean = false;
  resSavedOrder;
  saveUserOrder() {
    // if (this.subTypeDetail.typeid == 1 && (this.txtMat.label == "None" && this.txtGlass.option != "None")) {
    //   this.toastr.error("please select mat.");
    // } else if (this.subTypeDetail.typeid == 1 && (this.txtMat.label != "None" && this.txtGlass.option == "None")) {
    //   this.toastr.error("please select glass.");
    if (this.selectedUserImage == null || this.selectedUserImage == '' || this.selectedUserImage == undefined) {
      this.toastr.error("Please upload image.");
    } else if (this.selectedUserImgSize == 0 && this.selectedUserImgSize > 50) {
      this.toastr.error("Image size must be less than 50 MB");
    } else if (this.txtLamination.option == "None" && this.txtMat.label == "None") {
      this.toastr.error("Please select mount.");
    } else if (this.txtLamination.option == "None" && this.txtGlass.option == "None") {
      this.toastr.error("Please select glass.");
    } else if (this.txtMat.label != "None" && this.txtGlass.option == "None") {
      this.toastr.error("Please select glass.");
    } else {
      this.loading = true;
      // this.productImageCode();
      if(this.txtShipMobile == undefined || this.txtShipMobile == null || this.txtShipMobile == "") {
        this.txtShipMobile = this.tempDlrMobile;
      }
      var saveData = new OrderDetailDto();
      saveData.productid = this.subTypeDetail.typeid;
      saveData.productName = this.subTypeDetail.typeLabel;
      saveData.productqty = 1;
      saveData.productImage = this.responseImg;
      // saveData.mainImage = this.imagePreview;
      saveData.mainImage = this.combineProductImage;
      saveData.size = this.txtSize;
      saveData.shape = this.txtShape;
      saveData.frame = this.txtProfile;
      saveData.frameColor = this.txtFramColor;
      saveData.canvasType = this.txtCanvasType;
      saveData.canvasWrap = this.txtCanvasWrap;
      saveData.mat = this.txtMat;
      saveData.width = this.txtWidth;
      saveData.lamination = this.txtLamination;
      saveData.glass = this.txtGlass;
      saveData.mount = this.txtMount;
      // console.log(saveData);
      this.baseDeliveryAmt = Math.round(parseFloat(Number(this.shippingCharges * 100 / (100 + this.gstPercentage)).toFixed(2)));
      var strJson = JSON.stringify(saveData);
      this.finalOrderAmount = Math.round(this.orderAmount + this.shippingCharges);
      this.checkoutService.createUserOrder(this.dealerId, strJson, this.orderAmount, this.finalOrderAmount, this.productDiscount, this.shippingCharges, this.expectedDeliveryDate, this.razorPayOrderId, this.razorpayTransationId, this.razorpaySignature,
        this.txtCustomerName, this.txtShipEmail, this.txtShipMobile, this.txtShipAddress1, this.txtShipAddress2, this.txtShipCountry, this.txtShipState, this.txtShipCity, this.txtShipPincode.toString(),this.baseDeliveryAmt,this.gstAmount,this.gstPercentage,this.adjustmentAmount,this.baseAmount).subscribe(res => {
          // console.log(res);
          this.resSavedOrder = res;
          this.uploadImage();
        }, error => {
          this.hideloaderOrder();
          this.loading = false;
          this.toastr.error("Order not saved")
        });
    }
  }

  //===================Calculate Shipping Charges=====================//
  existingShippincode;
  // finalBillAmount = 0;
  isShipping = false;
  txtCustomerName
  txtShipMobile;
  txtShipEmail;
  txtShipAddress1;
  txtShipAddress2;
  txtShipCountry;
  txtShipState;
  txtShipCity;
  txtShipPincode;
  isShippingAddress = false;

  openAddressModal() {
    if (!$("#dlrAddress").prop("checked") && !$("#custAddress").prop("checked")) {
      this.toastr.error("please select address option");
    } else {
      $('#shipping_address').modal('show');
    }
  }

  getShippingCharges() {
    this.shippingCharges = 0;
    this.totalAmount = this.orderAmount;
    this.existingShippincode = this.txtShipPincode;
    this.checkoutService.getShippingCharges(this.existingShippincode, this.txtSize.weight).subscribe(res => {
      // console.log(res);
      this.isShipping = true;
      this.shippingCharges = Math.round(res.rate);
      var ltime = new Date(res.etd).getTime() + this.manufacturingDays * 24 * 60 * 60 * 1000;
      this.expectedDeliveryDate = formatDate(new Date(ltime), "dd-MMM-yyyy", 'en-US')
      this.totalAmount = Math.round(this.totalAmount + this.shippingCharges);
      $('#shipping_address').modal('hide');
      // this.isMakePaymentBtn = true;
    }, error => {
      this.isShipping = false;
      this.totalAmount = Math.round(this.totalAmount + this.shippingCharges);
      // this.isMakePaymentBtn = false;
      this.expectedDeliveryDate = "";
      // let message = JSON.parse(error._body).message;
      // this.toastr.error(JSON.stringify(message));
    })
  }

  checkDeliveryPincode() {
    var cust = this.txtCustomerName;
    var add1 = this.txtShipAddress1;
    if (this.txtCustomerName == "" || this.txtCustomerName == undefined || this.txtCustomerName == null || (this.txtCustomerName = cust.trim()) == "") {
      this.toastr.error("customer name is required");
    } else if (this.selectedRadio == "Customer" && (this.txtShipMobile == "" || this.txtShipMobile == null || this.txtShipMobile == undefined)) {
      this.toastr.error("mobile number is required");
    } else if (this.selectedRadio == "Customer" && (this.txtShipMobile.length < 10 || this.txtShipMobile.length > 10)) {
      this.toastr.error("mobile number must be 10 digits");
    } else if (this.selectedRadio == "Dealer" && (this.txtShipMobile != null && this.txtShipMobile != "") && (this.txtShipMobile.length < 10 || this.txtShipMobile.length > 10)) {
      this.toastr.error("mobile number must be 10 digits");
    } else if (this.txtShipAddress1 == null || this.txtShipAddress1 == '' || this.txtShipAddress1 == undefined || (this.txtShipAddress1 = add1.trim()) == "") {
      this.toastr.error("address1 is required");
    } else if (this.txtShipPincode == null || this.txtShipPincode == '' || this.txtShipPincode == undefined) {
      this.toastr.error("pincode is required");
    } else if (this.txtShipPincode.length < 6 || this.txtShipPincode.length > 6) {
      this.toastr.error("pincode must be 6 digits.")
    } else {
      this.getShippingCharges();
    }
  }

  clearShipData() {
    // const cb = document.getElementById('chngShipAddress');
    // var element = cb as HTMLInputElement;
    // element.checked = false;
    this.isShippingAddress = false;
    this.txtCustomerName = null;
    this.txtShipMobile = null;
    if (this.selectedRadio == "Dealer") {
      // this.txtShipAddress1 = this.userDetail.address;
      this.txtShipCity = this.userDetail.city;
      this.txtShipCountry = this.userDetail.country;
      this.txtShipState = this.userDetail.state;
      this.txtShipPincode = this.userDetail.pincode;
      this.existingShippincode = this.userDetail.pincode;
    } else {
      this.txtShipEmail = null;
      this.txtShipAddress1 = null;
      this.txtShipAddress2 = null;
      this.txtShipCountry = null;
      this.txtShipState = null;
      this.txtShipCity = null;
      this.txtShipPincode = null;
    }
  }

  // changeShippingAddress() {
  //   const cb = document.getElementById('chngShipAddress');
  //   var element = cb as HTMLInputElement;
  //   if (element.checked == true) {
  //     this.isShippingAddress = true;
  //     this.txtShipCity = this.userDetail.city;
  //     this.txtShipCountry = this.userDetail.country;
  //     this.txtShipState = this.userDetail.state;
  //     this.txtShipPincode = this.userDetail.pincode;
  //   } else {
  //     this.isShippingAddress = false;
  //   }
  // }

  selectedRadio;
  onItemChange(value) {
    this.selectedRadio = value;
    // console.log(value);
    if (value == "Dealer") {
      this.txtShipAddress1 = this.userDetail.address;
      this.txtShipCity = this.userDetail.city;
      this.txtShipCountry = this.userDetail.country;
      this.txtShipState = this.userDetail.state;
      this.txtShipPincode = this.userDetail.pincode;
      this.existingShippincode = this.userDetail.pincode;
      this.isShipping = false;
      this.totalAmount = this.orderAmount;
    } else if (value == "Customer") {
      this.txtShipAddress1 = null;
      this.txtShipAddress2 = null;
      this.txtShipEmail = null;
      this.txtShipCity = null;
      this.txtShipCountry = null;
      this.txtShipState = null;
      this.txtShipPincode = null;
      this.existingShippincode = null;
      this.isShipping = false;
      this.totalAmount = this.orderAmount;
    } else {
      this.txtShipAddress1 = null;
      this.txtShipAddress2 = null;
      this.txtShipEmail = null;
      this.txtShipCity = null;
      this.txtShipCountry = null;
      this.txtShipState = null;
      this.txtShipPincode = null;
      this.existingShippincode = null;
      this.isShipping = false;
      this.totalAmount = this.orderAmount;
    }
  }

  combineProductImage;
  productImageCode(repeatImageHeight) {
    let canvas: HTMLCanvasElement = this.productComImg.nativeElement;
    let context = canvas.getContext('2d');

    let img1 = new Image();
    let img2 = new Image();
    img1.src = "assets/img/m13.jpg";
    img1.onload = () => {
      canvas.width = img1.width;
      canvas.height = img1.height;
      if (this.subTypeDetail.typeid == 2) {
        if (this.imagePreview == null || this.imagePreview == undefined) {
          context.drawImage(img1, 0, 0);
          const imgUrl = canvas.toDataURL();
          // this.responseImg = imgUrl;
        } else {
          img2.src = this.imagePreview;
        }
      } else {
        // if (this.txtMat.url == null) {
        //   context.drawImage(img1, 0, 0);
        // } else {
          img2.src = this.imagePreview;
        // }
      }
    };
    img2.onload = () => {
      // context.globalAlpha = 0.5;
      context.drawImage(img1, repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
      if (this.subTypeDetail.typeid == 2) {
        // context.globalAlpha = 1;
        context.drawImage(img2, repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
      } else {
        // context.globalAlpha = 1;
        if ( this.txtMat.url == null || this.txtMat.url != null && this.txtWidth.label == "0''") {
          context.drawImage(img2,repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
        } else {
          context.drawImage(img2,((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)),((this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)), this.txtSize.wpixel-(2*(this.txtSize.mat1inchpixel*this.txtWidth.widthvalue)), this.txtSize.hpixel - (2*(this.txtWidth.widthvalue * this.txtSize.mat1inchpixel)));
        }
      }
      context.strokeStyle = '#231f20';
      // context.lineWidth = 2;
      context.strokeRect(repeatImageHeight, repeatImageHeight, this.txtSize.wpixel-(2*repeatImageHeight), this.txtSize.hpixel-(2*repeatImageHeight));
      const imgUrl = canvas.toDataURL();
      // this.responseImg = imgUrl;
      this.combineProductImage = imgUrl;
    };
  }

  //==========================save frame image==========================//
  uploadImage() {
    if (this.selectedUserImage == null || this.selectedUserImage == '' || this.selectedUserImage == undefined) {
      // this.toastr.success("Order saved successfully")
      // this.shippingCharges = 0;
      // this.finalOrderAmount = 0;
      // this.productDiscount = 0;
      // this.orderAmount = 0;
      // this.dealerId = null;
      // this.isShipping = false;
      // this.getinitValue();
      // this.loading = false;
      // this.hideloaderOrder();
      // window.location.reload();
      this.uploadThumbImage();
      return;
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedUserImage, this.selectedUserImage.name);
      data1.append('ordid', this.resSavedOrder.orderDetailsId);
      this.checkoutService.uploadImage(data1).subscribe(res => {
        // this.resImage = res;
        // this.toastr.success("Order saved successfully")
        // if (res) {
        //   this.hideloaderOrder();
        // }
        // this.shippingCharges = 0;
        // this.finalOrderAmount = 0;
        // this.productDiscount = 0;
        // this.orderAmount = 0;
        // this.dealerId = null;
        // this.isShipping = false;
        // this.getinitValue();
        // window.location.reload();
        // this.imagePreview = "";
        // this.loading = false;
        this.uploadThumbImage();
      }, error => {
        this.uploadThumbImage();
        // this.loading = false;
        // this.imagePreview = "";
        // this.toastr.error("Order not saved")
      })
    }
  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  }
  uploadThumbImage() {
    var blobData = this.dataURItoBlob(this.responseImg);
    const data1 = new FormData();
    data1.append('file', blobData, "thumb.png");
    data1.append('ordid', this.resSavedOrder.orderDetailsId);
    this.checkoutService.uploadThumbImage(data1).subscribe(res => {      
      this.toastr.success("Order saved successfully")
      if (res) {
        this.hideloaderOrder();
      }
      this.shippingCharges = 0;
      this.finalOrderAmount = 0;
      this.productDiscount = 0;
      this.orderAmount = 0;
      this.dealerId = null;
      this.isShipping = false;
      this.selectedUserImgSize = 0;
      this.getinitValue();
      window.location.reload();
      this.imagePreview = "";
      this.loading = false;
    }, error => {
      this.loading = false;
      // this.imagePreview = "";
      // this.toastr.error("Order not saved")
    })
  }
}
