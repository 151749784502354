<footer id="footer">
    <div class="studio-footer">
      <div class="wrapper">
        <p class="">© Copyright Tarapara - {{currentYear}}
            <span style="float: right;">Address : {{dealerAddress}},{{dealerCity}}-{{dealerPincode}}</span>
        </p>
      </div>
    </div>
</footer>
<!-- #footer -->
