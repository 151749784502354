import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: Http, private httpClient: HttpClient) { }

  createShippingOrder(txtorderid: string, orderdate: string, billing_customer_name: string, billing_address: string, billing_city: string, billing_pincode: number, billing_state: string, billing_country: string, billing_email: string, billing_phone: string,
    shipping_is_billing: boolean, shipping_customer_name: string, shipping_address: string, shipping_city: string, shipping_pincode: number, shipping_country: string, shipping_state: string, shipping_email: string, shipping_phone: string, orderitems: any,
    totalbill: number, totalWeight: number, maxlength: string, maxheight: string, maxbreadth: string, razorpayorderid: string, razorpaytransationid: string, razorpaysignature: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      order_id: txtorderid,
      order_date: orderdate,

      billing_customer_name: billing_customer_name,
      billing_address: billing_address,
      billing_city: billing_city,
      billing_pincode: billing_pincode,
      billing_state: billing_state,
      billing_country: billing_country,
      billing_email: billing_email,
      billing_phone: billing_phone,

      shipping_is_billing: shipping_is_billing,
      shipping_customer_name: shipping_customer_name,
      shipping_address: shipping_address,
      shipping_city: shipping_city,
      shipping_pincode: shipping_pincode,
      shipping_country: shipping_country,
      shipping_state: shipping_state,
      shipping_email: shipping_email,
      shipping_phone: shipping_phone,

      sub_total: totalbill,
      weight: totalWeight,
      length: maxlength,
      breadth: maxbreadth,
      height: maxheight,
      shippingOrderDetailDTO: orderitems,

      razorpayorderid: razorpayorderid,
      razortransactionid: razorpaytransationid,
      razorpaysignature: razorpaysignature
    });
    return this.http.post('/api/create/ship/order', body, { headers })
      .pipe(map(res => res.json()));
  }

  getShippingCharges(txtdeliverypicode: string, weight: number) {
    const headers = new Headers();
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      delivery_postcode: txtdeliverypicode,
      weight: weight,
    });
    return this.http.post('/api/ship/login', body, { headers })
      .pipe(map(res => res.json()));
  }

  createDealerRazorPayOrder(txtuserMobile: string, txtTotalBill: number, currency: string, txtorderid: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('studio-token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      userMobile: txtuserMobile,
      amount: txtTotalBill,
      currency: currency,
      orderNumber: txtorderid
    });

    return this.http.post('/api/razorpay/create/dealer', body, { headers })
      .pipe(map(res => res.json()));
  }

  createOrderRazorPayOrder(listOfId: any, txtTotalBill: number, currency: string, txtorderid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      orderDetailsIdList: listOfId,
      amount: txtTotalBill,
      currency: currency,
      orderNumber: txtorderid
    });

    return this.http.post('/api/razorpay/create/order', body, { headers })
      .pipe(map(res => res.json()));
  }

  createUserOrder(dealerId: string, orderJson: any, orderAmount: number, finalOrderAmount: number, offer: number, deliverycharge: number, deliveryDate: string, razorPayOrderId: string, razorpayTransationId: string, razorpaySignature: string,
    customerName:string,shipEmail:string,shipMobile:string,shipAddress1:string,shipAddress2:string,shipcountry:string,shipState:string,shipCity:string,shipPincode:string,deliveryGst:number,gstAmount:number,dlrGstRate : number,adjustmentamount : number,baseAmount: number) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      dealerId: dealerId,
      orderJson: orderJson,
      orderAmount: orderAmount,
      totalAmount: finalOrderAmount,
      finalOrderAmount: finalOrderAmount,
      offer: offer,
      deliveryCharges: deliverycharge,
      deliveryDate: deliveryDate,
      razorPayOrderId: razorPayOrderId,
      razorPayPaymentId: razorpayTransationId,
      razorPaySignature: razorpaySignature,
      customerName:customerName,
      shippingEmail:shipEmail,
      shippingMobile:shipMobile,
      shippingAddress1:shipAddress1,
      shippingAddress2:shipAddress2,
      shippingCountry:shipcountry,
      shippingState:shipState,
      shippingCity:shipCity,
      shippingPincode:shipPincode,
      deliveryGst:deliveryGst,
      gstAmount:gstAmount,
      dlrGst : dlrGstRate,
      adjustmentAmount : adjustmentamount,
      baseAmount : baseAmount,
    });

    return this.http.post('/api/orderDetails', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateRazorPaySuccesOrder(razorPayOrderId: string, razorpayTransationId: string, razorpaySignature: string, txtstatus: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('studio-token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    const body = JSON.stringify({
      razorPayOrderId: razorPayOrderId,
      razorPayPaymentId: razorpayTransationId,
      razorPaySignature: razorpaySignature,
      status: txtstatus
    });

    return this.http.post('/api/razorpay/success', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateSelectedOrderPayment(listofPaymentId: any,razorPayOrderId: string, razorpayTransationId: string, razorpaySignature: string, txtstatus: string) {
    const headers = new Headers();
    // var token = localStorage.getItem('studio-token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    const body = JSON.stringify({
      orderDetailsIdList: listofPaymentId,
      razorPayOrderId: razorPayOrderId,
      razorPayPaymentId: razorpayTransationId,
      razorPaySignature: razorpaySignature,
      status: txtstatus
    });

    return this.http.post('/api/razorpay/success', body, { headers })
      .pipe(map(res => res.json()));
  }

  getallDraft(DRAFT: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/orderDetailsByStatus/' + DRAFT, { headers })
      .pipe(map(res => res.json()));
  }

  orderDetailByUserStatus(orderstatus: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/orderdetailsbyuser/' + orderstatus, { headers })
      .pipe(map(res => res.json()));
  }
  
  orderHistoryByUserStatus(orderstatus: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/get/orderhistorybyuser/' + orderstatus, { headers })
      .pipe(map(res => res.json()));
  }

  orderHistoryByUserStatusDTOs(orderstatus: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/get/orderhistorybyuser/dtos/' + orderstatus, { headers })
      .pipe(map(res => res.json()));
  }

  getOneorderHistoryById(id: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/getone/orderDetails/' + id, { headers })
      .pipe(map(res => res.json()));
  }

  getallOrderHistory(dealerId: string) {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/orderDetailsByDealer/' + dealerId, { headers })
      .pipe(map(res => res.json()));
  }

  getOrderByAllStatus() {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/orderDetailsByAllStatus' , { headers })
      .pipe(map(res => res.json()));
  }
  
  getOrderHistoryByAllStatus() {
    const headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/orderHistoryByAllStatus' , { headers })
      .pipe(map(res => res.json()));
  }

  public downloadOrderBill(billid: string): Observable<Blob> {
    var token = localStorage.getItem('studio-token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/order/invoice/` + billid, { headers: header, responseType: 'blob' });
  }

  getallSaleSPerson() {
    const headers = new Headers();
    // var token : any= localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/salesPersons', { headers })
      .pipe(map(res => res.json()));
  }
  
  getallActiveSalePerson() {
    const headers = new Headers();
    // var token : any= localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/act/salesPersons', { headers })
      .pipe(map(res => res.json()));
  }
  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/originalimage', data, { headers: headers })
      .pipe(map(res => res));
  }
  uploadThumbImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('studio-token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/thumbimage', data, { headers: headers })
      .pipe(map(res => res));
  }

  deleteOrderDetail(deleteid: string) {
    var headers = new Headers();
    var token = localStorage.getItem("studio-token");
    headers.append('Authorization',token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      orderDetailsId: deleteid,
    });
    return this.http.put('/api/delete/orderdetail', body, { headers: headers})
      .pipe(map(res=>res.json()))
  }

  saveCharges(delaerid: string,designCharge:string,AdjustmentPersontage :string) {
    var headers = new Headers();
    var token = localStorage.getItem("studio-token");
    headers.append('Authorization',token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      dealerId: delaerid,
      designCharges: designCharge,
      adjustmentPersontage : AdjustmentPersontage,
    });
    return this.http.put('/api/dealer/update/charges', body, { headers: headers})
      .pipe(map(res=>res.json()))
  }
}
