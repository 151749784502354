<div class="main_component detail_pages">
<app-sub-header></app-sub-header>
<!-- /header -->
<div class="overlay" [ngClass]="loading == true ?'active':''">
  <div class="loader"></div>
</div>
<div id="main">
    <!-- <div class="breadcrumb">
      <div class="wrapper">
        <div class="breadcrumb_inner">
          <ul class="d-flex flex-row flex-wrap justify-content-start align-items-center">
            <li><a href="#">home</a></li>
            <li><span>Detail</span></li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="wrapper">
      <section class="detail_block">
        <div class="detail_inner d-flex flex-row flex-wrap justify-content-start align-items-start">
          <div class="detail_left">
            <canvas #productComImg class="img-fluid" style="display: none;" width="800" height="800"></canvas>
            <div class="detail_slider" [ngClass]="txtShapevalue == 'Horizontal' ? 'imghorizonal' : txtShapevalue == 'Square' ? 'imgsqure' :'' ">
              <div class="item">
                <figure>
                  <!-- <canvas #canvas class="img-fluid" [ngClass]="txtShapevalue == 'Horizontal' ? 'imageHorizontal' : txtShapevalue == 'Vertical' ? 'imageVertical' :'' "></canvas> -->
                  <canvas #canvas class="img-fluid" width="800" height="800"></canvas>
                  <!-- <img [src]="responseImg" /> -->
                </figure>
              </div>
              <div class="item">
                <figure>
                  <!-- <canvas #canvasthumb3 class="img-fluid" [ngClass]="txtShapevalue == 'Horizontal' ? 'imageHorizontal' : txtShapevalue == 'Vertical' ? 'imageVertical' :'' "></canvas> -->
                  <canvas #canvasthumb3 class="img-fluid"></canvas>
                  <!-- <img [src]="responseImg" /> -->
                </figure>
              </div>
              <div class="item">
                <figure>
                  <!-- <canvas #canvasthumb4 class="img-fluid" [ngClass]="txtShapevalue == 'Horizontal' ? 'imageHorizontal' : txtShapevalue == 'Vertical' ? 'imageVertical' :'' "></canvas> -->
                  <canvas #canvasthumb4 class="img-fluid" ></canvas>
                  <!-- <img [src]="responseImg" /> -->
                </figure>
              </div>
              <div class="item">
                <figure>
                  <img class="img-fluid" [src]="thumbStaticImages" />
                </figure>
              </div>
            </div>
            <div class="detail_thumb_block">
              <div class="detail_upload_btn text-center">
                <input class="form-control" type="file" #myInput1 name="txtImage" (change)="onImageUpload($event)"
                class="custom-file-input" id="customFile2" accept="image/*" style="opacity: 0;visibility: hidden;display: none;">
                <label class="custom-file-label" style="opacity: 0;visibility: hidden;display: none;" for="customFile2">Image</label>
                <a role="button" class="btn_fill" (click)="myInput1.click()">Upload image</a>
              </div>
              <div class="detail_thumb_slider">
                <div class="item">
                  <figure class="thumb-back-color">
                    <!-- <canvas #canvasm1 class="img-fluid" [ngClass]="txtShapevalue == 'Horizontal' ? 'imageHorizontal' : txtShapevalue == 'Vertical' ? 'imageVertical' :'' "></canvas> -->
                    <canvas #canvasm1 class="img-fluid"></canvas>
                    <!-- <img [src]="url" alt=""> -->
                  </figure>
                </div>
                <div class="item">
                  <figure class="thumb-back-color">
                    <!-- <canvas #canvasthumb class="img-fluid" [ngClass]="txtShapevalue == 'Horizontal' ? 'imageHorizontal' : txtShapevalue == 'Vertical' ? 'imageVertical' :'' "></canvas> -->
                    <canvas #canvasthumb class="img-fluid"></canvas>
                    <!-- <img [src]="url" alt=""> -->
                  </figure>
                </div>
                <div class="item">
                  <figure class="thumb-back-color">
                    <!-- <canvas #canvasthumb2 class="img-fluid" [ngClass]="txtShapevalue == 'Horizontal' ? 'imageHorizontal' : txtShapevalue == 'Vertical' ? 'imageVertical' :'' "></canvas> -->
                    <canvas #canvasthumb2 class="img-fluid" ></canvas>
                    <!-- <img src="assets/img/img_2.jpg" alt=""> -->
                  </figure>
                </div>
                <!-- <div class="item">
                  <figure>
                    <img class="img-fluid" [src]="thumbStaticImages" />
                  </figure>
                </div> -->
              </div>
            </div>
          </div>
          <div class="detail_right">
            <div class="detail_right_inner">
              <div class="detail_box detail_frame">
                <h4 class="mo_20_r">Type</h4>
                <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                  <li *ngFor="let types of jsonData.configs.types;let i = index" [ngClass]="{'active': selectedTypeIndex == i}" >
                    <a role="button" *ngIf="types.enableType == true" (click)="getSelectedTypeData(types,i)">
                      <figure><img [src]="types.image" alt=""></figure>
                      <span>{{types.typeLabel}}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <span *ngIf="subTypeDetail.configs[0].size != null">
                <div class="detail_box detail_size d-flex" *ngFor="let shapesize of subTypeDetail.configs[0].size">
                  <img [src]="shapesize.url" [title]="shapesize.label" >
                  <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                    <li *ngFor="let size of shapesize.options;let si = index" [ngClass]="{'active': sizeIndex == si && shapesize.label == chkSizelabel}">
                      <a role="button" [title]="size.widthmm + ' x '+ size.heightmm + ' MM'" (click)="changeImageSize(size,si,shapesize)">
                        {{size.cm}}
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
              <!-- <div class="detail_box detail_mat" *ngIf="subTypeDetail.configs[0].shape != null">
                <h4 class="mo_20_r">{{subTypeDetail.configs[0].shape.label}}</h4>
                <select name="txtShape" [(ngModel)]="txtShape" id="" (change)="changeImageShape()">
                  <option *ngFor="let shap of subTypeDetail.configs[0].shape.options" [ngValue]="shap">{{shap.label}}</option>
                </select>
              </div> -->
              <div class="detail_box detail_mat detail_onlyBox" *ngIf="subTypeDetail.configs[0].profile != null">
                <h4 class="mo_20_r">{{subTypeDetail.configs[0].profile.label}}</h4>
                <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                  <li *ngFor="let frmtype of subTypeDetail.configs[0].profile.options;let pi = index" [ngClass]="{'active': profileIndex == pi}">
                    <a role="button" (click)="getselectedFramColors(frmtype,pi)">
                      <figure><img [src]="frmtype.url" alt=""></figure>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="detail_box detail_mat detail_onlyBox" *ngIf="framTypeColors != null">
                <h4 class="mo_20_r">Color</h4>
                <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                  <li *ngFor="let frmcol of framTypeColors;let ci = index" [ngClass]="{'active': colorIndex == ci}">
                    <a role="button" (click)="changeImageFramColor(frmcol,ci)">
                      <figure><img [src]="frmcol.url" alt=""></figure>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="detail_box detail_size" *ngIf="subTypeDetail.configs[0].lamination != null">
                <h4 class="mo_20_r">{{subTypeDetail.configs[0].lamination.label}}</h4>
                <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                  <li *ngFor="let lami of subTypeDetail.configs[0].lamination.options;let li = index" [ngClass]="{'active': laminationIndex == li}">
                    <a role="button" (click)="changeImageLamination(lami,li)">
                      {{lami.option}}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="detail_box detail_mat" *ngIf="subTypeDetail.configs[0].mat != null">
                <div class="row">
                  <div class="col-md-9">
                    <h4 class="mo_20_r">{{subTypeDetail.configs[0].mat.label}}</h4>
                      <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                        <li *ngFor="let mat of subTypeDetail.configs[0].mat.options;let mi = index" [ngClass]="{'active': matIndex == mi}">
                          <a role="button" *ngIf="mat.url !=null" (click)="changeImageMat(mat,mi)">
                            <img [src]="mat.url">
                          </a>
                          <a role="button" style="justify-content: center;color: black;" *ngIf="mat.url == null" (click)="changeImageMat(mat,mi)">
                            <p class="d-flex align-items-center none-matt">{{mat.label}}</p>
                          </a>
                        </li>
                      </ul>
                  </div>
                  <div class="col-md-3">
                    <div style="float: left;">
                      <h4 class="mo_20_r">{{subTypeDetail.configs[0].width.label}}</h4>
                      <select name="txtWidth" [(ngModel)]="txtWidth" id="" (change)="changeWidth()">
                        <option *ngFor="let wtd of sizeWidth" [ngValue]="wtd">{{wtd.label}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="detail_box detail_size" *ngIf="subTypeDetail.configs[0].glass != null">
                <h4 class="mo_20_r">{{subTypeDetail.configs[0].glass.label}}</h4>
                <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                  <li *ngFor="let glass of subTypeDetail.configs[0].glass.options;let gi = index" [ngClass]="{'active': glassIndex == gi}">
                    <a role="button" (click)="changeImageGlass(glass,gi)">
                      {{glass.option}}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="detail_box detail_size" *ngIf="subTypeDetail.configs[0].mount != null">
                <h4 class="mo_20_r">{{subTypeDetail.configs[0].mount.label}}</h4>
                <ul class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
                  <li *ngFor="let mout of subTypeDetail.configs[0].mount.options;let mti = index " [ngClass]="{'active': mountIndex == mti}">
                    <a role="button" (click)="changeImageMount(mout,mti)">
                      {{mout.option}}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="detail_box detail_mat" *ngIf="subTypeDetail.configs[0].canvaswrap != null">
                <h4 class="mo_20_r">{{subTypeDetail.configs[0].canvaswrap.label}}</h4>
                <select name="txtCanvasWrap" [(ngModel)]="txtCanvasWrap" id="" (change)="changeImageCanvasWrap()">
                  <option *ngFor="let cnvwrp of subTypeDetail.configs[0].canvaswrap.options" [ngValue]="cnvwrp">{{cnvwrp.label}}</option>
                </select>
              </div>
              <div class="detail_box detail_mat" *ngIf="subTypeDetail.configs[0].canvastype != null">
                <h4 class="mo_20_r">{{subTypeDetail.configs[0].canvastype.label}}</h4>
                <select name="txtCanvasType" [(ngModel)]="txtCanvasType" id="" (change)="changeImageCanvastype()">
                  <option *ngFor="let cnvtyp of subTypeDetail.configs[0].canvastype.options" [ngValue]="cnvtyp">{{cnvtyp.label}}</option>
                </select>
              </div>
              <!-- <div class="detail_box detail_address_btn">
                <a href="#" class="btn_fill active" data-bs-toggle="modal" data-bs-target="#shipping_address">Shipping address</a>
                <a href="#" class="btn_fill" data-bs-toggle="modal" data-bs-target="#billing_address">Billing address</a>
              </div> -->
              <div class="detail_box delivery_fees">
                <h4 class="mo_20_r">Address</h4>
                <ul>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" role="button" style="vertical-align: middle;margin-top: 0;" name="addressRadio" id="dlrAddress" value="Dealer"
                       data-bs-toggle="modal" data-bs-target="#shipping_address" (change)="onItemChange($event.target.value)">
                    <label class="form-check-label" for="dlrAddress" role="button">Dealer Address</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" role="button" style="vertical-align: middle;margin-top: 0;" name="addressRadio" id="custAddress" value="Customer"
                    data-bs-toggle="modal" data-bs-target="#shipping_address" (change)="onItemChange($event.target.value)">
                    <label class="form-check-label" for="custAddress" role="button">Customer Address</label>
                  </div>
                </ul>

              </div>
              <!-- <div class="detail_box delivery_fees" *ngIf="isShipping">
                <ul>
                  <li><strong>  </strong><span>:{{txtShipAddress1}},{{txtShipCity}}-{{txtShipPincode}}</span></li>
                </ul>
                 <h4 class="mo_20_r" style="margin-top: 7px;" >Add. : {{txtShipAddress1}},{{txtShipCity}}-{{txtShipPincode}}</h4>
              </div> -->
              <div class="detail_box delivery_fees" style="background-color: white; padding-left: 1rem; padding-top: 1rem; padding-right: 1rem; margin-top: 0rem !important" *ngIf="isShipping">
                <ul>
                  <li><span>{{txtShipAddress1}},{{txtShipCity}}-{{txtShipPincode}}</span></li>
                  <li><strong>Order Amount : </strong><span>₹ {{orderAmount | number:'1.2-2'}}</span></li>
                  <li><strong>Delivery Charge :</strong><span>₹ {{shippingCharges | number:'1.2-2'}}</span></li>
                  <li class="d-flex flex-row flex-wrap justify-content-start align-items-center">
                    <strong>Total Payable Amount : </strong><span>₹ {{totalAmount | number : '1.2-2'}}</span>
                    <p class="p-delivery-date ms-4">Delivery Date :<span>{{expectedDeliveryDate}}</span></p>
                  </li>
                </ul>
              </div>
              <div class="detail_price_box d-flex flex-row flex-wrap justify-content-start align-items-center">
                <div class="me-2">
                  <span class="price" style="font-size: 3.2rem;">₹&nbsp;{{orderAmount + shippingCharges | number : '1.2-2'}}</span><br/>
                  <!-- <label class="mt-2" role="button" data-bs-toggle="modal" data-bs-target="#priceDetail">Show Price Details</label> -->
                </div>
                <a role="button" class="btn_fill btn_save" *ngIf="isShipping" (click)="saveUserOrder()">Save</a>
                <!-- <a role="button" class="btn_fill btn_cancel" style="margin-left: 5px;" (click)="openAddressModal()">Shipping Charge</a> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="detail_discription">
          <h4>Description</h4>
          <p class="mo_14_r">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
          </p>
        </div> -->
      </section>
      <!-- <section class="framing_block">
        <div class="framing_inner">
          <h2 class="section_title mo_35_b text-center">
            <span class="mo_14_m">You can Framebridge just about anything.</span>
            True <strong>custom framing</strong> made<br> truly simple.
          </h2>
          <ul class="d-flex flex-row flex-wrap justify-content-between align-items-stretch">
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/custom_img_1.svg" alt="">
                </figure>
                <h3 class="mo_20_r">choose a frame</h3>
              </div>
            </li>
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/custom_img_2.svg" alt="">
                </figure>
                <h3 class="mo_20_r">upload a photo<br>
                  or send us your art</h3>
              </div>
            </li>
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/custom_img_3.svg" alt="">
                </figure>
                <h3 class="mo_20_r">frame is built and<br>
                  shipped to you</h3>
              </div>
            </li>
          </ul>
          <div class="framing_btn d-block text-center">
            <a href="#" class="btn_fill">continue
              <img src="assets/img/arrow_btn.svg" alt="">
            </a>
          </div>
        </div>
      </section> -->
    </div>
</div>
<!-- #main -->
<app-footer-studio></app-footer-studio>
</div>
<!--Address Modal-->
<div class="modal fade shipping_address" id="shipping_address" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Shipping Detail</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearShipData()"></button>
      </div>
      <div class="modal-body">
        <form action="">
          <!-- <div class="form_group form_column_tow">
            <div>
              <input type="checkbox" role="button" id="chngShipAddress" style="vertical-align: middle;" (click)="changeShippingAddress()">
              Same As Billing Address
            </div>
          </div> -->
          <div class="form_group form_column_tow">
            <div class="form_box">
              <input type="text" placeholder="Customer Name" name="txtCustomerName" [(ngModel)]="txtCustomerName" (focusout)="toUpperCaseCustomerName()">
            </div>
            <div class="form_box">
              <input type="text" placeholder="Mobile Number"name="txtShipMobile" [(ngModel)]="txtShipMobile" maxlength="10" (keypress)="numberOnly($event)">
            </div>
          </div>
          </form>
        <form action="">
          <div class="form_group form_column_one">
            <div class="form_box">
              <input type="email" placeholder="Email" name="txtShipEmail" [(ngModel)]="txtShipEmail">
            </div>
          </div>
          <div class="form_group form_column_one">
            <div class="form_box">
              <input type="text" placeholder="Address 1" name="txtShipAddress1" [(ngModel)]="txtShipAddress1">
            </div>
            <!-- <div class="form_box">
              <input type="text" placeholder="Address 2" name="txtShipAddress2" [(ngModel)]="txtShipAddress2">
            </div> -->
          </div>
          <div class="form_group form_column_tow">
            <div class="form_box">
              <input type="text" placeholder="Country" name="txtShipCountry" [(ngModel)]="txtShipCountry">
            </div>
            <div class="form_box">
              <input type="text" placeholder="State" name="txtShipState" [(ngModel)]="txtShipState">
            </div>
          </div>
          <div class="form_group form_column_tow">
            <div class="form_box">
              <input type="text" placeholder="City" name="txtShipCity" [(ngModel)]="txtShipCity">
            </div>
            <div class="form_box">
              <input type="text" placeholder="Pincode" name="txtShipPincode" [(ngModel)]="txtShipPincode" maxlength="6" (keypress)="numberOnly($event)">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
       <button class="btn_fill" (click)="checkDeliveryPincode()">Submit</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade shipping_address" id="billing_address" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Billing address</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form action="">
          <div class="form_group form_column_tow">
            <div class="form_box">
              <input type="text" placeholder="First Name">
            </div>
            <div class="form_box">
              <input type="text" placeholder="Last Name">
            </div>
          </div>
          <div class="form_group form_column_tow">
            <div class="form_box">
              <input type="text" placeholder="Mobile Number">
            </div>
            <div class="form_box">
              <input type="email" placeholder="Email">
            </div>
          </div>
          <div class="form_group form_column_one">
            <div class="form_box">
              <input type="text" placeholder="Address 1">
            </div>
            <div class="form_box">
              <input type="text" placeholder="Address 2">
            </div>
          </div>
          <div class="form_group form_column_tow">
            <div class="form_box">
              <select name="Country" id="country">
                <option value="">Country</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
              </select>
            </div>
            <div class="form_box">
              <select name="State" id="State">
                <option value="">State</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
              </select>
            </div>
          </div>
          <div class="form_group form_column_tow">
            <div class="form_box">
              <select name="State" id="City ">
                <option value="">City</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
              </select>
            </div>
            <div class="form_box">
              <input type="text" placeholder="Pincode">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
       <button class="btn_fill">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade shipping_address" id="priceDetail" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Price Detail</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body orderbody">
       <form action="">
          <div class="form_group form_column_one">
            <div class="form_box">
              <table class="table table-striped">
                  <tbody>
                      <tr>
                          <th>Frame </th>
                          <td>₹ {{profilePrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr>
                          <th>Frame Color</th>
                          <td>₹ {{colorPrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr *ngIf="txtWidth != null">
                          <th>Width</th>
                          <td>₹ {{widthPrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr *ngIf="txtMat != null">
                          <th>Mat</th>
                          <td>₹ {{matPrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr *ngIf="txtLamination != null">
                          <th>Lamination</th>
                          <td>₹ {{laminationPrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr *ngIf="txtGlass != null">
                          <th>Glass</th>
                          <td>₹ {{glassPrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr *ngIf="txtMount != null">
                          <th>Mount</th>
                          <td>₹ {{mountPrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr *ngIf="txtCanvasWrap != null">
                        <th>Canvas Wrap</th>
                        <td>₹ {{canvasWrapPrice | number : '1.2-2'}}</td>
                      </tr>
                      <tr *ngIf="txtCanvasType != null">
                        <th>Canvas Type</th>
                        <td>₹ {{canvasTypePrice | number : '1.2-2'}}</td>
                      </tr>
                  </tbody>
                  <tfoot>
                    <tr style="font-size: 18px;">
                      <th><b>Total : </b></th>
                      <td><b>₹ {{orderAmount | number : '1.2-2'}}</b></td>
                    </tr>
                  </tfoot>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
