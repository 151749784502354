<app-header></app-header>
<div id="main">
    <div class="breadcrumb">
        <div class="wrapper">
          <div class="breadcrumb_inner">
            <ul class="d-flex flex-row flex-wrap justify-content-start align-items-center">
              <li><a href="#">home</a></li>
              <li><span>Terms and Conditions</span></li>
            </ul>
          </div>
        </div>
    </div>
    <div class="wrapper">
      <section class="framing_block">
        <div class="framing_inner">
          <h2 class="section_title mo_35_b text-center">
            <span>Terms and Conditions</span>
            <!-- <strong>Privacy Policy</strong> -->
            <p class="mo_14_m">tarapara.in is an entity of  Tarapara Industries. As a visitor to the Site/ Customer you are advised to please read the Term & Conditions carefully. By accessing the services provided by the Site you agree to the terms provided in this Terms & Conditions document.</p>
          </h2>
          <ul class="flex-row flex-wrap justify-content-between align-items-stretch">
            <li style="margin-bottom: 1.5rem;">
              <div class="framing_box" style="align-items: start;">
                <h3 class="mo_20_r mb-3">1. Personal Information</h3>
                  <!-- <figure>
                    <img src="assets/img/custom_img_1.svg" alt="">
                  </figure> -->
                  <!-- <h3 class="mo_20_r">choose a frame</h3> -->
                  <p class="mo_14_r text-start">As part of the registration process on the site, tarapara.in may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information the services provided by the Site you agree to the collection and use of your data by tarapara.in in the manner provided in this Privacy Policy.</p>
              </div>
            </li>
            <li style="margin-bottom: 1.5rem;">
              <div class="framing_box" style="align-items: start;">
                <h3 class="mo_20_r mb-3">2. Eligibility</h3>
                <p class="mo_14_r text-start">Services of the Site would be available to only select geographies in India. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use the Site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below 18 years your parents or legal guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult consumption and the sale of which to minors is prohibited.</p>
              </div>
            </li>
            <li style="margin-bottom: 1.5rem;">
              <div class="framing_box" style="align-items: start;">
                <h3 class="mo_20_r mb-3">3. License & Site Access</h3>
                <p class="mo_14_r text-start">tarapara.in grants you a limited sub-license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Tarapara Industries. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Tarapara Industries. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the site or of Tarapara Industries and its affiliates without express written consent. You may not use any meta tags or any other “hidden text” utilizing the site’s name or  trademarks without the express written consent of Tarapara Industries. Any unauthorized use terminates the permission or license granted by Tarapara Industries.</p>
              </div>
            </li>
            <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">4.Account & Registration Obligations</h3>
                  <p class="mo_14_r text-start">All shoppers have to register and login for placing orders on the site. You have to keep your account and registration details current and correct for communications related to your purchases from the site. By agreeing to the terms and conditions, the shopper agrees to receive promotional communication and newsletters upon registration.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">5. Pricing</h3>
                  <p class="mo_14_r text-start">The prices mentioned at the time of ordering will be the prices charged on the date of the delivery. Although prices of most of the products do not fluctuate on a daily basis, some of the raw material cost or processing cost may change over the period. In case the prices are higher or lower on the date of delivery, additional charges will not be collected or refunded as the case may be at the time of the delivery of the order.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">6. Returns</h3>
                  <p class="mo_14_r text-start">Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange. To be eligible for a return, your item must be unused,  in the original packaging and in the same condition that you received it. Several types of goods are exempt from being returned. Customised products and prints cannot be returned.
                    To complete your return, we require a receipt or proof of purchase. There are certain situations where only partial refunds are granted: Any item not in its original condition, is damaged or missing parts for reasons not due to our error. For eligible returns, customers can return back to our address.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">7. Refunds</h3>
                  <p class="mo_14_r text-start">Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your original method of payment.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">8. Delivery</h3>
                  <p class="mo_14_r text-start">You will get to see the delivery date and time at the end of your purchase transaction. However delivery date and time depends on external factors like geographical condition, traffic etc. We will try our best to deliver your order as soon as possible as per given timeline. However for an unforeseen situation tarapara.in reserves the right to modify the delivery date and time.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">9. You Agree and Confirm</h3>
                  <p class="mo_14_r text-start">That in the event that a non-delivery occurs on account of a mistake by you (i.e. wrong name or address or any other wrong information) any extra cost incurred by tarapara.in for redelivery shall be claimed from you. That you will use the services provided by the site, its affiliates, consultants and contracted companies, for lawful purposes only and comply with all applicable laws and regulations while using and transacting on the site. You will provide authentic and true information in all instances where such information is requested of you. tarapara.in reserves the right to confirm and validate the information and other details provided by you at any point of time. If upon confirmation your details are found not to be true (wholly or partly), it has the right in its sole discretion to reject the registration and debar you from using the services and / or other affiliated websites without prior intimation whatsoever. You authorise tarapara.in to contact you for any transactional purposes related to your order/account. That you are accessing the services available on this site and transacting at your sole risk and are using your best and prudent judgment before entering into any transaction through this Site. That the address at which delivery of the product ordered by you is to be made will be correct and proper in all respects. That before placing an order you will check the product description carefully. By placing an order for a product you agree to be bound by the conditions of sale included in the item’s description.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">10. Products colours</h3>
                  <p class="mo_14_r text-start">We have made every effort to display the colours of our products that appear on the Website as accurately as possible. However, as the actual colours you see will depend on your monitor/mobile, we cannot guarantee that your monitor’s/mobile’s display of any colour will be accurate.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">11. Modification of Terms & Conditions of Service</h3>
                  <p class="mo_14_r text-start">tarapara.in may at any time modify the Terms & Conditions of Use of the Website without any prior notification to you. You can access the latest version of these Terms & Conditions at any given time on the Site. You should regularly review the Terms & Conditions on the Site. In the event the modified Terms & Conditions is not acceptable to you, you should discontinue using the Service. However, if you continue to use the Service you shall be deemed to have agreed to accept and abide by the modified Terms & Conditions of Use of this Site.</p>
                </div>
              </li>
          </ul>
        </div>
      </section>
    </div>
</div>
<app-footer></app-footer>
