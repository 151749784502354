<div class="main_component">
<app-sub-header></app-sub-header>
<div id="main" style="margin-top: 25px;">
    <div class="wrapper header-manage">
      <section class="framing_block">
        <div class="framing_inner">
            <ul class="d-flex flex-row flex-wrap justify-content-between align-items-stretch">
                <li>
                <div class="framing_box" style="background-color: #88eab9;">
                    <figure>
                        <i class="fas fa-check-circle fa-3x"></i>
                    </figure>
                    <h3 class="mo_20_r">Registration</h3>
                </div>
                </li>
                <li>
                <div class="framing_box" style="background-color: #88eab9;">
                    <figure>
                        <i class="fas fa-check-circle fa-3x"></i>
                    </figure>
                    <h3 class="mo_20_r">Payment</h3>
                </div>
                </li>
                <li>
                <div class="framing_box">
                    <figure>
                        <i class="fas fa-history fa-3x"></i>
                    </figure>
                    <h3 class="mo_20_r">Account Creation</h3>
                </div>
                </li>
            </ul>
          <h2 style="font-size: 20px;" class="section_title mo_35_b text-center mt-5">
            <!-- <span class="mo_14_m">You can Framebridge just about anything.</span> -->
            Thank you for partenering with Tarapara - studio business.<br/>
            We will verify your details and activate your account soon.
          </h2>
        </div>
      </section>
    </div>
</div>
  <!-- #main -->
<app-footer-studio class="fix-footer"></app-footer-studio>
</div>