<footer id="footer">
    <div class="wrapper"> 
       <div class="footer_inner d-flex flex-row flex-wrap justify-content-between align-items-start">
         <div class="footer_com_box footer_1">
           <a href="#" class="d-block">
             <img src="assets/img/logo.png" alt="">
           </a>
           <ul class="social_links d-flex flex-row flex-wrap justify-content-start align-items-center">
             <li>
               <a href="#">
                <i class="fab fa-facebook-f"></i>
               </a>
             </li>
             <li>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
           </ul>
         </div>
         <!-- <div class="footer_com_box footer_2">
           <div class="foot_linkbox">
             <h4 class="mo_16_b">Our links</h4>
             <ul>
               <li><a href="#">Shop Frames</a></li>
               <li><a href="#">Start a Print</a></li>
               <li><a href="#">Start a Frame</a></li>
             </ul>
           </div>
         </div> -->
         <div class="footer_com_box footer_3">
          <div class="foot_linkbox">
            <h4 class="mo_16_b">Info</h4>
            <ul class="target_link">
              <li><a role="button" data-id="stdHelp"[routerLink]="['']" (click)="redirecthomeabout()">Help</a></li>
              <li><a role="button" data-id="stdAbout"[routerLink]="['']" (click)="redirecthomeabout()">About</a></li>
              <li><a href="#">How It Works</a></li>
              <li><a [routerLink]="['/terms']">Terms</a></li>
              <li><a [routerLink]="['/privacy']">Privacy</a></li>
            </ul>
          </div>
         </div>
         <div class="footer_com_box footer_4">
          <div class="foot_linkbox">
            <h4 class="mo_16_b">Contact us</h4>
            <ul>
              <li><a href="tel:+91 9302 33 9302"><img src="assets/img/call.svg" alt=""><span>+91 9302 33 9302</span></a></li>
              <li><a href="mailto:info@tarapara.in"><img src="assets/img/email.svg" alt=""><span>info@tarapara.in</span></a></li>
              <li><address><img src="assets/img/marker.svg" alt=""><span>Tarapara Industries<br>B-18, Krishana Industrial Estate, <br/>
                B/H Nirman Industrial Estate, <br>
                Ashwani Kumar Road, Fulpada, <br>
                Surat, Gujarat  - 395008, India.<br>
                </span></address></li>
            </ul>
          </div>
         </div>
         <!-- <div class="footer_com_box footer_5">
           <div class="foot_linkbox">
            <h4 class="mo_16_b">Subscribe To Our Newsletter!</h4>
            <form action="">
              <div class="form_group">
                <input type="text" placeholder="Enter your email">
              </div>
              <button class="btn_fill">Submit</button>
            </form>
           </div>
         </div> -->
       </div>
    </div>
    <div class="copyright">
      <div class="wrapper">
        <p class="mo_14_r">© Copyright Tarapara - {{currentYear}}</p>
      </div>
    </div>
</footer>
<!-- #footer -->