import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-studio',
  templateUrl: './footer-studio.component.html',
  styleUrls: ['./footer-studio.component.css']
})
export class FooterStudioComponent implements OnInit {

  currentYear;
  dealerAddress;
  dealerCity;
  dealerPincode;
  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.getLoginUserData();
  }

  getLoginUserData() {
    var data = JSON.parse(localStorage.getItem("studio-loginuser"));
    this.dealerAddress = data.address;
    this.dealerCity = data.city;
    this.dealerPincode = data.pincode;
    // console.log(data);
  }
}
