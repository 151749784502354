<app-header></app-header>
<!-- /header -->
<div id="main">
    <div class="breadcrumb">
      <div class="wrapper">
        <div class="breadcrumb_inner">
          <ul class="d-flex flex-row flex-wrap justify-content-start align-items-center">
            <li><a href="#">home</a></li>
            <li><span>Cart</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <section class="detail_block">
        <div class="detail_inner d-flex flex-row flex-wrap justify-content-start align-items-start">
          <div class="detail_left">
            <div class="detail_slider" >
              <div class="item" *ngFor="let cartitem of cartItems">
                <div class="row">
                    <div class="col-md-4">
                      <figure>
                        <img [src]="cartitem.productimage" alt="">
                      </figure>
                    </div>
                    <div class="col-md-8">
                      <!-- <figure>
                        <img src="assets/img/fram.png" alt="">
                      </figure> -->
                      <p role="button" (click)="removeItem(cartitem)" style="color: black;" class="text-uppercase me-3"><i
                        class="fa fa-trash me-1 text-danger"></i></p>
                      <span>Price : Rs.{{cartitem.productPrice}}</span><br>
                      <span>Product Name : {{cartitem.productPrice}}</span><br>
                      <span>Size : {{cartitem.size}}</span><br>
                      <span>Frame Type : {{cartitem.frame}}</span><br>
                      <span>Frame Color : {{cartitem.frameColor}}</span><br>
                      <span>Mat : {{cartitem.mat}}</span><br>
                      <span>Lamination : {{cartitem.lamination}}</span><br>
                      <span>Glass : {{cartitem.glass}}</span><br>
                      <span>Mount : {{cartitem.mount}}</span><br>
                      <span *ngIf="cartitem.canvasWrap != null">Canvas Wrap : {{cartitem.canvasWrap}}</span><br>
                      <span *ngIf="cartitem.canvasType != null">Canvas Type : {{cartitem.canvasType}}</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="detail_right">
            <div class="detail_right_inner">
              <div class="detail_upload_btn">
                <!-- <a role="button" class="btn_fill">Upload image</a> -->
                <div>
                  <label class="radio-inline me-3">
                    <input type="radio" name="optradio" checked>&nbsp; Billing Address
                  </label>
                  <label class="radio-inline">
                    <input type="radio" name="optradio">&nbsp; Shipping Address
                  </label>
                </div>
              </div>
              <div class="detail_price_box d-flex flex-row flex-wrap justify-content-start align-items-center">
                Delivery Date : <span>{{expectedDeliveryDate}}</span>
              </div>
              <div class="detail_price_box d-flex flex-row flex-wrap justify-content-start align-items-center">
                <span class="price">₹ {{totalNetAmount | number:'1.2-2'}}</span>
                <a role="button" class="btn_fill btn_save">Make Payment</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</div>
<!-- #main -->
<app-footer></app-footer>
