<header id="header">
    <div class="header_content">      
        <nav class="navbar navbar-expand-lg p-0">
          <div class="wrapper d-flex flex-row flex-wrap justify-content-start align-items-center">
            <a class="logo me-auto" href="#">              
              <img src="assets/img/logo.png" alt="">      
            </a>
            <div class="collapse navbar-collapse nav" id="navbarNav">        
              <ul class="navbar-nav target_link">
                <li class="menu-item">
                  <a role="button" class="nav-link d-block p-0 _14_b_bj" data-id="stdHome" aria-current="page" [routerLink]="['']" (click)="redirecthomeabout()">Home</a>
                </li>
                <li class="menu-item">
                  <a role="button" class="nav-link d-block p-0 _14_b_bj" data-id="stdAbout" [routerLink]="['']" (click)="redirecthomeabout()">About</a>
                </li>
                <li class="menu-item">
                  <a role="button" class="nav-link d-block p-0 _14_b_bj" data-id="stdContact" [routerLink]="['']" (click)="redirecthomeabout()">Contact</a>
                </li>  
                <li class="menu-item">
                  <a class="nav-link d-block p-0 _14_b_bj" href="#">Segment</a>
                  <ul class="dropdown">
                    <li><a role="button" [routerLink]="['/studio-signin']">Studio</a></li>
                    <!-- <li><a role="button">Hospital</a></li>
                    <li><a role="button">Hotel</a></li>
                    <li><a role="button">Interior</a></li> -->
                  </ul>
                </li>
                
                <!-- <li class="menu-item" *ngIf="!isLoginUser">
                  <a class="nav-link" href="javascript:void(0);" data-bs-toggle="modal"
                    data-bs-target="#loginModal" tooltip="LOGIN/SIGN UP" flow="down">Login<i style="vertical-align: middle;" class="fas fa-sign-in-alt"></i>
                  </a>
              </li>       -->
              </ul> 
          </div>              
            <!-- <div class="user_btn active" *ngIf="isLoginUser">
              <p>                  
                <a role="button" class="user_link d-flex flex-row flex-nowrap justify-content-between align-items-center">
                  <span class="user_name active">{{userName}}</span>
                  <img src="assets/img/user.svg" alt="">
                </a>
              </p>
              <ul class="dropdown_cart">
                <li><a role="button" [routerLink]="['/myaccount']" >My Account</a></li>
                <li><a role="button" (click)="logoutUser()">Logout</a></li>
              </ul>
            </div> -->
            <!-- <div class="cart_btn active">
              <a href="#" class="cart_link">
                <img src="assets/img/cart.svg" alt="">
                <span class="cart_velue">3</span>
              </a>
            </div> -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <!-- /container -->           
        </nav>
        <!-- /nav -->  
    </div>
    <!-- header_content -->
</header>
<!-- /header -->
<!-- Login Modal -->
<div class="modal fade loginModal" id="loginModal" style="z-index: 9999!important;" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" style="font-size: 2rem;">Login</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <div class="login-holder">
                  <div class="form-floating mb-3">
                      <input type="tel" maxlength="10" class="form-control" id="floatingInput" required [(ngModel)]="txtmobilenumber"
                          name="txtmobilenumber" placeholder="eg. 9988774455" (keypress)="numberOnly($event)">
                      <label for="floatingInput">Mobile Number <span style="color: red;">*</span></label>
                  </div>
                  <div class="form-floating mb-3" *ngIf="otpTextEnable">
                      <input type="tel" class="form-control" id="floatingPassword" required [(ngModel)]="txtLoginOTP"
                          name="txtLoginOTP" placeholder="Enter OTP" (keypress)="numberOnly($event)">
                      <label for="floatingPassword">OTP <span style="color: red;">*</span></label>
                  </div>
                  <div class="col-auto d-flex flex-row flex-wrap justify-content-start align-items-stretch"><a role="button" id="resenddiv1" style="margin-top: 9px;margin-bottom: 5px; float:left; display: none;" (click)="resendOtptouser()">Resend OTP?</a></div>
                  <!-- <button class="btn btn-primary m-auto" *ngIf="!btnVerifyEnable" (click)="loginUser()">Login</button>
                  <button class="btn btn-primary m-auto" *ngIf="btnVerifyEnable" (click)="verifyUser()">Verify</button> -->
                  <a role="button" class="loginbutton" *ngIf="!btnVerifyEnable" (click)="loginUser()">Login</a>
                  <a role="button" class="loginbutton" *ngIf="btnVerifyEnable" (click)="verifyUser()">Verify</a>
                  <!-- <div class="text-center text-muted delimiter">or use a social network</div>
                  <div class="d-flex justify-content-center social-buttons">
                      <button type="button" class="btn btn-secondary btn-round" title="Twitter">
                          <i class="fab fa-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-secondary btn-round" title="Facebook">
                          <i class="fab fa-facebook"></i>
                      </button>
                      <button type="button" class="btn btn-secondary btn-round" title="Google">
                          <i class="fab fa-google"></i>
                      </button>
                  </div> -->
              </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
              <div class="signup-section">Not a member yet? <a href="#a" data-bs-toggle="modal"
                      data-bs-target="#signupModal" class="text-primary" data-bs-dismiss="modal">Sign Up</a>.
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Login Modal Close -->
<div class="modal fade loginModal" id="signupModal" style="z-index: 9999 !important;" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" style="font-size: 2rem;">Sign Up</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

              <div class="login-holder">
                  <div class="row g-2">
                    <div class="form-floating col-6">
                      <input type="text" class="form-control" [(ngModel)]="txtDealerName" name="txtDealerName"
                        id="floatingName" placeholder="Name" (focusout)="toUpperCaseDealerName()">
                      <label for="floatingName">Dealer Name <span style="color: red;">*</span></label>
                    </div>
                    <div class="form-floating col-6 ">
                      <input type="text" class="form-control" [(ngModel)]="txtOwnerName" name="txtOwnerName"
                        id="floatingName" placeholder="Name" (focusout)="toUpperCaseOwnerName()">
                      <label for="floatingName">Owner Name <span style="color: red;">*</span></label>
                    </div>
                  </div>
                  <div class="row g-2">
                    <div class="form-floating col-6 ">
                      <input type="text" class="form-control" required [(ngModel)]="txtContactPerson"
                        name="txtContactPerson" id="floatingInstitute" (focusout)="toUpperCaseContactPerson()" placeholder="Contact Person">
                      <label for="floatingInstitute">Contact Person <span style="color: red;">*</span></label>
                    </div>
                    <div class="form-floating col-6">
                      <input type="text" class="form-control" required [(ngModel)]="txtGstin"
                        name="txtGstin" id="floatingDise" placeholder="Gstin">
                      <label for="floatingDise">Gstin <span style="color: red;">*</span></label>
                    </div>
                  </div>
                  <div class="form-floating">
                    <input type="email" class="form-control" required [(ngModel)]="txtemail" name="txtemail"
                      id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">Email <span style="color: red;">*</span></label>
                  </div>
                  <div class="form-floating">
                    <input type="tel" class="form-control" required [(ngModel)]="txtsignupmobilenumber"
                      name="txtsignupmobilenumber" id="floatingName" (focusout)="checkUserMobileNoExit()" maxlength="10" placeholder="Mobile Number" (keypress)="numberOnly($event)">
                    <label for="floatingName">Mobile Number <span style="color: red;">*</span></label>
                  </div>
                  <div class="row g-2">
                    <div class="form-floating col-6">
                      <input type="text" class="form-control" required [(ngModel)]="txtCountry" name="txtCountry"
                        id="floatingName" placeholder="Country" (focusout)="toUpperCaseCountry()">
                      <label for="floatingName">Country <span style="color: red;">*</span></label>
                    </div>
                    <div class="form-floating col-6 ">
                      <input type="text" class="form-control" required [(ngModel)]="txtstate" name="txtstate"
                        id="floatingName" placeholder="State" (focusout)="toUpperCaseState()">
                      <label for="floatingName">State <span style="color: red;">*</span></label>
                    </div>
                  </div>
                  <!-- <div class="form-floating">
                      <input type="text" class="form-control" required [(ngModel)]="txtaddress" name="txtaddress"
                          id="floatingName" placeholder="address">
                      <label for="floatingName">Address <span style="color: red;">*</span></label>
                  </div> -->
                  <div class="row g-2">
                    <div class="form-floating col-6">
                      <input type="text" class="form-control" required [(ngModel)]="txtcity" name="txtcity"
                        id="floatingName" placeholder="City" (focusout)="toUpperCaseCity()">
                      <label for="floatingName">City <span style="color: red;">*</span></label>
                    </div>
                    <div class="form-floating col-6">
                      <input type="tel" class="form-control" required [(ngModel)]="txtpincode" name="txtpincode"
                        id="floatingName" maxlength="6" placeholder="Pincode" (keypress)="numberOnly($event)">
                      <label for="floatingName">Pincode <span style="color: red;">*</span></label>
                    </div>
                  </div>

                  <div class="text-center text-muted delimiter mt-3">
                      <small>By clicking <i>Sign Up</i>, you agree to our <!--<a href="#"><strong>User Agreement</strong></a> and -->
                          <a role="button" (click)="goTOPrivacyPolicy()"><strong>Privacy Policy</strong></a>.</small>
                      </div>
                  <!-- <button class="btn btn-primary m-auto mt-1" (click)="signup()">Sign up</button> -->
                  <a role="button" class="loginbutton mt-3" (click)="signup()">Sign up</a>
              </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
              <div class="signup-section">Already have an account?

                  <a href="#a" data-bs-toggle="modal" data-bs-target="#loginModal" class="text-primary"
                      data-bs-dismiss="modal">Login</a>.
              </div>
          </div>
      </div>
  </div>
</div>
<div class="modal fade loginModal" id="otpstaticBackdrop" style="z-index: 9999!important;" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel" style="font-size: 2rem;">Verify OTP</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="login-holder">
                <div class="form-floating mb-3">
                    <input type="tel" class="form-control" id="pass" name="txtUserOtp"
                        [(ngModel)]="txtUserOtp" placeholder="Enter OTP" (keypress)="numberOnly($event)">
                    <label for="pass">OTP <span style="color: red;">*</span></label>
                </div>
                <a class="" role="button" id="resenddivReg" style="margin-top: 9px; float:left; display: none;" title="Resend OTP" rel="nofollow"
                  (click)="regResendOtptouser()">Resend OTP?</a>
                <a role="button" class="loginbutton text-center" (click)="regVerifyUser()">Verify</a>
              </div>
            </div>
        </div>
    </div>
</div>
