<app-header></app-header>
<div id="main">
    <div class="wrapper">
      <section class="banner_black" id="stdHome">
        <div class="banner_inner d-flex flex-row flex-wrap justify-content-between align-items-center">
          <div class="banner_left black_text">
            <h1 class="mo_60_b">Custom frames, <span>made</span> simple.</h1>
            <p class="mo_14_r ">
              We are always ready to do whatever it takes to make sure you are completely satisfied with your print. We take great care in crafting your canvas.
            </p>
            <!-- <a href="#" class="btn_fill">Start a Frame
              <img src="assets/img/arrow_btn.svg" alt="">
            </a> -->
          </div>
          <div class="banner_right">
            <figure>
              <img src="assets/img/img_1.jpg" alt="">
            </figure>
          </div>
        </div>
      </section>
      <section class="about_block" id="stdAbout">
        <div class="about_inner d-flex flex-row flex-wrap justify-content-between align-items-center">
          <div class="about_left">
            <figure>
              <img src="assets/img/about_img.png" alt="">
            </figure>
          </div>
          <div class="about_right black_text">
            <h2 class="section_title mo_35_b">
              <span class="mo_14_m">Artwork</span>
              About <strong>us</strong>
            </h2>
            <p class="mo_14_r">
              <b class="mo_14_m">Tarapara</b> customizes your photo and art on high quality aluminum framed canvas. Based in Surat, Gujarat. From the start, we have taken pride in providing top-quality premium products and are 
              doing our best to serve our community by processing photos of families, weddings, and vacations and creating lifelong memories in the form of portraits which can be preserved and kept around your surroundings for life long. We have soon  expanded our services to businesses, offices, hospitals and other sectors.
            </p>
            <p class="mo_14_r">
              What differentiate us from the others is the quality of product we provide at comparative rates and something unique which a user wouldn’t find in the open market. Our metal frames are designed specially 
              by us with considering different parameters and expectations of the user. We are capable of providing it with different size, design, color which can add value to your place of use.  
            </p>
            <p class="mo_14_r">
              We want to help you keep your most precious memories last forever. We actively recruit people with not just skill, experience  and talent, but with passion that ensures each photo is brought to life with expert care.
            </p>
            <p class="mo_14_r">
              At <b class="mo_14_m">tarapara</b> we understand the importance of your wall worthy photos and art. They are the moments you cherish the most, the views that took your breath away, the loved ones you hold closest to your heart. We give each aluminum framed canvas print the same attention we would give our own favorite photos. You can rest assured, your print is handled with love.
            </p>
            <p class="mo_14_r">
              We feel our business falls into the premium category(quality wise) because the look of our product is  really eye-catching and this characteristic of ours helps us to earn greater profit margins compared to others. As far as revenue is concerned we are confident that the flow of revenue with potential growth would 
              come with ease because we are constantly innovating and improvising our products with market needs and try to feature something new in the market always.
            </p>
            <!-- <a href="#" class="btn_fill">
              Read more
              <img src="assets/img/arrow_btn.svg" alt="">
            </a> -->
          </div>
        </div>
      </section>
       <section class="banner_black mb-3" id="stdContact">
        <div class="banner_inner d-flex flex-row flex-wrap justify-content-between align-items-center">
          <div class="banner_left black_text">
            <h2 class="section_title mo_35_b">
              <!-- <span class="mo_14_m">Artwork</span> -->
              Contact <strong>us</strong>
            </h2>
            <p class="mo_14_r">
              <b class="mo_14_m">Tarapara Industries</b><br/>
              B-18, Krishana Industrial Estate, <br/>
              B/H Nirman Industrial Estate, <br/>
              Ashwani Kumar Road, Fulpada, <br/>
              Surat, Gujarat  - 395008, India.<br/>
              +91 9302 33 9302<br/>
              info@tarapara.in
            </p>
          </div>
          <div class="banner_right">
            <figure>
              <img src="assets/img/contact-us-img.jpg" alt="">
            </figure>
          </div>
        </div>
      </section>
      <section class="framing_block">
        <div class="framing_inner">
          <br/> <br/>
          <h2 class="section_title mo_35_b text-center">
            <span class="mo_14_m">You can Frame just about anything.</span>
            True <strong>custom framing</strong> made<br> truly simple.
          </h2>
          <ul class="d-flex flex-row flex-wrap justify-content-between align-items-stretch">
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/custom_img_1.svg" alt="">
                </figure>
                <h3 class="mo_20_r">choose a frame</h3>
              </div>
            </li>
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/custom_img_2.svg" alt="">
                </figure>
                <h3 class="mo_20_r">upload a photo<br>
                  or send us your art</h3>
              </div>
            </li>
            <li>
              <div class="framing_box">
                <figure>
                  <img src="assets/img/custom_img_3.svg" alt="">
                </figure>
                <h3 class="mo_20_r">frame is built and<br>
                  shipped to you</h3>
              </div>
            </li>
          </ul>
          <!-- <div class="framing_btn d-block text-center">
            <a href="#" class="btn_fill">continue
              <img src="assets/img/arrow_btn.svg" alt="">
            </a>
          </div> -->
        </div>
      </section>
      <section class="framing_block" id="stdHelp">
        <div class="framing_inner">
          <h2 class="section_title mo_35_b" style="margin-left: 20px;">
            Help
          </h2>
          <ul class="d-flex flex-row flex-wrap justify-content-between align-items-stretch">
            <li>
              <div class="framing_box help_section" style="text-align: inherit !important;">
                <p class="mo_14_r">
                  <span> What are the image specifications for printing custom images?</span><br/>
                  We recommend that you upload the biggest, highest resolution image you have (the image should be clear at the largest size). 
                </p><br/>
                <p class="mo_14_r">
                <span>What file format images can I upload to Photos to Art?</span><br/>
                  You may upload photos that are formatted as “jpg”, “tiff”, “pdf”, “png”, or “gif”              
                </p><br/>
                <p class="mo_14_r">
                <span>Where can I see the sizes?</span><br/>
                  Once you have uploaded your photo and created an account, you will be taken to a page to select your options.              
                </p><br/>
                <p class="mo_14_r">
                <span>Why do I need to create an account before selecting a size?</span><br/>
                  Yes, accounts are required for custom photo orders. We are able to save your image in your account for future reference and to store your proofs for approval and reference.              
                </p><br/>
                <p class="mo_14_r">
                <span>How long will I have access to my images on Photos to Art?</span><br/>
                  tarapara industries is not an image storage or hosting service. Therefore, the images you upload are made accessible for limited periods of time. If you upload images but do not purchase any prints, your images will be accessible for 30 days. If you purchase prints, you will have access to your images for at least 90 days from the date of purchase.
                </p><br/>
                <p class="mo_14_r">
                <span>Will I receive a proof before my image is printed?</span><br/>
                  You can expect to receive a proof of your touched up image within two business days.
                </p><br/>
                <p class="mo_14_r">
                <span>What is the delivery timeframe for custom artwork?</span><br/>
                  Our items are made to order by hand and can take up to 2 - 5 business days to assemble and ship (after the proof has been approved by the customer).  Customers who selected "Free Standard Shipping" should expect to receive their orders between 7-10 business days from the date of purchase.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
</div>
  <!-- #main -->
<app-footer></app-footer>
