import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from '../service/signup/signup.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {

  loginUserData;
  isLoginUser = false;
  userName;
  constructor(
    private toastr: ToastrService,
    private SignupService: SignupService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadScript('../assets/js/general.js');
    this.getLoginUserData();
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  logoutUser() {
    localStorage.removeItem("studio-loginuser");
    localStorage.removeItem("studio-token");
    // let component = localStorage.getItem("component");
    this.toastr.success("Logout successfully");
    this.router.navigate(['']);
    this.getLoginUserData();
  }

  getLoginUserData() {
    this.loginUserData = JSON.parse(localStorage.getItem("studio-loginuser"));
    // console.log(this.loginUserData);
    if (this.loginUserData == null || this.loginUserData == undefined) {
      this.isLoginUser = false;
    } else {
      this.isLoginUser = true;
      this.userName = this.loginUserData.companyName;
      // this.headerFname = this.loginUserData.firstName;
      // this.headerLname = this.loginUserData.lastName;
    }
  }

  goToStudioPage() {
    this.SignupService.getLoginUserData().subscribe(res=>{
      var resData = res;
      // console.log(res);
      if (resData == null || resData == undefined) {
        this.toastr.error("Please login");
      } else {
        if (resData.active == true && resData.razorPayPaymentId != null && resData.paymentStatus == "SUCCESS") {
          this.router.navigate(['/studio']);
        } else if (resData.active == false && resData.razorPayPaymentId == null && (resData.paymentStatus == "created" || resData.paymentStatus == "" || resData.paymentStatus == null)) {
          this.toastr.error("Please make payment");
        } else if (resData.active == false && resData.razorPayPaymentId != null && resData.paymentStatus == "SUCCESS") {
          this.toastr.error("please wait until admin can accept your request.")
        } else {
          this.toastr.error("Please login");
        }
      }
    })
  }
  
  goToAccount() {
    this.SignupService.getLoginUserData().subscribe(res=>{
      var resData = res;
    // var resData = JSON.parse(localStorage.getItem("studio-loginuser"));
    // console.log(resData);
      if (resData == null || resData == undefined) {
        this.toastr.error("Please login");
      } else {
        if (resData.active == true && resData.razorPayPaymentId != null && resData.paymentStatus == "SUCCESS") {
          this.router.navigate(['/myaccount']);
        } else if (resData.active == false && resData.razorPayPaymentId == null && (resData.paymentStatus == "created" || resData.paymentStatus == "" || resData.paymentStatus == null)) {
          this.toastr.error("Please make payment");
        } else if (resData.active == false && resData.razorPayPaymentId != null && resData.paymentStatus == "SUCCESS") {
          this.toastr.error("please wait until admin can accept your request.")
        } else {
          this.toastr.error("Please login");
        }
      }
    })
  }
}
