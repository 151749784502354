export class ProductsCartDetail {
    id1: number;
    category: string;
    productid: number;
    productName: string;
    productimage: string;
    productDescription: string;
    categoryCombinationId: string;
    size: string;
    frame: string;
    frameColor: string;
    productQuantity: number;
    productPrice: number;
    subTotal: number;
    height: string;
    // width:string;
    length: string;
    breadth: string;
    weight: number;
    totalweight: number;
    staticimg: string;
    canvasType: string;
    canvasWrap: string;
    mat: string;
    lamination: string;
    glass: string;
    mount: string;
}