export class OrderDetailDto {
    productid: string;
    productName: string;
    productqty: number;
    productPrice: string;
    productImage: string;
    mainImage: string;
    size: string;
    frame: string;
    frameColor: string;
    length: string;
    breadth: string;
    height: string;
    weight: string;
    totalAmount: number;
    productDiscount: number;
    netAmount: number;
    canvasType: string;
    canvasWrap: string;
    mat: string;
    lamination: string;
    glass: string;
    mount: string;
    width: string;
    shape: string;
}