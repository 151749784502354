import { Component, NgZone, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from '../service/signup/signup.service';
import { Router } from '@angular/router';
import { ICustomWindow, RazorpayService } from '../service/razorpay/razorpay.service';
import { CheckoutService } from '../service/checkout/checkout.service';
import { GlobalStudioConstants } from '../service/GlobalStudioConstants';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  subscription: Subscription;
  everyTwoSeconds: Observable<number> = timer(0, 1000);
  allCategory;
  cartLength = 0;
  cartItems;
  totalBill = 0;
  txtmobilenumber
  exituser
  otpTextEnable = false;
  btnVerifyEnable = false;
  createdOtp;
  txtLoginOTP;
  loginUserData;
  isLoginUser = false;

  txtDealerName;
  txtContactPerson;
  txtOwnerName;
  txtemail;
  txtsignupmobilenumber;
  txtGstin;
  txtCountry;
  txtcity;
  txtstate;
  txtpincode;

  signupcreatedOtp;
  txtUserOtp;
  ressignup;
  userName;
  userMobile;
  resLoginUser;
  private _window: ICustomWindow
  public rzp: any

  constructor(
    private signupservice: SignupService,
    private toastr: ToastrService,
    private router: Router,
    private zone: NgZone,
    private razorpayService: RazorpayService,
    private checkoutservice: CheckoutService,
  ) {
    this._window = this.razorpayService.nativeWindow;
  }

  ngOnInit(): void {
    this.loadScript('../assets/js/general.js');
    this.getLoginUserData();
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  logoutUser() {
    localStorage.removeItem("studio-loginuser");
    localStorage.removeItem("studio-token");
    let component = localStorage.getItem("component");
    this.toastr.success("Logout successfully");
    if (component == "HomeComponent") {
      window.location.reload();
    } else {
      this.router.navigate(['']);
    }
    this.getLoginUserData();
  }

  headerFname;
  headerLname;
  getLoginUserData() {
    this.loginUserData = JSON.parse(localStorage.getItem("studio-loginuser"));
    // console.log(this.loginUserData);

    if (this.loginUserData == null || this.loginUserData == undefined) {
      this.isLoginUser = false;
    } else {
      this.isLoginUser = true;
      this.userName = this.loginUserData.companyName;
      // this.headerFname = this.loginUserData.firstName;
      // this.headerLname = this.loginUserData.lastName;
    }
  }

  userProfile() {
    this.signupservice.getLoginUserData().subscribe(res => {
      localStorage.setItem("studio-loginuser", JSON.stringify(res));
      this.loginUserData = JSON.parse(localStorage.getItem("studio-loginuser"));
      var componentname = localStorage.getItem("component");
      if (componentname == "UserProfileComponent") {
        window.location.reload();
      } else {
        this.router.navigate(['/userprofile']);
      }
    }, error => {
      // this.router.navigate([''])
      this.toastr.error("please login.")
      localStorage.removeItem("studio-loginuser");
      localStorage.removeItem("studio-token");
      $('#loginModal').modal('show');
    })
  }

  loginUser() {
    var mob = this.txtmobilenumber;
    if (this.txtmobilenumber == null || this.txtmobilenumber == '' || this.txtmobilenumber == undefined || (this.txtmobilenumber = mob.trim()) == "") {
      this.toastr.error("mobile number  is required");
    } else if (this.txtmobilenumber.length > 10 || this.txtmobilenumber.length < 10) {
      this.toastr.error("mobile number must be 10 digit");
    } else {
      this.signupservice.checkDealerExitOrNot(this.txtmobilenumber).subscribe(res => {
        this.exituser = res;
        if (this.exituser == false || this.exituser == 'false') {
          this.toastr.error("user not found.")
        } else {
          this.userMobile = this.txtmobilenumber;
          // console.log(this.exituser);
          // this.createdOtp = Math.floor(1000 + Math.random() * 9000);
          // console.log(this.createdOtp);
          this.sendOtptouser();
          setTimeout(function () {
            // alert("Hello");
            $('#resenddiv1').css('display', "block");
          }, 20000)
        }
      }, error => {
        this.toastr.error("user not found.")
      })
    }
  }

  verifyUser() {
    if (this.txtLoginOTP == "" || this.txtLoginOTP == null || this.txtLoginOTP == undefined) {
      this.toastr.error("please enter OTP.");
    } else {
      if (this.createdOtp != "Success") {
        // console.log("invalid OTP");
        this.toastr.error("Invalid OTP.");
      } else {
        this.signupservice.authenticatUser(this.txtmobilenumber, this.txtLoginOTP).subscribe(res => {
          // console.log(res.token);
          localStorage.setItem("studio-token", res.token);
          this.authUser();
        }, error => {
          this.toastr.error("Invalid OTP.");
        })
      }
    }
  }

  authUser() {
    this.signupservice.getDealerByMobile(this.userMobile).subscribe(res => {
      // console.log(res);
      this.txtorderid = 'ordDel' + res.dealerId;
      var component = localStorage.getItem("component");
      if (res.active == true && res.razorPayPaymentId != null && res.paymentStatus == "SUCCESS") {
        localStorage.setItem("studio-loginuser", JSON.stringify(res));
        this.toastr.success("Login Successful.");
        this.router.navigate(['/studio']);
        this.getLoginUserData();
        $('.modal-backdrop').remove();
      } else if (res.active == false && res.razorPayPaymentId == null && (res.paymentStatus == "created" || res.paymentStatus == "" || res.paymentStatus == null)) {
        this.createRazorPayOrderId();
      } else if (res.active == false && res.razorPayPaymentId != null && res.paymentStatus == "SUCCESS") {
        this.toastr.error("please wait until admin can accept your request.")
      } else {
        this.router.navigate([''])
      }
    }, error => {
      if (error.status == 403) {
        this.toastr.error("please login again.");
        localStorage.removeItem("studio-loginuser");
        localStorage.removeItem("studio-token");
        window.location.reload();
      } else {
        this.toastr.error("please login again.");
        localStorage.removeItem("studio-loginuser");
        localStorage.removeItem("studio-token");
        window.location.reload();
        // console.log("login use api error");
      }
    })
  }
  sendOtptouser() {
    this.signupservice.loginUserOtp(this.txtmobilenumber, "login").subscribe(res => {
      // console.log(res);
      this.createdOtp = res.status;
      this.otpTextEnable = true;
      this.btnVerifyEnable = true;
    })
  }
  resendOtptouser() {
    // this.createdOtp = Math.floor(1000 + Math.random() * 9000);
    // console.log(this.createdOtp);
    this.sendOtptouser();
    this.toastr.success("OTP send.");
  }

  //-----------------------------------------Registration Code---------------------------------------------

  toUpperCaseDealerName() {
    const temp = this.txtDealerName;
    this.txtDealerName = temp.charAt(0).toUpperCase() + temp.slice(1);
    return this.txtDealerName;
  }
  toUpperCaseOwnerName() {
    const temp = this.txtOwnerName;
    this.txtOwnerName = temp.charAt(0).toUpperCase() + temp.slice(1);
    return this.txtOwnerName;
  }
  toUpperCaseContactPerson() {
    const temp = this.txtContactPerson;
    this.txtContactPerson = temp.charAt(0).toUpperCase() + temp.slice(1);
    return this.txtContactPerson;
  }
  toUpperCaseCountry() {
    const temp = this.txtCountry;
    this.txtCountry = temp.charAt(0).toUpperCase() + temp.slice(1);
    return this.txtCountry;
  }
  toUpperCaseCity() {
    const temp = this.txtcity;
    this.txtcity = temp.charAt(0).toUpperCase() + temp.slice(1);
    return this.txtcity;
  }
  toUpperCaseState() {
    const temp = this.txtstate;
    this.txtstate = temp.charAt(0).toUpperCase() + temp.slice(1);
    return this.txtstate;
  }

  clearData() {
    this.txtDealerName = "";
    this.txtOwnerName = "";
    this.txtContactPerson = "";
    this.txtGstin = "";
    this.txtemail = "";
    this.txtsignupmobilenumber = "";
    this.txtCountry = "";
    this.txtcity = "";
    this.txtstate = "";
    this.txtpincode = "";
    this.signupcreatedOtp = "";
    this.txtUserOtp = "";
    // var ele = document.getElementById("normaluser");
    // var element = ele as HTMLInputElement;
    // element.checked = true;
  }
  signup() {
    var pin = this.txtpincode;
    // var add1 = this.txtaddress;
    var mobi = this.txtsignupmobilenumber;
    var email = this.txtemail;
    var city = this.txtcity;
    var state = this.txtstate;
    var contactperson = this.txtContactPerson;
    if (this.txtDealerName == null || this.txtDealerName == '' || this.txtDealerName == undefined) {
      this.toastr.error("dealer name is required");
    } else if (this.txtOwnerName == null || this.txtOwnerName == '' || this.txtOwnerName == undefined) {
      this.toastr.error("Owner name is required");
    } else if (this.txtContactPerson == null || this.txtContactPerson == '' || this.txtContactPerson == undefined) {
      this.toastr.error("contact person is required");
    } else if (this.txtGstin == null || this.txtGstin == '' || this.txtGstin == undefined) {
      this.toastr.error("Gstin is required");
    } else if (this.txtemail == null || this.txtemail == '' || this.txtemail == undefined || (this.txtemail = email.trim()) == "") {
      this.toastr.error("email  is required");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtemail)) {
      this.toastr.error("Please enter valid email");
    } else if (this.txtsignupmobilenumber == null || this.txtsignupmobilenumber == '' || this.txtsignupmobilenumber == undefined || (this.txtsignupmobilenumber = mobi.trim()) == "") {
      this.toastr.error("mobile number is required");
    } else if (this.txtsignupmobilenumber.length > 10 || this.txtsignupmobilenumber.length < 10) {
      this.toastr.error("mobile number must be 10 digit");
    } else if (this.txtCountry == null || this.txtCountry == '' || this.txtCountry == undefined) {
      this.toastr.error("country is required");
      // } else if (this.txtaddress == null || this.txtaddress == '' || this.txtaddress == undefined || (this.txtaddress = add1.trim()) == "") {
      //   this.toastr.error("address is required");
    } else if (this.txtcity == null || this.txtcity == '' || this.txtcity == undefined || (this.txtcity = city.trim()) == "") {
      this.toastr.error("city is required");
    } else if (this.txtstate == null || this.txtstate == '' || this.txtstate == undefined || (this.txtstate = state.trim()) == "") {
      this.toastr.error("state is required");
    } else if (this.txtpincode == null || this.txtpincode == '' || this.txtpincode == undefined || (this.txtpincode = pin.trim()) == "") {
      this.toastr.error("pincode number is required");
    } else if (this.txtpincode.length > 6 || this.txtpincode.length < 6) {
      this.toastr.error("pincode number must be 6 digit");
    } else {
      if (this.exituser == false || this.exituser == 'false') {
        // this.signupcreatedOtp = Math.floor(1000 + Math.random() * 9000);
        // console.log(this.signupcreatedOtp);
        this.regsendOtptouser();
        $('#signupModal').modal('hide');
        $('#otpstaticBackdrop').modal('show');
        setTimeout(function () {
          $('#resenddivReg').css('display', "block");
        }, 20000)
      }
      else {
        this.toastr.error("user already exists.");
      };
    };
  }

  txtaddress;
  txtSalesPerson;
  regVerifyUser() {
    if (this.signupcreatedOtp != this.txtUserOtp) {
      this.toastr.error("Invalid OTP.");
    } else {
      this.signupservice.signup(this.txtDealerName, this.txtOwnerName, this.txtContactPerson, this.txtsignupmobilenumber, this.txtemail, this.txtGstin, this.txtcity, this.txtstate, this.txtpincode, this.txtCountry,this.txtaddress,this.txtSalesPerson).subscribe(res => {
        this.ressignup = res;
        this.txtmobilenumber = this.txtsignupmobilenumber;
        // alert("user exit with this mobile number");
        this.clearData();
        $('#signupModal').modal('hide');
        $('#otpstaticBackdrop').modal('hide');
        $('#loginModal').modal('show');
        // this.router.navigate(["signin"]);
      }, error => {
        // this.exituser = null;
      })
    }
  }

  checkUserMobileNoExit() {
    if (this.txtsignupmobilenumber == null || this.txtsignupmobilenumber == '' || this.txtsignupmobilenumber == undefined) {
      this.toastr.error("mobile number is required");
    } else if (this.txtsignupmobilenumber.length > 10 || this.txtsignupmobilenumber.length < 10) {
      this.toastr.error("mobile number must be 10 digit");
    } else {
      this.signupservice.checkDealerExitOrNot(this.txtsignupmobilenumber).subscribe(res => {
        this.exituser = res;
        // console.log(res);
        if (this.exituser == true ||this.exituser == 'true' ) {
          this.toastr.error("user already exists.");
        } else {
          return;
        }
      }, error => {
        this.exituser = false;
      })
    }
  }

  regsendOtptouser() {
    this.signupservice.loginUserOtp(this.txtsignupmobilenumber, "reg").subscribe(res => {
      // console.log(res);
      this.signupcreatedOtp = res.otp;
    })
  }

  regResendOtptouser() {
    // this.signupcreatedOtp = Math.floor(1000 + Math.random() * 9000);
    // console.log(this.signupcreatedOtp);
    this.regsendOtptouser();
    this.toastr.success("OTP send.")
  }

  goTOPrivacyPolicy() {
    var component = localStorage.getItem("component");
    if (component == "PrivacyPolicyComponent") {
      window.location.reload();
    } else {
      $('.modal-backdrop').remove();
      this.router.navigate(['/privacy'])
    }
  }

  //=========================razorpay payment code=================================//
  resRazorPayOrder;
  txtcurrency = "INR";
  razorpayOrderId;
  transactionId;
  razorpay_signature;
  dealerRegAmount = 2000;
  txtorderid = null;
  createRazorPayOrderId() {
    var finalamount = parseFloat((this.dealerRegAmount * 100).toFixed(2));
    this.checkoutservice.createDealerRazorPayOrder(this.userMobile, finalamount, this.txtcurrency, this.txtorderid).subscribe(res => {
      this.resRazorPayOrder = res;
      this.razorpayOrderId = this.resRazorPayOrder.razorPayOrderId;
      // console.log(this.resRazorPayOrder)

      var options: any = {
        key: GlobalStudioConstants.RazorPay_Key_Id, // Enter the Key ID generated from the Dashboard
        amount: finalamount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tarapara",
        description: "Tarapara",
        image: "",
        order_id: this.razorpayOrderId,
        handler: this.paymentHandler.bind(this),
        // prefill: {
        // "name": "Studio",
        // "email": "Studio.SSM@example.com",
        // "contact": "9999999999"
        // },
        notes: '',
        theme: {
          // color: "#e90e0e"
          color: "#ffffff"
        },
        modal: {
          ondismiss: (() => {
            this.zone.run(() => {
              this.router.navigate(['']);
            });
          })
        }
      };
      this.rzp = new this.razorpayService.nativeWindow['Razorpay'](options);
      this.rzp.open();
      this.rzp.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        this.toastr.error(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      })
    }, error => {
      if (error.status == 403) {
        this.toastr.error("please login again.");
        localStorage.removeItem("studio-loginuser");
        localStorage.removeItem("studio-token");
        this.router.navigate(['']);
      } else {
        // console.log("create razorpay order api error");
      }
    })
  }

  paymentHandler(res: any) {
    this.zone.run(() => {
      // console.log(res);//get payment id if payment successfully done
      this.razorpayOrderId = res.razorpay_order_id;
      this.transactionId = res.razorpay_payment_id;
      this.razorpay_signature = res.razorpay_signature;
      this.updateDealerPaymentData();
    }, error => {
      console.log("razorpay transaction handler error");
    });
  }

  status = "Empty";
  updateDealerPaymentData() {
    this.checkoutservice.updateRazorPaySuccesOrder(this.razorpayOrderId, this.transactionId, this.razorpay_signature, this.status).subscribe(res => {
      // console.log(res);
      this.toastr.success("Payment success.");
      var component = localStorage.getItem("component");
      if (component == "HomeComponent") {
        window.location.reload();
      } else {
        this.router.navigate([''])
      }
    }, error => {
      this.toastr.error("Payment not success.");
    })
  }

  goToStudioPage() {
    var resData = JSON.parse(localStorage.getItem("studio-loginuser"));
    // console.log(resData);
    if (resData == null || resData == undefined) {
      this.toastr.error("Please login");
    } else {
      if (resData.active == true && resData.razorPayPaymentId != null && resData.paymentStatus == "SUCCESS") {
        this.router.navigate(['/studio']);
      } else if (resData.active == false && resData.razorPayPaymentId == null && (resData.paymentStatus == "created" || resData.paymentStatus == "" || resData.paymentStatus == null)) {
        this.toastr.error("Please make payment");
      } else if (resData.active == false && resData.razorPayPaymentId != null && resData.paymentStatus == "SUCCESS") {
        this.toastr.error("please wait until admin can accept your request.")
      } else {
        this.toastr.error("Please login");
      }
    }
  }

  redirecthomeabout() {
    var cmp =  localStorage.getItem("component");
    if (cmp == "HomeComponent") {
      window.location.reload();
    } else {
      this.router.navigate(['']);
    }
  }
}
