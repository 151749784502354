import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartDetailComponent } from './cart-detail/cart-detail.component';
import { HomeComponent } from './home/home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { StudioDetailComponent } from './studio-detail/studio-detail.component';
import { StudioInitComponent } from './studio-init/studio-init.component';
import { StudioRegistrationComponent } from './studio-registration/studio-registration.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'studio', component: ProductDetailComponent },
  { path: 'cartdetail', component: CartDetailComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'terms', component: TermsConditionsComponent },
  { path: 'myaccount', component: MyAccountComponent },
  { path: 'studio-signin', component: StudioDetailComponent },
  { path: 'studio-reg', component: StudioRegistrationComponent },
  { path: 'studioinit', component: StudioInitComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
