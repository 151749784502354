<app-header></app-header>
<div id="main">
    <div class="breadcrumb">
        <div class="wrapper">
          <div class="breadcrumb_inner">
            <ul class="d-flex flex-row flex-wrap justify-content-start align-items-center">
              <li><a href="#">home</a></li>
              <li><span>Privacy Policy</span></li>
            </ul>
          </div>
        </div>
    </div>
    <div class="wrapper">
      <section class="framing_block">
        <div class="framing_inner">
          <h2 class="section_title mo_35_b text-center">
            <span>Privacy Policy</span>
            <!-- <strong>Privacy Policy</strong> -->
            <p class="mo_14_m">WHAT PERSONAL DATA WE COLLECT AND WHY WE COLLECT IT</p>
          </h2>
          <ul class="flex-row flex-wrap justify-content-between align-items-stretch">
            <li style="margin-bottom: 1.5rem;">
              <div class="framing_box" style="align-items: start;">
                <h3 class="mo_20_r mb-3">1. Personal Information</h3>
                <p class="mo_14_r text-start">tarapara.in is an entity of  Tarapara Industries. tarapara.in respects your privacy. This Privacy Policy provides succinct details about how your data is collected and used by the Site. As a visitor to the Site/Customer, you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by tarapara.in in the manner provided in this Privacy Policy.</p>
              </div>
            </li>
            <li style="margin-bottom: 1.5rem;">
              <div class="framing_box" style="align-items: start;">
                <h3 class="mo_20_r mb-3">2. What information is, or maybe, collected from you?</h3>
                <p class="mo_14_r text-start">As part of the registration process on the site, tarapara.in may collect the following personally identifiable information about you: Name including first and last name, email address, mobile phone number and contact details, postal code, demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information.</p>
              </div>
            </li>
            <li style="margin-bottom: 1.5rem;">
              <div class="framing_box" style="align-items: start;">
                <h3 class="mo_20_r mb-3">3. How is the information used?</h3>
                <p class="mo_14_r text-start">tarapara.in will use your personal information to provide personalized features to you on the site and to provide for promotional offers to you through the site and other channels. tarapara.in will also provide this information to its business associates and partners to get in touch with you when necessary to provide the services requested by you.

                    <br><br>tarapara.in will use this information to preserve transaction history as governed by existing law or policy. tarapara.in may also use contact information internally to direct its efforts for product improvement, to contact you as a survey respondent, to notify you if you win any contest; and to send you promotional materials from its contest sponsors or advertisers.

                    <br><br>tarapara.in will also use this information to serve various promotional and advertising materials to you via display advertisements through the Google Ad network on third party websites. You can opt-out of Google Analytics for Display Advertising and customize Google Display Network ads using the Ads Preferences Manager.

                    <br><br>Information about Customers on an aggregate (excluding any information that may identify you specifically) covering Customer transaction data and Customer demographic and location data may be provided to partners of tarapara.in for the purpose of creating additional features on the website, creating appropriate merchandising or creating new products and services and conducting marketing research and statistical analysis of customer behaviour and transactions.</p>
              </div>
            </li>
            <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">4.With whom your information will be shared</h3>
                  <p class="mo_14_r text-start">tarapara.in will not use your financial information for any purpose other than to complete a transaction with you. tarapara.in does not rent, sell or share your personal information and will not disclose any of your personally identifiable information to third parties. In cases where it has your permission to provide products or services, you’ve requested and such information is necessary to provide these products or services with the information may be shared with tarapara.in’s business associates and partners. tarapara.in may, however, share consumer information on an aggregate with its partners or third parties where it deems necessary.

                    <br><br>In addition, tarapara.in may use this information for promotional offers, to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person, violations of the site’s terms of use or to defend against legal claims; special circumstances such as compliance with subpoenas, court orders, requests/order from legal authorities or law enforcement agencies requiring such disclosure.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">5. How do we protect your information?</h3>
                  <p class="mo_14_r text-start">To protect against the loss, misuse, unauthorised access, damage and alteration of the information under its control, tarapara.in has in place appropriate and reasonable security measures and safeguards, in compliance with applicable laws. For example, tarapara.in servers are accessible only to authorised personnel and your information is shared with the authorised personnel on a need tarapara.in know basis to complete the transaction and to provide the services requested by you. Although tarapara.in will endeavour to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the Internet cannot be made absolutely secure.

                    <br><br>By using this Site, you agree that tarapara.in will have no liability for disclosure of your information due to errors in transmission or unauthorized acts of third parties. By using this Site or providing information, you agree (i) that we can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Site; and (ii) that we can take adequate physical, managerial, and technical safeguards to preserve the integrity and security of your data till the time you use the Site or the services provided by the Site (directly or indirectly). </p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">6. How can you correct inaccuracies in the information?</h3>
                  <p class="mo_14_r text-start">To correct or update any information you have provided, the site allows you to do it online. In the event of loss of access details, you can send an email to: info@tarapara.in</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                  <h3 class="mo_20_r mb-3">7. Policy Updates</h3>
                  <p class="mo_14_r text-start">tarapara.in  reserves the right to change or update this policy at any time. Such changes shall be effective immediately upon posting to the Site.</p>
                </div>
              </li>
              <li style="margin-bottom: 1.5rem;">
                <div class="framing_box" style="align-items: start;">
                    <p class="mo_14_r text-start">
                      <b>Contact Information</b>
                      <br>Tarapara Industries
                      <br>B-18, Krishana Industrial Estate,
                      <br>B/H Nirman Industrial Estate,
                      <br>Ashwani Kumar Road, Fulpada,
                      <br>Surat, Gujarat - 395008, India.
                      <br>Tel.: +91 9302 33 9302
                      <br>Email id:  info@tarapara.in
                    </p>
                </div>
              </li>
          </ul>
        </div>
      </section>
    </div>
</div>
<app-footer></app-footer>
